//NOSONAR
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/core-module/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { LoginComponent } from "../login-component/login.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalComponent } from "../configure-device-modal/configure-device-modal.component";
import { ReadDeviceComponent } from "../read-device/read-device.component";
import { catchError, flatMap, takeUntil } from "rxjs/operators";
import { Subject, Subscription, throwError } from "rxjs";
import { isNullOrUndefined } from "util";
import { ErrorComponent } from "src/app/error/error.component";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { CustomAuthService } from "src/app/core-module/services/custom-auth.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  loading: boolean;
  returnUrl: any;
  redirectUrl = window.location.origin;
  afterLogin: boolean;
  newsLinks = "https://www.signify.com/global/our-company/news";
  dcsDownloadLink = "https://signify-portal-acc.trimm.net/.rest/file/v1/";
  verLinks = "./assets/pdf/User manual Multione web.pdf";
  isReadDevice = false;
  getReadDeviceSubjectSub: Subscription;
  statusCallData;
  unsubscribe = new Subject<any>();
  readerBaseUrl: any[];
  readerListUrl = "/peripheral/v1/types";
  pathVal: string;
  isDeviceConnected: boolean = false;
  token: any;
  statusCallDataSwVersion: any;
  latestDcsVersion: any;
  documentId = null;
  isDcsConnected: boolean = true;
  constructor(
    private apiService: ApiService,
    private location: Location,
    private route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private authSrv: CustomAuthService
  ) {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
  }
  openInfo(e) {
    e.stopPropagation();
  }

  get loggedIn() {
    return this.authSrv.isUserLoggedIn();
  }

  isLoggingIn() {
    return this.authSrv.IsLoggigIn();
  }

  async ngOnInit() {
    let authCode = this.route.snapshot.queryParamMap.get("code");
    if (authCode) {
      await this.authSrv.startTokenGenUsingCode(authCode);
    }
    let redirectToLocalHost = this.route.snapshot.queryParamMap.get("redirectToLocal");
    if(redirectToLocalHost){
      window.location.href = redirectToLocalHost+'?logout=true';
    }

    let logoutParam = this.route.snapshot.queryParamMap.get("logout");
    if(logoutParam == "true"){
      await this.authSrv.handleDataOnUserSessionEnd();
      this.location.replaceState("/");
      this.router.navigate(['/'], {replaceUrl: true});
    }
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    localStorage.setItem("prodInfoStatus", "no");
    localStorage.setItem("showContinueNow", "no");
    localStorage.setItem("readContinue", "no");
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    localStorage.setItem("reading", JSON.stringify(false));
    this.apiService.removeModes("invalid");
  
    window.scrollTo(0, 0);
    this.apiService.setConfig("");

    if (this.authSrv.isUserLoggedIn()) {
      this.token = this.authSrv.getToken();

      //uncomment on getting the dcs url

      this.getLatestDcsVersion().subscribe(
        (response: any) => {
          this.latestDcsVersion = response.version.split(".").join("");
          this.documentId = response.documentId;
          localStorage.setItem("latestDcsVersion", this.latestDcsVersion);
        },
        (err) => {
          const modalRef = this.modalService.open(ErrorComponent, {
            keyboard: false,
            centered: true,
            windowClass: "error-style, error-style1",
          });
          modalRef.componentInstance.errorName = "DCS Download Failed";
          modalRef.componentInstance.errorDescription =
            "Please contact your local Signify representative!";
        }
      );

      localStorage.removeItem("saveSetData");
      this.loading = true;
      this.apiService.getBaseUrl();
      this.apiService.getReadData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.readerBaseUrl = url;
            this.unsubscribe.next();
            let status = this.getDeviceReader();
            let readerSelection = this.getReaderList();
            status
              .pipe(
                flatMap((data) => {
                  this.statusCallData = data;
                  this.statusCallDataSwVersion =
                    this.statusCallData.serverSwVersion
                      .toString()
                      .split(".")
                      .join("");
                  localStorage.setItem(
                    "statusCallDataSwVersion",
                    this.statusCallDataSwVersion
                  );
                  this.latestDcsVersion = JSON.parse(
                    localStorage.getItem("latestDcsVersion")
                  );
                  if (
                    !isNullOrUndefined(this.latestDcsVersion) &&
                    !isNullOrUndefined(this.statusCallDataSwVersion)
                  ) {
                    if (
                      parseInt(this.latestDcsVersion) >
                      parseInt(this.statusCallDataSwVersion)
                    ) {
                      this.isReadDevice = false;
                    } else {
                      this.isReadDevice = true;
                    }
                  }
                  //this.isReadDevice = true;

                  return readerSelection.pipe(
                    catchError((err) => {
                      // do stuff with err from API1 request
                      return throwError(err);
                    })
                  );
                }),
                catchError((err) => {
                  // this.isReadDevice = false;
                  // do stuff with err from API2 request
                  return throwError(err);
                })
              )
              .subscribe(
                (resp) => {
                  this.isDeviceConnected = true;
                  let readerData: any;
                  readerData = resp;
                  localStorage.setItem(
                    "readerDevices",
                    JSON.stringify(readerData)
                  );

                  let readers = JSON.parse(
                    localStorage.getItem("detectedReaderInfo")
                  );

                  let newReader = false;
                  if (
                    !isNullOrUndefined(readers && readers["selectedReader"]) &&
                    readerData[0]["peripheralType"] !==
                      readers["selectedReader"]["peripheralType"]
                  ) {
                    newReader = true;
                  }

                  if (
                    !readers ||
                    (readers && isNullOrUndefined(readers["selectedReader"])) ||
                    newReader
                  ) {
                    let detectedReader = {
                      readerType: "SimpleSet",
                      selectedReader: readerData[0],
                      readerConnected: true,
                    };

                    localStorage.setItem(
                      "detectedReaderInfo",
                      JSON.stringify(detectedReader)
                    );

                    let selevtedReadArr: any[] = [];
                    selevtedReadArr.push(readerData[0]);
                    localStorage.setItem(
                      "readerInfo",
                      JSON.stringify(selevtedReadArr)
                    );
                  }

                  let detectedReaders = JSON.parse(
                    localStorage.getItem("detectedReaderInfo")
                  );
                  detectedReaders["isDcsConnected"] = true;
                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReaders)
                  );
                  this.loading = false;
                },
                (error) => {
                  if (
                    error &&
                    !isNullOrUndefined(error.status) &&
                    error.status === "404.1"
                  ) {
                    this.isDeviceConnected = false;
                  }

                  if (
                    error &&
                    !isNullOrUndefined(error.target && error.target.url) &&
                    error.target.url ===
                      "http://localhost:2020/server/v1/status"
                  ) {
                    this.isDcsConnected = false;
                  } else {
                    this.isDcsConnected = true;
                  }

                  localStorage.removeItem("readerDevices");
                  let detectedReader = {
                    readerType: "SimpleSet",
                    selectedReader: null,
                    readerConnected: false,
                    isDcsConnected: this.isDcsConnected,
                  };

                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReader)
                  );

                  this.loading = false;
                }
              );
          }
        });
      this.router.navigate(["/home"]);
    }

    this.getReadDeviceSubjectSub =
      this.apiService.getReadDeviceSubject.subscribe((response) => {
        this.isReadDevice = response;
      });
  }

  login() {
    this.authSrv.login();
  }

  ngDoCheck() {
    if (
      localStorage.getItem("detectedReaderInfo") &&
      localStorage.getItem("detectedReaderInfo") != "undefined"
    ) {
      let readers = JSON.parse(localStorage.getItem("detectedReaderInfo"));

      if (readers && !isNullOrUndefined(readers["selectedReader"])) {
        this.isDeviceConnected = true;
      } else {
        this.isDeviceConnected = false;
      }
      this.isDcsConnected = readers.isDcsConnected;
    } else {
      this.isDeviceConnected = false;
    }

    if (JSON.parse(localStorage.getItem("latestDcsVersion"))) {
      this.latestDcsVersion = JSON.parse(
        localStorage.getItem("latestDcsVersion")
      );
      if (
        !isNullOrUndefined(this.latestDcsVersion) &&
        !isNullOrUndefined(this.statusCallDataSwVersion)
      ) {
        if (
          parseInt(this.latestDcsVersion) >
          parseInt(this.statusCallDataSwVersion)
        ) {
          this.isReadDevice = false;
        } else {
          this.isReadDevice = true;
        }
      }
    }
  }

  getDeviceReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
    // return this.service.apiCall('get', '/assets/reader/status.json');
  }

  getReaderList() {
    return this.apiService.apiCall(
      "get",
      this.readerBaseUrl + this.readerListUrl
    );
  }

  getLatestDcsVersion() {
    return this.apiService.apiCall(
      "get",
      this.authSrv.baseUrl+'search/api/v1/multione-dcs/latest'+'?nocache='+Date.now(),
    );
  }

  callTodownloadDcs() {
    this.apiService.isDcsDownloadStarted = true;
    return this.apiService.apiCall(
      "get",
      this.authSrv.baseUrl+'search/api/v1/multione-dcs/download/'+this.documentId+'?nocache='+Date.now(),
      null,
      {
        responseType: "arraybuffer",
        observe:'response'
      }
    );
  }

  isDcsLinkDisabled(){
    return this.apiService.isDcsDownloadStarted;
  }

  downloadDcs() {
    if(this.apiService.isDcsDownloadStarted) return;
    const initateModelRef = this.modalService.open(ErrorComponent, {
      keyboard: false,
      centered: true,
      windowClass: "error-style, error-style1",
      backdrop:'static'
    });
    initateModelRef.componentInstance.errorName = "DCS Download has started";
    initateModelRef.componentInstance.errorDescription =
      "When the download is finished, please start installation of the downloaded file.";
      initateModelRef.componentInstance.confirmBtnText = 'OK';
    this.callTodownloadDcs().subscribe(
      (data) => {
        if(data && data['body']){
          let filename = 'DCS.zip';
          const contentDisposition = data['headers'].get('content-Disposition');
          if(contentDisposition){
            const matches: string = contentDisposition.split("=")[1];
            filename = matches.replace("\"","");
          }
          const blob = new Blob([data['body']], {
            type: "application/zip",
          });
          const url = URL.createObjectURL(blob);

          let a = document.createElement('a');
          a.href = url;
          a.download = filename;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
        this.apiService.isDcsDownloadStarted = false;
      },
      (err) => {
        if(initateModelRef){
          initateModelRef.close();
        }
        this.apiService.isDcsDownloadStarted = false;
        const modalRef = this.modalService.open(ErrorComponent, {
          keyboard: false,
          centered: true,
          windowClass: "error-style, error-style1",
        });
        modalRef.componentInstance.errorName = "DCS Download Failed";
        modalRef.componentInstance.errorDescription =
          "Please contact your local Signify representative!";
      }
    );
  }

  deivcePage() {
    if (this.authSrv.isUserLoggedIn()) {
      localStorage.setItem("reading", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveSetData");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      this.apiService.removeModes("invalid");
      this.router.navigate(["/deviceList"]);
    }
  }

  uploadFeatureFile() {
    if (this.authSrv.isUserLoggedIn()) {
      localStorage.setItem("reading", JSON.stringify(false));
      localStorage.removeItem("saveSetData");
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      localStorage.setItem("normalFlow", JSON.stringify(true));
      this.apiService.removeModes("invalid");
      // this.apiService.sendModes('Guided');
      let url = window.location.href;
      let isLoggedin = this.authSrv.isUserLoggedIn();
      if (isLoggedin != true) {
        this.router.navigate(["/deviceList"]);
      } else {
        this.apiService.modalConfigurationData("uploadFeatureFile");
        const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        });
      }
    }
  }

  newsLink() {
    window.open(this.newsLinks, "_blank");
  }

  versionLink() {
    window.open(this.verLinks, "_blank");
  }

  readDevice() {
    if (this.authSrv.isUserLoggedIn() && this.isReadDevice) {
      localStorage.setItem("normalFlow", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("resultFeatures");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      localStorage.removeItem("InputPower1001");
      localStorage.removeItem("InputPower100");
      localStorage.removeItem("InputPowerDim");
      localStorage.removeItem("DimLevel");
      localStorage.removeItem("tags");
      this.apiService.removeModes("invalid");
      localStorage.setItem("reading", JSON.stringify(true));
      localStorage.removeItem("saveSetData");
      let isLoggedin = this.authSrv.isUserLoggedIn();
      if (isLoggedin != true) {
        this.router.navigate(["/deviceList"]);
      } else {
        localStorage.setItem("isWriting", JSON.stringify(false));
        this.apiService.modalConfigurationData("readDevice");
        const modalRef = this.modalService.open(ReadDeviceComponent, {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.getReadDeviceSubjectSub) {
      this.getReadDeviceSubjectSub.unsubscribe();
    }
  }
}
