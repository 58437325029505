import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  ChangeDetectorRef,
  ViewRef,
  AfterViewChecked,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ApiService } from "src/app/core-module/services/api.service";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import {
  FormlyFormOptions,
  FormlyFieldConfig,
  FormlyConfig,
} from "@ngx-formly/core";
import { ConfigureDeviceModalComponent } from "../configure-device-modal/configure-device-modal.component";
import { Subject, Subscription, forkJoin, of, throwError } from "rxjs";
import {
  takeUntil,
  take,
  switchMap,
  catchError,
  flatMap,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { ReadDeviceComponent } from "../read-device/read-device.component";
import { element } from "protractor";
import { setInterval } from "timers";
import { ErrorComponent } from "src/app/error/error.component";
import { DataLayerService } from "src/app/core-module/services/data-layer.service";
import { ConfigureDeviceModalInputComponent } from "src/app/configure-device-modal-input/configure-device-modal-input.component";
import { isNullOrUndefined } from "util";
import { dcUsupportedDeviceList } from "src/assets/json/dcUnSupportedList";
import { featurefileUnsupportedDeviceList } from "src/assets/json/featurefileUnSupported";
import { OwpPasswordComponent } from "src/app/owp-password/owp-password.component";

const SUPPORTED_WEB_FEATURES = [
  "ztvoutput-", "0-10v / 1-10v", "ztv-", "Startup time", 'ast-', 'Aoc', "aoc-", 'Alo', "alo-", 
  'Srpsu', 'Psu', "dalipsu-", "srpsu-","luminfoformat3-", "luminfoformat0-", "clo-", 'CloLite', 
  "clolite-", "owp-", 'DTL', "dtl-", "flextune-", "lineswitch-", "codedmains-", "ampdim-", 
  "corridor-", "mtp-", "dcemergency-", "diia253maintenance-", "lip-", "rso-", "touchdim-", 
  "dynadimmer-", "Min Dim Level", "Min dim level", "mindimlevel-", "dimminginterface-", "multiconfigmode-",
]

@Component({
  selector: "app-feature-supported",
  templateUrl: "./feature-supported.component.html",
  styleUrls: ["./feature-supported.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FeatureSupportedComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewChecked
{
  @Input() public device;
  isSimpleSetMultiDeviceConfiguration: boolean = false;
  codedmainsValues: any = new Object();
  codedmainsFeature: {};
  owpFeature: {};
  featureForm = new FormGroup({});
  valueChange = false;
  model = {};
  aloModel = {};
  powerModel = {};
  dimmingModel = {};
  multiconfigModel = {};
  ztvModel = {};
  ztvoutputModel = {};
  rsoModel = {};
  DtlModel = {};
  OwpModel = {};
  minDimLevelModel = {};
  lumModel = {};
  CorridorModel = {};
  MtpModel = {};
  AmpDimModel = {};
  LineSwitchModel = {};
  codedmainsModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  psuSchema: FormlyFieldConfig[] = [];
  lmuSchema: FormlyFieldConfig[] = [];
  ztvSchema: FormlyFieldConfig[] = [];
  ztvoutputSchema: FormlyFieldConfig[] = [];
  lipSchema: any;
  rsoSchema: any;
  dimmingInterfaceSchema: FormlyFieldConfig[] = [];
  loading: boolean = false;
  buttonDisable = false;
  schema: any = [];
  aloSchema: FormlyFieldConfig[] = [];
  schemaAPI = "/assets/json/featureSchema.json";
  aloschemaAPI = "/assets/json/aloSchema.json";
  submittedFormData;
  setConfigUrl = "config/api/v1/setFeatureConfiguration";
  ioTschemaDetails = "config/api/v1/devices/schemas/details?{nc12}/v/{version}";
  featureFileUrl =
    "feature/api/v1/configuration/{configurationId}/feature-file";
  deviceConfigFileUrl =
    "feature/api/v1/configuration/{configurationId}/device-configuration-file";
  envAloschemaDetails;
  envSetConfigUrl;
  unsubscribe = new Subject<any>();
  prefencesUnsubscribe = new Subject<any>();
  readBaseUrlUnSubscribe = new Subject<any>();
  alovalue: any;
  aloSelectedFeature: any;
  codedmainsSelectedFeature: any;
  aocSelectedFeature: any;
  cloSelectedFeature: any;
  psuSelectedFeature: any;
  luminSelectedFeature: any;
  invalidInputValue = false;
  isDataLoaded: boolean = false;
  aloValueChange;
  cloValueChange;
  users: any = [];
  url;
  aloFeature = {};
  aocFeature = {};
  cloFeature = {};
  dynaFeature = {};
  dynaDimmerDSFeature = {};
  psuFeature = {};
  luminFeature = {};
  cloFeatureParametersObj = {};
  psuFeatureParamtersObj = {};
  checkCLOEdited = false;
  checkDynaDimmerEdited = false;
  checkDTLEdited = false;
  isError = false;
  fieldAloPercentage: any = 100;

  receivedFeature;
  exportFeature = {};
  graphPointsCollection;

  AocCurrentValue;
  AloPercentage;
  AloMinLevel;
  percentage;

  configObject = {};
  graphPointsObj = {};
  isCLOSelected = false;
  isOpenModal;

  // cloEnableCreateFile=false;
  dynaDimmerEnabledValue;
  cloEnabledValue;
  checkEnableGraphUnSub = new Subject<any>();
  checkCloScehma = new Subject<any>();
  getGraphDataUnSub = new Subject<any>();
  aocDisableCreateFile = false;
  showAloMinSlider: any;
  customLoader: boolean = true;
  aloEnableCreateFile: boolean = false;
  dtlEnableCreatFile = false;
  cloLiteEnableCreatFile = false;
  getAlopercent: any;
  subscription: any;
  subscriptionLumin;
  // graphDataValidCheck=true;
  OEMSelected: boolean;
  checkEnableRsetUnSub = new Subject<any>();
  isAloEnabled: boolean;
  sliderClicked: boolean;
  isPsuEnabled: boolean;
  isLGPsuEnabled: boolean;
  isHBPsuEnabled: boolean;
  countPSU;
  newPSUParamter = [];
  statusObs$: Subscription;
  quickConfigValue = {};
  configurationText = "Standard configuration";
  headerText = "Guided configuration";
  quickConfig = false;
  aloMinValue;
  aloMaxValue;
  alodefaultValue;
  aloMinDefaultValue;
  aocDefaultValue;
  aloMinLevelValue;
  aloMaxLevelValue;
  aocMinValue;
  aocMaxValue;
  isResetDisabled = false;
  aloInputError = false;
  aloMinInputError = false;
  textboxValue: number;
  minTextboxValue: number;
  aoctextboxValue;
  isAloResetDisabled = true;
  defaultState = true;
  aocInputError: boolean;
  inputError: boolean;
  defaultAocState: boolean;
  isHidden;
  showAloMin;
  quickLoader = false;
  aloSelected = false;
  aocSelected = false;
  cloSelected = false;
  psuSelected = false;
  luminSelected = false;
  cloLiteSelected = false;
  dtlSelected = false;
  corridorSelected = false;
  mtpSelected = false;
  ztvoutputSelected: any = false;
  rsoSelected: any = false;
  lipSelected: any = false;
  ampDimSelected = false;
  lineSwitchSelected = false;
  codedmainsSelected = false;
  envFeatureFileUrl: string;
  envConfigFileUrl: string;
  isDisabledButton: boolean;
  quickAloEnabled = false;
  aocEnabled = true;
  countPSUEnable;
  countPSULast;
  countPSUHeart;
  quickAloPercentage;
  quickAlomin;
  Feature12nc: any;
  aocQuickValue: Number;
  isAloQuickDisable = true;
  isAocQuickDisable = true;
  isCloQuickDisable = true;
  isPsuQuickDisable = true;
  iscloLiteQuickDisable = true;
  isLuminQuickDisable = true;
  isClo = true;
  isCloLite = true;
  countQuickFeature;
  newLuminObj = {};
  disableLuminInfo = false;
  luminValueEdited;

  //DYNA
  isdynaDimmerQuickDisable = true;
  dynaDimmerSelected = false;
  isdynaDimmer = true;
  dynaDimmerParametersObj = {};
  doubleArrowClosed: boolean;
  acoClosed: boolean = false;
  aloClosed: boolean = false;
  doubleArrowVal: any = false;
  openFeature: any = [];
  sortedSelectedFeatures;
  cloLiteSelectedFeature;
  liteHour;
  litepower;
  allFeature = [];
  oldValue = true;
  newFeatureArray = [];
  dynaCCTSupportDevice:boolean = false;

  colorCSS = "file-name";
  standardDoubleArrowCSS =
    "filters-section-title double-arrow button-collapse collapsed";

  aocCSS =
    "row feature-container filters-section-title button-collapse collapsed";
  aocMultiCollapseCSS = "row feature-container collapse multi-collapse";

  aloCSS =
    "row feature-container filters-section-title button-collapse collapsed";
  aloMultiCollapseCSS = "row feature-container collapse multi-collapse";
  ztvSelectedFeature: any;
  startupModel = {};
  startupSchema: FormlyFieldConfig[] = [];
  startupSelectedFeature: any;
  startupValueChange;
  startupFeature = {};
  startupFeatureParametersObj: any = [];
  ztvValues: any = new Object();
  ztvoutputObj: {} = {};
  rsoObj: {} = {};
  lipObj: {} = {};
  touchDimValues: any = new Object();
  ztvFeature: {};
  ztvoutputFeature: {};
  rsoFeature: {};
  lipFeature: {};
  isZtvError: any;
  isztvoutputError: any;
  softwareVersion: string;
  currentDate: string;
  startConfigURL;
  envStartConfigURL: string;
  startConfigurationData = {};
  setmode: any;
  isAocEnabled: any;
  minAloValueSchema: any;
  DtlSchema: any[];
  dtlSelectedFeature: string;
  isDtlQuickDisable: boolean;
  dtlFeatureObj: {} = {};
  cloLiteFeatureObj: {} = {};
  DtlLapmOffValue: any;
  corridorSelectedFeature: string;
  mtpSelectedFeature: string;
  ztvoutputSelectedFeature: string;
  rsoSelectedFeature: string;
  lipSelectedFeature: string;
  CorridorSchema: any[];
  MtpSchema: any[];
  codedmainsSchema: any[];
  AmpDimSchema: any[];
  corridorFeatureObj: {} = {};
  corridorEnableCreatFile: boolean = false;
  mtpEnableCreatFile: boolean = false;
  ztvoutputEnableCreatFile: boolean = false;
  rsoEnableCreatFile: boolean = false;
  lipEnableCreatFile: boolean = false;
  ampDimCreatFile: boolean = false;
  lineSwitchEnableCreatFile: boolean = false;
  diia253maintenanceEnableCreatFile: boolean = false;
  owpEnableCreatFile: boolean = false;
  codedmainsEnableCreatFile: boolean = false;
  checkLineSwitchEdited: any;
  checkOwpEdited: any;
  checkcodedmainsEdited: any;
  checkCorridorEdited: any;
  checkMtpEdited: any;
  checkztvoutputEdited: any;
  checklipEdited: any;
  checkrsoEdited: any;
  isCorridorQuickDisable: boolean;
  isMtpQuickDisable: boolean;
  iscodedmainsQuickDisable: boolean;
  isowpQuickDisable: boolean;
  isAmpDimQuickDisable: boolean;
  lineSwitchFeatureObj: {} = {};
  codedmainsFeatureObj: {} = {};
  mtpFeatureObj: {} = {};
  ampDimFeatureObj: {} = {};
  lineswitchSelectedFeature: string;
  owpSelectedFeature: string;
  isLineSwitchDisable: boolean;
  isOwpDisable: boolean;
  iscodedmainsDisable: boolean;
  isMtpDisable: boolean;
  lineswitchFeature: {};
  mtpFeature: {};
  ampDimFeature: {};
  corrdiorDefaultColor: any = true;
  mtpDefaultColor: any = true;
  lineSwitchDefaultColor: any = true;
  codedmainsDefaultColor: any = true;
  lineSwitchValueValid = false;
  LineSwitchSchema: any[];
  owpSchema: any[];
  minDimLevelSchema: any[];
  minDimLevelSelectedFeature: string;
  isMinDimLevelQuickDisable: boolean;
  minDimLevelFeatureObj: {} = {};

  ProlongTimeValue: any;
  apiListResponse: any[];
  isNormalFlow: any;
  minDimLevelSelected: any;
  minDimLevelEnableCreatFile: boolean;
  minDimLevelDefaultColor: any = true;
  checkMinDimLevelEdited = false;
  count: number;
  selectedFEaturesLength: any;
  dimmingInterface: string;
  ztvvalueChange: any;
  ztvoutputvalueChange: any;
  startupSelected: any = false;
  isStartupQuickDisable = false;
  ztvSelected: any = false;
  isZtvQuickDisable = false;
  isztvoutputQuickDisable = false;
  isrsoQuickDisable = false;
  islipQuickDisable = false;
  startUpValueValid = false;

  preferencesAPI = "config/api/v1/preferences";

  allFeatures: Boolean = false;
  startupDefaultColor: any = true;
  aocDefaultColor: any = true;
  ztvDefaultColor: any = true;
  ztvoutputDefaultColor: any = true;
  lipDefaultColor: any = true;
  rsoDefaultColor: any = true;
  srpsuDefaultColor: any = true;
  aloDefaultColor: any = true;
  cloDefaultColor: any = true;
  cloLiteDefaultColor: any = true;
  dynaDefaultColor: any = true;
  restoreDynaDimmer: any = false;
  luminDefaultColor: any = true;
  dtlDefaultColor: any = true;
  ampdimDefaultColor: any = true;
  dcemergencyModel = {};
  dcemergencySchema: FormlyFieldConfig[] = [];
  dcemergencySelectedFeature: any;
  dcemergencyValueChange;
  dcemergencyFeature = {};
  dcemergencyFeatureParametersObj: any;
  dcemergencyDefaultColor: any = true;
  dcemergencySelected = false;
  dcemergencyValueValid = false;
  isDcemergencyQuickDisable: boolean = true;
  checkDcemergencyEdited;
  dimmingRestoreColor: any = true;

  //MULTI CONFIG
  multiconfig: string;
  multiconfigSchema: any;
  multiconfigDefaultColor = true;
  multiconfigSelected = false;
  multiconfigData = {};

  //FLEXTUNE
  FlextuneModel = {};
  checkFlextuneEdited = false;
  flextuneSelected = false;
  flextuneEnableCreatFile = false;
  isFlextuneQuickDisable: boolean;
  FlextuneSchema: any[];
  flextuneDefaultColor: any = true;
  flextuneData = null;


  //DYNA
  dynadimSelectedFeature: any;
  dynaDimmerSchema: FormlyFieldConfig[] = [];
  dynaDimDefaultColor: any = true;
  dynaDimModel = {};
  //DYNA
  diia253maintenanceModel = {};
  diia253maintenanceSchema: FormlyFieldConfig[] = [];
  touchDimModel = {};
  touchDimSchema: FormlyFieldConfig[] = [];
  touchDimSelectedFeature: any;
  ampDimSelectedFeature: any;
  touchDimValueChange;
  touchDimFeature = {};
  diia253maintenanceFeature = {};
  touchDimFeatureParametersObj: any;
  diia253maintenanceFeatureParametersObj: any;
  owpFeatureParametersObj: any;
  touchDimDefaultColor: any = true;
  diia253maintenanceDefaultColor: any = true;
  owpDefaultColor: any = true;
  touchDimSelected = false;
  diia253maintenanceSelected = false;
  owpSelected = false;
  dimmingInterfaceSelected = false;
  touchDimValueValid = false;
  diia253maintenanceValueValid = false;
  owpValueValid = false;
  isTouchDimQuickDisable: boolean;
  isdiia253maintenanceQuickDisable: boolean;
  checkTouchDimEdited;
  pathVal: string;
  setAocData = {};
  dimmingInterfaceObj = {};
  disableCheckboxZtv = false;
  disableDimmingInterface: boolean;
  isReadytoValidte: boolean = false;
  countWritingFeature = 0;
  aocObj = {};
  touchDimObj = {};
  isReadDevice: boolean = true;
  readerStartUrl = "/server/v1/status";
  readerBaseUrl: any[];
  readUrl;
  restartConfiguration = false;
  aloData = {};
  countConfigureFeature: any = 0;
  dimmingInterfaceFeature: {};
  corridorFeature: {};
  dtlFeature: {};
  minDimFeature: any;
  isPageReady: boolean = false;
  isZtvDependDimming: boolean = false;
  isZTVFeatureAvailable: boolean = false;
  newConfigurationIDExtra: any = null;
  saveFeatureFileClicked: boolean = false;
  configureDeviceClicked: boolean = false;
  dynaDimmerTimeReferenceEnabledValue: boolean = false;
  dynaDimmerOverrideEnabledValue: boolean = false;
  dynaDimmerMidNightShiftEnabledValue: boolean = false;
  timeReferenceDynaModeGraph: boolean = false;
  timeReferenceDynaModeDisplay: boolean = false;
  isDynaDimmerError: boolean = false;
  isDynadimmerEnabled;
  astFound: boolean = false;
  ztvFound: boolean = false;
  UnSelectedFeatPrev: any[];
  newConfigIddata: string[] = [];
  writingURLViaPlugin =
    "feature/api/v1/configuration/{configurationId}/feature-payload";
  mem: any = [];
  successMessage: string = "error";
  dalionlydevice: boolean = true;
  loadingComponetsRen: boolean = false;
  cloLiteSchema: {} = null;
  cloSchema: {} = null;
  enableSub: Subscription;
  enableGraphSub: Subscription;
  cloGraphSub: Subscription;
  restoreDtl: boolean = false;
  dtlFinalDefaultColor: boolean | any;
  configDtlCheckbox: boolean;
  subscriptionService: Subscription;
  readingStatus;
  readerListUrl = "/peripheral/v1/types";
  readerData;
  isDcsConnected: boolean = true;
  unSupportedDeviceListDevices: any = [];
  unsupportedFeatureFileDevices: any = [];
  disableConfigureDevice_Unsupported: boolean = false;
  disableFeatureFile_Unsupported: boolean = false;
  isEnabledConfigBox: boolean = false;
  isEnableConfigDevice: boolean = true;
  isSelectedSaveFeatureFile: boolean = false;
  isSelectedDCFFile: boolean = false;
  getDynaDefaultColorSub: Subscription;
  getDimmingInterfaceRestoredSub: Subscription;
  getLineSwitchEdtedDataSub: Subscription;
  getDynaDimmerEnabledModeSub: Subscription;
  getdiia253maintenanceFeatApiSub: Subscription;
  diasbleConfig: boolean;
  statusCallDataSwVersion: any;
  latestDcsVersion: any;
  statusCallData;
  enablePreferenceMsg: string =
    "To enable saving select appropriate option in Preferences.";
  enableFeatureMsg: string =
    "To enable saving select at least one feature for include in configuration.";
  ngbEvent: NgbTooltip;

  deviceFeatures = [];
  initialSelectedFeatures = [];
  completedApiCalls = {};

  constructor(
    private ref: ChangeDetectorRef,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private _dataLayerService: DataLayerService
  ) {}
  ngAfterViewChecked() {
    //your code to update the model

    this.ref.detectChanges();
  }
  onOEMChnge(isChecked) {
    this.OEMSelected = isChecked;
  }
  ngOnInit() {
    let configData = JSON.parse(localStorage.getItem("configurationData"));
    if(configData && configData['selectedFeaturesInfo']){
      this.initialSelectedFeatures = [...configData['selectedFeaturesInfo']]
    }
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    var latestDcsVersion = JSON.parse(localStorage.getItem("latestDcsVersion"));
    var statusCallDataSwVersion = JSON.parse(
      localStorage.getItem("statusCallDataSwVersion")
    );
    if (
      !isNullOrUndefined(latestDcsVersion) &&
      !isNullOrUndefined(statusCallDataSwVersion)
    ) {
      if (parseInt(latestDcsVersion) > parseInt(statusCallDataSwVersion)) {
        this.diasbleConfig = false;
      } else {
        this.diasbleConfig = true;
      }
    }
    this.disableFeatureFile_Unsupported = false;
    this.disableConfigureDevice_Unsupported = false;
    try {
      this.unSupportedDeviceListDevices = atob(
        dcUsupportedDeviceList.XIBPDevices
      );
      this.unsupportedFeatureFileDevices = atob(
        featurefileUnsupportedDeviceList.SRBridgeFeatures
      );
    } catch (error) {}
    localStorage.setItem("readContinue", "no");
    localStorage.setItem("prodInfoStatus", "no");
    localStorage.setItem("showContinueNow", "no");
    this.setFeatureAPI(false);
    this.loadingComponetsRen = true;
    let nfc_protocol = localStorage.getItem("NFC_Protocol");
    if (nfc_protocol != undefined) {
      if (nfc_protocol.toLowerCase().includes("simpleset")) {
        this.dalionlydevice = false;
      }
      if (nfc_protocol === "null") {
        this.dalionlydevice = false;
      }
    }

    // this.apiService.getReloadReader.subscribe((data)  => {
    //   if(data === 'success') {
    //     window.location.reload();
    //   }
    // })

 
    const parsedData = JSON.parse(localStorage.getItem("dynaDimmerSchema"));
    this.getDynaDimmerEnabledModeSub =
      this.apiService.getDynaDimmerEnabledMode.subscribe((res) => {
        if (
          (parsedData &&
            parsedData[0]["properties"].hasOwnProperty("DynadimmerEnabled") &&
            parsedData[0]["properties"]["DynadimmerEnabled"]["default"] ===
              undefined) ||
          (parsedData &&
            parsedData[0]["properties"].hasOwnProperty("DynadimMode"))
        ) {
          this.isDynadimmerEnabled = false;
          if (
            parsedData[0]["$interSchemaDependency"] ===
            "DynadimmerConfigDependency_v1"
          ) {
            this.isDynadimmerEnabled = true;
          }
        } else {
          this.isDynadimmerEnabled = res;
        }
      });
    ////////////////////////
    this.apiService.getAocFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.aocSchemaListDetails();
        }
      });
    this.apiService.getCodedMainsFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.codedmainsListSchemaDetails();
        }
      });
    this.apiService.getLineSwitchFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.lineswitchSchemaListDetails();
        }
      });
    this.apiService.getOwpFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.owpSchemaListDetails();
        }
      });
    this.apiService.getAloFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.aloschemaListDetails();
        }
      });
    this.apiService.getDimmingInterfaceFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.dimmingInterfaceSchemaListDetails();
        }
      });
    this.apiService.getCloFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.cloSchemaListDetails();
        }
      });
    this.apiService.getCloLiteFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.CloLIteListDetails();
        }
      });
    this.apiService.getDaliPSUFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.psuSchemaListDetails();
        }
      });
    this.apiService.getZtvFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.ztvListDetails();
        }
      });
    this.apiService.getlipFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.lipListDetails();
        }
      });
    this.apiService.getztvoutputFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.ztvoutputListDetails();
        }
      });
    this.apiService.getrsoFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.rsoListDetails();
        }
      });
    this.apiService.getLumininfoformatFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.lumInfoListDetails();
        }
      });
    this.apiService.getStartUpTimeFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.startupSchemaListDetails();
        }
      });
    this.apiService.getDtlFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.DtlSchemaListDetails();
        }
      });
    this.apiService.getFlextuneFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.FlextuneSchemaListDetails();
        }
      });
    this.apiService.getCorridoeFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.corridorSchemaListDetails();
        }
      });
    this.apiService.getMtpFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.mtpSchemaListDetails();
        }
      });
    this.apiService.getampDimFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.ampdimSchemaListDetails();
        }
      });
    this.apiService.getDCEmergencyFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.dcemergencySchemaListDetails();
        }
      });
    this.apiService.getminDimLevelFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.minDimLevelSchemaListDetails();
        }
      });
    this.apiService.getTouchDimFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.touchDimSchemaListDetails();
        }
      });
    this.getdiia253maintenanceFeatApiSub =
      this.apiService.getdiia253maintenanceFeatApi
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((featFlag) => {
          if (featFlag === true) {
            this.diia253maintenanceSchemaListDetails();
          }
        });
    this.apiService.getDynaDimmerFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.dynaDimmerSchemaListDetails();
        }
      });

      this.apiService.getmulticonfigApiFeatApi
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((featFlag) => {
        if (featFlag === true) {
          this.multiconfigSchemaListDetails();
        }
      });

    this.setFeatureAPI(false);

    ////////////////////////////
    this.configureDeviceClicked = false;
    this.saveFeatureFileClicked = false;
    window.scroll(0, 0);
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.url = url;
        }
      });

    this.refreshReadersList();
    if (!localStorage.getItem("configurationData")) {
      this.router.navigate(["/deviceList"]);
    } else {
      let verObj = {};
      let versionSchema = JSON.parse(localStorage.getItem("configurationData"));
      verObj = {
        luminSchemaVer: versionSchema["luminSoftVer"],
        swschema: versionSchema["softwareVerSchema"],
        dtlSchemaVer: versionSchema["dtlSoftVer"],
        flextuneSoftVer: versionSchema["flextuneSoftVer"],
        mindimlevelSoftVer: versionSchema["mindimlevelSoftVer"],
        dalipsuSchemaVer: versionSchema["dalipsuSoftVer"],
        ztvSoftVer: versionSchema["ztvSoftVer"],
        ztvoutputSoftVer: versionSchema["ztvoutputSoftVer"],
        lipSoftVer: versionSchema["lipSoftVer"],
        rsoSoftVer: versionSchema["rsoSoftVer"],
        mtpSoftVer: versionSchema["mtpSoftVer"],
        dimminginterfaceSoftVer: versionSchema["dimminginterfaceSoftVer"],
        multiconfigSoftVer: versionSchema["multiconfigSoftVer"],
        ampdimSoftVer: versionSchema["ampdimSoftVer"],
        aocSoftVer: versionSchema["aocSoftVer"],
        touchdimSoftVer: versionSchema["touchdimSoftVer"],
        diia253maintenanceSoftVer: versionSchema["diia253maintenanceSoftVer"],
        corridorSoftVer: versionSchema["corridorSoftVer"],
        astSoftVer: versionSchema["astSoftVer"],
        cloliteSoftVer: versionSchema["cloliteSoftVer"],
        cloSoftVer: versionSchema["cloSoftVer"],
        dynadimmerSoftVer: versionSchema["dynadimmerSoftVer"],
        dcemergencySoftVer: versionSchema["dcemergencySoftVer"],
        lineswitchSoftVer: versionSchema["lineswitchSoftVer"],
        owpSoftVer: versionSchema["owpSoftVer"],
        codedmainsSoftVer: versionSchema["codedmainsSoftVer"],
      };
      localStorage.setItem("softwareSchema", JSON.stringify(verObj));
    }
    this.apiService.removeModes("valid");
    this.isNormalFlow = JSON.parse(localStorage.getItem("normalFlow"));

    this.apiService.setAloDefaultColor(true);
    this.apiService.getAloDefaultColor.subscribe((response) => {
      this.aloDefaultColor = response;
      if (this.aloDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("alo-", !this.aloDefaultColor);
    });

    this.apiService.setStartupDefaultColor(true);
    this.apiService.getStartupDefaultColor.subscribe((response) => {
      this.startupDefaultColor = response;
      if (
        this.startupDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("ast-", !this.startupDefaultColor);
    });

    this.apiService.setAocDefaultColor(true);
    this.apiService.getAocDefaultColor.subscribe((response) => {
      this.aocDefaultColor = response;
      if (this.aocDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("aoc-", !this.aocDefaultColor);
    });

    this.apiService.setSrpsuDefaultColor(true);
    this.apiService.getSrpsuDefaultColor.subscribe((response) => {
      this.srpsuDefaultColor = response;
      if (
        this.srpsuDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("srpsu-", !this.srpsuDefaultColor);
    });

    this.apiService.getCLOliteDefaultColor.subscribe((response) => {
      this.cloLiteDefaultColor = response;
      if (
        this.cloLiteDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("CLO_CLOLITE", !this.cloLiteDefaultColor);
    });

    //this.apiService.setCloDefaultColor(true);
    this.apiService.getCloDefaultColor.subscribe((response) => {
      this.cloDefaultColor = response;
      if (
        this.cloDefaultColor === false &&
        this.loadingComponetsRen === false
      ) {
        this.onCheckboxChnge("clo-", !this.cloDefaultColor);
      }
    });

    //DYNA
    this.apiService.setDynaDefaultColor(true);
    this.getDynaDefaultColorSub = this.apiService.getDynaDefaultColor.subscribe(
      (response) => {
        this.dynaDefaultColor = response;
        if (
          this.dynaDefaultColor === false &&
          !this.restoreDynaDimmer &&
          this.loadingComponetsRen === false
        ) {
          this.onCheckboxChnge("dynadimmer-", !this.dynaDefaultColor);
        }
        setTimeout(() => {
          this.restoreDynaDimmer = false;
        }, 500);
      }
    );

    this.apiService.getIsDynaError.subscribe((response) => {
      this.isDynaDimmerError = response;
    });
    this.apiService.getDynaTimeReferencedMode.subscribe((response) => {
      this.timeReferenceDynaModeGraph = response;
    });

    this.apiService.getDynaShowTimeReference.subscribe((response) => {
      this.timeReferenceDynaModeDisplay = response;
    });

    this.apiService.setLuminDefaultColor(true);
    this.apiService.getLuminDefaultColor.subscribe((response) => {
      this.luminDefaultColor = response;
      if (
        this.luminDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("Lumin", !this.luminDefaultColor);
    });
    this.apiService.dtlEditedVal(true);
    this.subscriptionService = this.apiService.getDtlEdited.subscribe(
      (response) => {
        this.dtlFinalDefaultColor = response;
        if (response === true || response === false) {
          this.dtlDefaultColor = this.restoreDtl = response;
          this.checkDTLEdited = !response;
          if (
            this.dtlDefaultColor === false && //commented for bug fix of DTL getting selected on mode change
            this.loadingComponetsRen === false
          ) {
            this.onCheckboxChnge("dtl-", !this.dtlDefaultColor);
          } else {
            this.reValidateDtlConfigCheckbox();
          }
        }
      }
    );

    this.subscriptionService = this.apiService.getdtlDefaultColor.subscribe(
      (response) => {
        if (response != "") {
          this.dtlFinalDefaultColor = response;
        }
        this.dtlDefaultColor = response;
        if (
          this.dtlDefaultColor === false &&
          this.loadingComponetsRen === false
        ) {
          this.onCheckboxChnge("dtl-", !this.dtlDefaultColor);
        }
      }
    );
    //flextune
    this.apiService.setFlextuneDefaultColor(true);
    this.apiService.getflextuneDefaultColor.subscribe((response) => {
      this.flextuneDefaultColor = response;
      if (
        this.flextuneDefaultColor === false &&
        this.loadingComponetsRen === false
      ){
        this.onCheckboxChnge("flextune-", !this.flextuneDefaultColor);
      }
        
    });
    this.apiService.MinDimLevelEditedVal(true);
    this.apiService.getMinDimLevelEdited.subscribe((response) => {
      this.minDimLevelDefaultColor = response;
      this.checkMinDimLevelEdited = !response;
      if (
        this.minDimLevelDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("mindimlevel-", !this.minDimLevelDefaultColor);
    });
    this.apiService.setowpDefaultColor(true);
    this.apiService.getowpDefaultColor.subscribe((response) => {
      this.owpDefaultColor = response;
      if (this.owpDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("owp-", !this.owpDefaultColor);
    });
    this.apiService.getowpEditedData.subscribe((response) => {
      if (response) {
        this.owpFeatureParametersObj = response;
      }
    });
    this.apiService.setdiia253maintenanceDefaultColor(true);
    this.apiService.getdiia253maintenanceDefaultColor.subscribe((response) => {
      this.diia253maintenanceDefaultColor = response;
      if (
        this.diia253maintenanceDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge(
          "diia253maintenance-",
          !this.diia253maintenanceDefaultColor
        );
    });
    this.apiService.getdiia253maintenanceEditedData.subscribe((response) => {
      if (response) {
        this.diia253maintenanceFeatureParametersObj = response;
      }
    });
    this.apiService.setTouchDimDefaultColor(true);
    this.apiService.getTouchDimDefaultColor.subscribe((response) => {
      this.touchDimDefaultColor = response;
      if (
        this.touchDimDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("touchdim-", !this.touchDimDefaultColor);
    });
    this.apiService.getTouchDimEditedData.subscribe((response) => {
      if (response) {
        this.touchDimFeatureParametersObj = response;
      }
    });
    this.apiService.ztvEditedVal(true);
    this.apiService.getZtvEdited.subscribe((response) => {
      this.ztvDefaultColor = response;
      if (this.ztvDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("ztv-", !this.ztvDefaultColor);
    });
    this.apiService.ztvoutputEditedVal(true);
    this.apiService.getztvoutputEdtedData.subscribe((response) => {
      this.ztvoutputDefaultColor = response;
      this.checkztvoutputEdited = !response;
      if (
        this.ztvoutputDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("ztvoutput-", !this.ztvoutputDefaultColor);
    });
    this.apiService.lipEditedVal(true);
    this.apiService.getlipEdtedData.subscribe((response) => {
      this.lipDefaultColor = response;
      this.checklipEdited = !response;
      if (this.lipDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("lip-", !this.lipDefaultColor);
    });
    this.apiService.rsoEditedVal(true);
    this.apiService.getrsoEdtedData.subscribe((response) => {
      this.rsoDefaultColor = response;
      this.checkrsoEdited = !response;
      if (this.rsoDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("rso-", !this.rsoDefaultColor);
    });
    this.apiService.corridorEditedVal(true);
    this.apiService.getCorridorEdtedData.subscribe((response) => {
      this.corrdiorDefaultColor = response;
      this.checkCorridorEdited = !response;
      if (
        this.corrdiorDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("corridor-", !this.corrdiorDefaultColor);
    });
    this.apiService.mtpEditedVal(true);
    this.apiService.getMtpEdtedData.subscribe((response) => {
      this.mtpDefaultColor = response;
      this.checkMtpEdited = !response;
      if (this.mtpDefaultColor === false && this.loadingComponetsRen === false)
        this.onCheckboxChnge("mtp-", !this.mtpDefaultColor);
    });
    // this.apiService.setDynaDefaultColor(true);
    // this.apiService.getDynaDefaultColor.subscribe((response) => {
    //   this.dynaDefaultColor = response;
    //   if (this.dynaDefaultColor === false && this.loadingComponetsRen === false)
    //     this.onCheckboxChnge("dynadimmer-", !this.dynaDefaultColor);
    // });
    this.apiService.setAmpDimDefaultColor(true);
    this.apiService.getAmpDimDefaultColor.subscribe((response) => {
      this.ampdimDefaultColor = response;
      if (
        this.ampdimDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("ampdim-", !this.ampdimDefaultColor);
    });
    //for line switch
    this.apiService.lineSwitchEditedVal(true);
    this.getLineSwitchEdtedDataSub =
      this.apiService.getLineSwitchEdtedData.subscribe((response) => {
        this.lineSwitchDefaultColor = response;
        this.checkLineSwitchEdited = !response;
        if (
          this.lineSwitchDefaultColor === false &&
          this.loadingComponetsRen === false
        )
          this.onCheckboxChnge("lineswitch-", !this.lineSwitchDefaultColor);
      });
    // for codedmains
    this.apiService.codedmainsEditedVal(true);
    this.apiService.getcodedmainsEdtedData.subscribe((response) => {
      this.codedmainsDefaultColor = response;
      this.checkcodedmainsEdited = !response;
      if (
        this.codedmainsDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("codedmains-", !this.codedmainsDefaultColor);
    });
    this.apiService.setDcemergencyDefaultColor(true);
    this.apiService.getDcemergencyDefaultColor.subscribe((response) => {
      this.dcemergencyDefaultColor = response;
      this.checkDcemergencyEdited = !response;
      if (
        this.dcemergencyDefaultColor === false &&
        this.loadingComponetsRen === false
      )
        this.onCheckboxChnge("dcemergency-", !this.dcemergencyDefaultColor);
    });

    this.apiService.sendDimmingRestoreDefault(true);
    this.getDimmingInterfaceRestoredSub =
      this.apiService.getDimmingInterfaceRestored.subscribe((resp) => {
        this.dimmingRestoreColor = resp;
        if (
          this.dimmingRestoreColor === false &&
          this.loadingComponetsRen === false
        )
          this.onCheckboxChnge("dimminginterface-", !this.dimmingRestoreColor);
      });

      this.apiService.getMulticonfigDefault.subscribe((resp) => {
        this.multiconfigDefaultColor = resp;
        if (
          this.multiconfigDefaultColor === false &&
          this.loadingComponetsRen === false
        ){
          this.onCheckboxChnge("multiconfigmode-", !this.multiconfigDefaultColor);
        }
      });

    this.apiService.getDcemergencyEditedData.subscribe((response) => {
      if (response) {
        this.dcemergencyFeatureParametersObj = response;
      }
    });
    var currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "dd-MM-yyyy");
    this.setmode = JSON.parse(localStorage.getItem("modes"));
    localStorage.setItem("setmode", JSON.stringify(this.setmode));
    // localStorage.removeItem('storeConfigMode');
    // localStorage.removeItem('storeOmitOption');
    this.configOmitOnRefresh();

    this.softwareVersion = JSON.parse(
      localStorage.getItem("comparefirmwarenoLatest")
    );
    if (this.softwareVersion.length > 3) {
      this.softwareVersion = this.softwareVersion.slice(0, -2);
    }
    localStorage.setItem(
      "setLastSoftwareVersion",
      JSON.stringify(this.softwareVersion)
    );
    this.apiService.sendFeaturePage("Isvalid");
    this.apiService.getQuickAlo.subscribe((data) => {
      if (data) {
        var newdata;
        var addNew = {
          resp: {
            ALO: {},
          },
        };
        if (localStorage.getItem("saveSetData1")) {
          newdata = JSON.parse(localStorage.getItem("saveSetData1"));
        } else {
          newdata = addNew;
        }
        newdata = {
          resp: {
            ALO: data,
          },
        };
        this.aloData = data;
        localStorage.setItem("saveSetData1", JSON.stringify(newdata));
      }
    });

    this.apiService.setConfig("featureConfiguration");
    this.apiService.getformInputValidation.subscribe((data) => {
      if (data == "invalid") {
        this.aocDisableCreateFile = true;
      } else {
        this.aocDisableCreateFile = false;
      }
    });

    this.apiService.checkStartupIsValid.subscribe((data) => {
      if (!isNullOrUndefined(data) && data != "") {
        if (data == "invalid") {
          this.startUpValueValid = true;
        } else {
          this.startUpValueValid = false;
        }
      }
    });
    this.apiService.checkALOGraph.subscribe((data) => {
      if (data === true) {
        this.aloEnableCreateFile = true;
      } else {
        this.aloEnableCreateFile = false;
      }
    });
    this.apiService.checkGraphIsValid.subscribe((data) => {
      if (data == "invalid") {
        // this.buttonDisable=true;
        this.isClo = true;
        this.isCloLite = true;
      } else {
        // this.buttonDisable=false;
        this.isClo = false;
        this.isCloLite = false;
      }
    });
    setTimeout(() => {
      this.getCLOData();
    }, 1000);

    // setTimeout(() => {
    //   this.getDynaDimmerData();
    // }, 3000);

    this.apiService.getformInputValidation.subscribe((data) => {
      this.invalidInputValue = data;
      this.buttonDisable = this.invalidInputValue;
    });
    this.subscriptionLumin = this.apiService.getLuminFormError.subscribe(
      (data) => {
        if (data) {
          this.disableLuminInfo = true;
        } else {
          this.disableLuminInfo = false;
        }
      }
    );
    this.apiService.getLuminValueEdited.subscribe((data) => {
      if (data) {
        this.luminValueEdited = true;
      } else {
        this.luminValueEdited = false;
      }
    });

    if (!localStorage.getItem("configurationData")) {
      this.apiService.getConfigurationData.subscribe((data) => {
        this.receivedFeature = data;
        this.receivedFeature.selectedFeaturesInfo.forEach((element) => {
          if (element == "Clo") {
            this.isCLOSelected = true;
          }
        });
      });
    } else {
      this.receivedFeature = JSON.parse(
        localStorage.getItem("configurationData")
      );
      this.deviceFeatures = this.receivedFeature.feature12Nc ? [...this.receivedFeature.feature12Nc] : [];
      this.deviceFeatures = this.deviceFeatures.filter(feature => !(feature=='srpsu-' || feature=='Srpsu') && SUPPORTED_WEB_FEATURES.includes(feature));
      this.deviceFeatures.sort();
      this.sortAllFeature(true);
      if (this.receivedFeature.selectedFeaturesInfo) {
        this.receivedFeature.selectedFeaturesInfo.forEach((element) => {
          if (element == "Clo") {
            this.isCLOSelected = true;
          }
        });
      }
    }

    //this.RemoveItem("dimminginterface-");
    //this.RemoveItem("lineswitch-");
    //this.RemoveItem("dynadimmer-");
    //this.RemoveItem("dynadim-");
    //this.RemoveItem("ampdim-");
    //this.RemoveItem("codedmains-");
    //this.RemoveItem("diia253maintenance-");
    //this.RemoveItem("owp-");
    this.RemoveItem("srpsu-"); //SUPPORTED BY DALIPSU is USED ALWAYS SHOULD BE REMOVED.
    //this.RemoveItem("ztvoutput-");
    // this.RemoveItem("rso-");
    // this.RemoveItem("lip-");
    //this.RemoveItem("ztv-");
    // this.RemoveItem("flextune-");
    // this.RemoveItem("multiconfigmode-");

    if (
      this.unSupportedDeviceListDevices.includes(
        this.receivedFeature.device12nc
      )
    ) {
      this.disableConfigureDevice_Unsupported = true;
    }

    if (
      this.unsupportedFeatureFileDevices.includes(
        this.receivedFeature.device12nc
      )
    ) {
      this.disableFeatureFile_Unsupported = true;
    }

    this.receivedFeature.feature12Nc.forEach((feature, i) => {
      this.openFeature.push(feature);
      setTimeout(() => {
        if (this.openFeature[i] == "multiconfigmode-") this.openFeature["multiconfigmode-"] = false;
        if (
          this.openFeature[i] === "CloLite" ||
          this.openFeature[i] === "Clolite" ||
          this.openFeature[i] === "clolite-"
        ) {
          this.openFeature[i] = "clolite-";
          this.openFeature["clolite-"] = false;
        }
        if (this.openFeature[i] === "aoc-") this.openFeature["aoc-"] = false;
        if (this.openFeature[i] == "alo-") this.openFeature["alo-"] = false;
        if (
          this.openFeature[i] == "PSU" ||
          this.openFeature[i] == "srpsu-" ||
          this.openFeature[i] == "SR PSU" ||
          this.openFeature[i] == "Srpsu"
        )
          this.openFeature["srpsu-"] = false;
        if (this.openFeature[i] == "dalipsu-")
          this.openFeature["srpsu-"] = false;
        if (
          this.openFeature[i] == "luminfoformat3-" ||
          this.openFeature[i] == "luminfoformat0-"
        )
          this.openFeature["LUMINFO"] = false;
        if (this.openFeature[i] == "clo-") this.openFeature["clo-"] = false;
        if (this.openFeature[i] == "dcemergency-")
          this.openFeature["dcemergency-"] = false;
        if (this.openFeature[i] == "dtl-") this.openFeature["dtl-"] = false;
        if (this.openFeature[i] == "ztv-") {
          this.openFeature["ztv-"] = false;
        }
        if (this.openFeature[i] == "ztvoutput-") {
          this.openFeature["ztvoutput-"] = false;
        }
        if (this.openFeature[i] == "luminaireinputpower-") {
          this.openFeature["luminaireinputpower-"] = false;
        }
        if (this.openFeature[i] == "rso-") {
          this.openFeature["rso-"] = false;
        }
        if (this.openFeature[i] == "lip-") {
          this.openFeature["lip-"] = false;
        }
        if (this.openFeature[i] == "corridor-") {
          this.openFeature["corridor-"] = false;
        }
        if (this.openFeature[i] == "mtp-") {
          this.openFeature["mtp-"] = false;
        }
        if (this.openFeature[i] == "ampdim-") {
          this.openFeature["ampdim-"] = false;
        }
        if (this.openFeature[i] == "codedmains-") {
          this.openFeature["codedmains-"] = false;
        }
        //for line switch feature
        if (this.openFeature[i] == "lineswitch-") {
          this.openFeature["lineswitch-"] = false;
        }
        if (this.openFeature[i] == "owp-") {
          this.openFeature["owp-"] = false;
        }
        if (this.openFeature[i] == "dimminginterface-") {
          this.openFeature["dimminginterface-"] = false;
        }
        if (this.openFeature[i] == "mindimlevel-") {
          this.openFeature["mindimlevel-"] = false;
        }
        if (this.openFeature[i] == "ast-") {
          this.openFeature["ast-"] = false;
        }
        if (this.openFeature[i] == "dynadimmer-") {
          this.openFeature["dynadimmer-"] = false;
        }
        if (this.openFeature[i] == "touchdim-")
          this.openFeature["touchdim-"] = false;
        if (this.openFeature[i] == "diia253maintenance-")
          this.openFeature["diia253maintenance-"] = false;
      }, 1000);
    });
    if (this.receivedFeature.feature12Nc) {
      this.receivedFeature.feature12Nc =
        this.receivedFeature.feature12Nc.sort();
      this.sortAllFeature();
      this.countQuickFeature = this.receivedFeature.feature12Nc.length;
    }

    // Calling ALL feature API's Start
    this.callAllApi();
    // Calling ALL feature API's End

    // this.apiService.getlitePower.subscribe((data: any) => {
    //   if (this.cloLiteSelectedFeature) {
    //     setTimeout(() => {
    //       var dataAdd;
    //       var addNew;
    //       addNew = {
    //         resp: {
    //           CLOLite: {
    //             CloLiteInitialPowerLevel: {},
    //           },
    //         },
    //       };
    //       if (localStorage.getItem("saveSetData")) {
    //         dataAdd = JSON.parse(localStorage.getItem("saveSetData"));
    //       } else {
    //         dataAdd = addNew;
    //       }
    //       if (data >= 0 && data != "") {
    //         this.litepower = data;
    //       } else if (data === 0) {
    //         this.litepower = 0;
    //       } else {
    //         this.litepower = 100;
    //       }

    //       if (
    //         dataAdd &&
    //         dataAdd["resp"] &&
    //         dataAdd["resp"]["CLOLite"] &&
    //         dataAdd["resp"]["CLOLite"]["CloLiteInitialPowerLevel"]
    //       ) {
    //         dataAdd["resp"]["CLOLite"]["CloLiteInitialPowerLevel"] =
    //           this.litepower;
    //       } else {
    //         dataAdd["resp"]["CLOLite"] = {
    //           CloLiteInitialPowerLevel: this.litepower,
    //         };
    //       }
    //       localStorage.setItem("saveSetData", JSON.stringify(dataAdd));
    //     }, 3000);
    //   }
    // });
    // this.apiService.getliteHour.subscribe((data: any) => {
    //   if (this.cloLiteSelectedFeature) {
    //     setTimeout(() => {
    //       var dataAdd;
    //       var addNew;
    //       addNew = {
    //         resp: {
    //           CLOLite: {
    //             CloLiteMaximumWorkingHours: {},
    //           },
    //         },
    //       };
    //       if (localStorage.getItem("saveSetData")) {
    //         dataAdd = JSON.parse(localStorage.getItem("saveSetData"));
    //       } else {
    //         dataAdd = addNew;
    //       }
    //       if (data >= 0 && data != "") {
    //         this.liteHour = data;
    //       } else {
    //         this.liteHour = 120;
    //       }
    //       dataAdd["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"] =
    //         this.liteHour;
    //       localStorage.setItem("saveSetData", JSON.stringify(dataAdd));
    //     }, 3000);
    //   }
    // });
    this.subscription = this.apiService.getAocValue.subscribe((aocValue) => {
      if (aocValue) {
        var data;
        var addNew = {
          resp: {
            AOC: {},
          },
        };
        if (localStorage.getItem("saveSetData")) {
          data = JSON.parse(localStorage.getItem("saveSetData"));
        } else {
          data = addNew;
        }
        this.aocObj = aocValue;
        data["resp"]["AOC"] = this.aocObj;
        localStorage.setItem("saveSetData", JSON.stringify(data));
      }
    });
    this.subscription = this.apiService.getTouchDimEditedData.subscribe(
      (touchDimVal: any) => {
        let touchDimValue = { ...touchDimVal };
        if (touchDimValue) {
          var data;
          var addNew = {
            resp: {
              TouchDim: {},
            },
          };
          if (localStorage.getItem("saveSetData")) {
            data = JSON.parse(localStorage.getItem("saveSetData"));
          } else {
            data = addNew;
          }
          this.touchDimObj = touchDimValue;
          data["resp"]["TouchDim"] = this.touchDimObj;
          localStorage.setItem("saveSetData", JSON.stringify(data));
        }
      }
    );
    this.subscription = this.apiService.getLuminValue.subscribe(
      (luminValue: any) => {
        if (luminValue) {
          var monitoredParamObj: any = new Object();
          var resp = luminValue;
          var data;
          var addNew = {
            resp: {
              LumInfoFormat: [{}],
            },
          };
          if (localStorage.getItem("saveSetData")) {
            data = JSON.parse(localStorage.getItem("saveSetData"));
          } else {
            data = addNew;
          }
          for (var i = 0; i < resp.length; i++) {
            if (!isNullOrUndefined(resp[i] && resp[i].key)) {
              if (resp[i].key === "OemGtin") {
                monitoredParamObj.OemGtin = resp[i].value;
              }
              if (resp[i].key === "OemIdentification") {
                if (resp[i].value === "18446744073709551615") {
                  monitoredParamObj.OemIdentification = "";
                } else {
                  monitoredParamObj.OemIdentification = resp[i].value;
                }
              }
              if (resp[i].key === "ContentIdentifier") {
                monitoredParamObj.ContentIdentifier = resp[i].value;
              }
              if (resp[i].key === "NominalInputPower") {
                monitoredParamObj.NominalInputPower = resp[i].value;
              }
              if (resp[i].key === "MinAcMainsVoltage") {
                monitoredParamObj.MinAcMainsVoltage = resp[i].value;
              }
              if (resp[i].key === "MaxAcMainsVoltage") {
                monitoredParamObj.MaxAcMainsVoltage = resp[i].value;
              }
              if (resp[i].key === "InputpowerBandNominal") {
                monitoredParamObj.InputpowerBandNominal = resp[i].value;
              }
              if (resp[i].key === "InputpowerBandPhysicalMin") {
                monitoredParamObj.InputpowerBandPhysicalMin = resp[i].value;
              }
              if (resp[i].key === "DimlevelPf90") {
                monitoredParamObj.DimlevelPf90 = resp[i].value;
              }
              if (resp[i].key === "DimlevelPf80") {
                monitoredParamObj.DimlevelPf80 = resp[i].value;
              }
              if (resp[i].key === "DimlevelPf70") {
                monitoredParamObj.DimlevelPf70 = resp[i].value;
              }
              if (resp[i].key === "LsiDevicePresent") {
                monitoredParamObj.LsiDevicePresent = resp[i].value;
              }
              if (resp[i].key === "IndependentOccupancySensor") {
                monitoredParamObj.IndependentOccupancySensor = resp[i].value;
              }
              if (resp[i].key === "LuminaireLightSource") {
                monitoredParamObj.LuminaireLightSource = resp[i].value;
              }
              if (resp[i].key === "LuminaireColorCode") {
                monitoredParamObj.LuminaireColorCode = resp[i].value;
              }
              if (resp[i].key === "NominalLightOutput") {
                monitoredParamObj.NominalLightOutput = resp[i].value;
              }
              if (resp[i].key === "DistributionType") {
                monitoredParamObj.DistributionType = resp[i].value;
              }
              if (resp[i].key === "LuminaireManufactureDate") {
                monitoredParamObj.LuminaireManufactureDate = resp[i].value;
              }
              if (resp[i].key === "LuminaireManufactureName") {
                monitoredParamObj.LuminaireManufactureName = resp[i].value;
              }
              if (resp[i].key === "LuminaireStyle") {
                monitoredParamObj.LuminaireStyle = resp[i].value;
              }
              if (resp[i].key === "LuminaireIdentification") {
                monitoredParamObj.LuminaireIdentification = resp[i].value;
              }
              if (resp[i].key === "LuminaireColor") {
                monitoredParamObj.LuminaireColor = resp[i].value;
              }
              if (resp[i].key === "AdditionalInfo") {
                monitoredParamObj.AdditionalInfo = resp[i].value;
              }
              if (resp[i].key === "LuminaireManufactureWeek") {
                monitoredParamObj.LuminaireManufactureWeek = resp[i].value
                  ? Number(resp[i].value)
                  : "";
              }
              if (resp[i].key === "LuminaireManufactureYear") {
                monitoredParamObj.LuminaireManufactureYear = resp[i].value
                  ? Number(resp[i].value)
                  : "";
              }
              if (resp[i].key === "LuminaireManufactureDate") {
                monitoredParamObj.LuminaireManufactureDate = resp[i].value;
              }
              if (resp[i].key === "LuminaireManufactureName") {
                monitoredParamObj.LuminaireManufactureName = resp[i].value;
              }
              if (resp[i].key === "UseDeviceUid") {
                monitoredParamObj.UseDeviceUid = resp[i].value;
              }
              if (resp[i].key === "PowerMinDim") {
                monitoredParamObj.PowerMinDim = resp[i].value;
              }
              if (resp[i].key === "UseDynamicDateIndicator") {
                monitoredParamObj.UseDynamicDateIndicator = resp[i].value;
              }
              if (resp[i].key === "CRI") {
                monitoredParamObj.CRI = resp[i].value;
              }
              if (resp[i].key === "CCT") {
                monitoredParamObj.CCT = resp[i].value;
              }
              if (resp[i].key === "LuminaireManufactureName") {
                monitoredParamObj.LuminaireManufactureName = resp[i].value;
              }
            }
          }
          data["resp"]["LumInfoFormat"] = monitoredParamObj;
          localStorage.setItem("saveSetData", JSON.stringify(data));
          this.newLuminObj = monitoredParamObj;
        }
      }
    );
    this.subscription = this.apiService.getZtvValues.subscribe((ztvObj) => {
      // let ztvData = {};
      if (ztvObj) {
        var data;
        var addNew = {
          resp: {
            Ztv: {},
          },
        };
        if (localStorage.getItem("saveSetData")) {
          data = JSON.parse(localStorage.getItem("saveSetData"));
        } else {
          data = addNew;
        }
        for (var type in ztvObj) {
          if (type == "ZtvMinDimLevel") {
            this.ztvValues[type] = Number(ztvObj[type]);
          } else {
            this.ztvValues[type] = ztvObj[type];
          }
        }
        data["resp"]["Ztv"] = this.ztvValues;
        localStorage.setItem("saveSetData", JSON.stringify(data));
      }
    });
    this.subscription = this.apiService.getZtvEdited.subscribe((isEdited) => {
      this.ztvvalueChange = isEdited;
    });
    this.subscription = this.apiService.getztvInputError.subscribe(
      (isZtvError) => {
        this.isZtvError = isZtvError;
      }
    );
    //Min Dim Level Start
    this.apiService.getMinDimLevelData.subscribe((response) => {
      if (response) {
        this.minDimLevelFeatureObj = response;
      }
      this.minDimLevelEnableCreatFile = false;
      this.subscription = this.apiService.getminDimLevelInputError.subscribe(
        (isminDimError) => {
          this.minDimLevelEnableCreatFile = isminDimError;
        }
      );
    });
    ////Min Dim Level End
    this.apiService.getDtldData.subscribe((response) => {
      if (response) {
        this.dtlFeatureObj = response;
      }
      this.dtlEnableCreatFile = false;
      this.subscription = this.apiService.getdtlInputError.subscribe(
        (isDtlError) => {
          this.dtlEnableCreatFile = isDtlError;
        }
      );
    });
    // Clolite start
    this.apiService.getCloLitedData.subscribe((response) => {
      if (response) {
        this.cloLiteFeatureObj = response;
      }
      this.cloLiteEnableCreatFile = false;
      this.subscription = this.apiService.getCLOliteInputError.subscribe(
        (isCloLiteError) => {
          this.cloLiteEnableCreatFile = isCloLiteError;
        }
      );
    });
    // Clolite end

    this.apiService.getCorridorData.subscribe((data) => {
      if (data) {
        this.corridorFeatureObj = data;
      }
      this.corridorEnableCreatFile = false;
      this.subscription = this.apiService.getCorridorError.subscribe(
        (isCorridorError) => {
          this.corridorEnableCreatFile = isCorridorError;
        }
      );
    });
    this.apiService.getLineSwitchData.subscribe((data) => {
      if (data) {
        this.lineSwitchFeatureObj = data;
      }
      this.lineSwitchEnableCreatFile = false;
      this.subscription = this.apiService.getLineSwitchError.subscribe(
        (islineSwitchError) => {
          this.lineSwitchEnableCreatFile = islineSwitchError;
        }
      );
    });
    this.apiService.getcodedmainsData.subscribe((data) => {
      if (data) {
        this.codedmainsFeatureObj = data;
      }
      this.codedmainsEnableCreatFile = false;
      this.subscription = this.apiService.getcodedmainsError.subscribe(
        (iscodedmainsError) => {
          this.codedmainsEnableCreatFile = iscodedmainsError;
        }
      );
    });
    this.apiService.getowpData.subscribe((data) => {
      if (data) {
        this.owpFeatureParametersObj = data;
      }
      this.owpEnableCreatFile = false;
      this.subscription = this.apiService.getowpError.subscribe(
        (isowpError) => {
          this.owpEnableCreatFile = isowpError;
        }
      );
    });
    this.apiService.getMtpData.subscribe((data) => {
      if (data) {
        this.mtpFeatureObj = data;
      }
      this.mtpEnableCreatFile = false;
      this.subscription = this.apiService.getMtpError.subscribe(
        (isMtpError) => {
          this.mtpEnableCreatFile = isMtpError;
        }
      );
    });
    //ztvoutput
    this.subscription = this.apiService.ztvoutputdata.subscribe((data) => {
      if (data) {
        this.ztvoutputObj = data;
      }
      this.ztvoutputEnableCreatFile = false;
      this.subscription = this.apiService.getztvoutputError.subscribe(
        (isztvoutputError) => {
          this.ztvoutputEnableCreatFile = isztvoutputError;
        }
      );
    });
    this.subscription = this.apiService.lipdata.subscribe((data) => {
      if (data) {
        this.lipObj = data;
      }
      this.lipEnableCreatFile = false;
      this.subscription = this.apiService.getlipError.subscribe(
        (islipError) => {
          this.lipEnableCreatFile = islipError;
        }
      );
    });
    //rso
    this.subscription = this.apiService.rsodata.subscribe((data) => {
      if (data) {
        this.rsoObj = data;
      }
      this.rsoEnableCreatFile = false;
      this.subscription = this.apiService.getrsoError.subscribe(
        (isrsoError) => {
          this.rsoEnableCreatFile = isrsoError;
        }
      );
    });
    this.apiService.getAmpDimData.subscribe((data) => {
      if (data) {
        this.ampDimFeatureObj = data;
      }
      this.ampDimCreatFile = false;
      this.subscription = this.apiService.getampdimError.subscribe(
        (isAmpDimError) => {
          this.ampDimCreatFile = isAmpDimError;
        }
      );
    });

    this.apiService.getMultiConfigData.subscribe((data) => {
      if (data) {
        this.multiconfigData = data;
      }
    });

    this.apiService.getFlextuneData.subscribe(data => {
      if(data){
        this.flextuneData = data;
      }
    })

    this.apiService.isFlextuneValid.next(true);
    this.apiService.getFlextuneValid.subscribe(data => {
      this.isFlextuneQuickDisable = !data;
    })

    this.apiService.getDimmingData.subscribe((data) => {
      if (data) {
        this.dimmingInterfaceObj = data;
        this.isReadytoValidte = true;
      }
    });

    this.apiService.getValidDimming.subscribe((data) => {
      if (data === "valid") {
        this.disableDimmingInterface = false;
      } else if (data === "invalid") {
        this.disableDimmingInterface = true;
      }
    });

    // this.apiService.getCorridorEdtedData.subscribe((data) => {
    //   if (data) {
    //   }
    // });
    this.subscription = this.apiService.checkPSUenable.subscribe((data) => {
      if (data) {
        var psudata;
        var addNew = {
          resp: {
            SrPsu: {},
          },
        };
        if (localStorage.getItem("saveSetData")) {
          psudata = JSON.parse(localStorage.getItem("saveSetData"));
        } else {
          psudata = addNew;
        }
        psudata["resp"]["SrPsu"] = this.psuFeatureParamtersObj = data;
        localStorage.setItem("saveSetData", JSON.stringify(psudata));
      }
    });
    this.subscription = this.apiService.checkLGPSUenable.subscribe(
      (psuEnable) => {
        this.isLGPsuEnabled = psuEnable;
        let counting = 0;
        if (psuEnable) {
          counting = counting + 1;
        }
        this.countPSULast = counting;
      }
    );
    this.subscription = this.apiService.checkHBPSUenable.subscribe(
      (psuEnable) => {
        this.isHBPsuEnabled = psuEnable;
        let counting = 0;
        if (psuEnable) {
          counting = counting + 1;
        }
        this.countPSUHeart = counting;
      }
    );

    this.apiService.getConfigMode.subscribe((resp) => {
      if (resp === "pass") {
        this.configOmitOnRefresh();
      }
    });

    this.subscription = this.apiService.getDcemergencyInputError.subscribe(
      (isDCEmergencyError) => {
        this.dcemergencyValueValid = isDCEmergencyError;
      }
    );
    this.subscription = this.apiService.getTouchDimInputError.subscribe(
      (isTouchDimError) => {
        this.touchDimValueValid = isTouchDimError;
      }
    );
    this.subscription =
      this.apiService.getdiia253maintenanceInputError.subscribe(
        (isdiia253maintenanceError) => {
          this.diia253maintenanceValueValid = isdiia253maintenanceError;
        }
      );
    this.subscription = this.apiService.getowpError.subscribe((isowpError) => {
      this.owpValueValid = isowpError;
    });
    //start of logic for mocking for multi driver configuration
    let data = JSON.parse(localStorage.getItem("readerDeviceFullInfo"));
    //if (data[0].device12nc === "AS929002179006NOTINUSE") {
    // if (
    //   !isNullOrUndefined(data[0].configurationInterface) &&
    //   !data[0].configurationInterface.includes(
    //     "SimpleSetMultiDeviceConfiguration"
    //   )
    // ) {
    //   data[0].configurationInterface.push(
    //     "SimpleSetMultiDeviceConfiguration"
    //   );
    // }
    // if (
    //   !isNullOrUndefined(data[0].supportedConfigProtocol) &&
    //   !data[0].supportedConfigProtocol.includes(
    //     "SimpleSetMultiDeviceConfiguration"
    //   )
    // ) {
    //   data[0].supportedConfigProtocol.push(
    //     "SimpleSetMultiDeviceConfiguration"
    //   );
    // }
    if (
      !isNullOrUndefined(data[0].configurationInterface) &&
      data[0].configurationInterface.includes("SimpleSetBoxConfiguration")
      //   ||
      // (!isNullOrUndefined(data[0].supportedConfigProtocol) &&
      //   data[0].supportedConfigProtocol.includes(
      //     "SimpleSetMultiDeviceConfiguration"
      //   ))
    ) {
      this.isSimpleSetMultiDeviceConfiguration = true;
    } else {
      this.isSimpleSetMultiDeviceConfiguration = false;
    }
    localStorage.setItem("readerDeviceFullInfo", JSON.stringify(data));
    //}
    //end of logic for mocking for multi driver configuration
    if (localStorage.getItem("quickConfiguration")) {
      let quickConfigVal = JSON.parse(
        localStorage.getItem("quickConfiguration")
      );
      if (quickConfigVal === "quickConfig") {
        this.configurationText = "Guided configuration";
        this.headerText = "Standard configuration";
        this.quickConfig = true;
        this.apiService.sendQuickConfig("quickConfig");
        this.allFeature = [];
        this.countQuickFeature = 0;
        this.receivedFeature.selectedFeaturesInfo = [];
        let selectedFeature = JSON.parse(
          localStorage.getItem("configurationData")
        );
        this.configurationText = "Guided configuration";
        this.headerText = "Standard configuration";
        this.quickConfig = true;
        this.apiService.sendQuickConfig("quickConfig");
        localStorage.setItem(
          "quickConfiguration",
          JSON.stringify("quickConfig")
        );
        selectedFeature.feature12Nc.forEach((element) => {
          element = element.slice(0, -1);
          selectedFeature.feature12Nc =
            element.charAt(0).toUpperCase() + element.slice(1);
          if (selectedFeature.feature12Nc === "Luminfoformat3") {
            selectedFeature.feature12Nc = "LumInfo";
          }
          if (selectedFeature.feature12Nc === "Ast") {
            selectedFeature.feature12Nc = "Startup time";
          }
          if (selectedFeature.feature12Nc === "Ztv") {
            selectedFeature.feature12Nc = "0-10v / 1-10v";
          }

          if (selectedFeature.feature12Nc === "Dcemergency") {
            selectedFeature.feature12Nc = "DC Emergency";
          }
          if (
            selectedFeature.feature12Nc === "touchdim" ||
            selectedFeature.feature12Nc === "touchdim-"
          ) {
            selectedFeature.feature12Nc = "Touch and Dim";
          }
          if (
            selectedFeature.feature12Nc === "diia253maintenance" ||
            selectedFeature.feature12Nc === "diia253maintenance-"
          ) {
            selectedFeature.feature12Nc = "diia253maintenance";
          }
          if (
            selectedFeature.feature12Nc === "owp" ||
            selectedFeature.feature12Nc === "owp-"
          ) {
            selectedFeature.feature12Nc = "owp";
          }
          if (
            selectedFeature.feature12Nc === "corridor" ||
            selectedFeature.feature12Nc === "corridor-"
          ) {
            selectedFeature.feature12Nc = "Corridor";
          }
          if (selectedFeature.feature12Nc === "Mindimlevel") {
            selectedFeature.feature12Nc = "Min Dim Level";
          }

          this.allFeature.push(selectedFeature.feature12Nc);
          this.receivedFeature.selectedFeaturesInfo.push(
            selectedFeature.feature12Nc
          );
          this.receivedFeature.selectedFeaturesInfo =
            this.receivedFeature.selectedFeaturesInfo.sort();
          // if(this.oldValue ) {
          //   setTimeout(() => {
          //   this.schemaApiCalls();
          // }, 1000);
          // }
        });
        // for(let i=0; i< this.receivedFeature.selectedFeaturesInfo.length; i++) {
        //   if(this.receivedFeature.selectedFeaturesInfo[i]==='Clolite') {
        //     this.receivedFeature.selectedFeaturesInfo[i] = 'CloLite'
        //   }
        //   this.onCheckboxChnge(this.receivedFeature.selectedFeaturesInfo[i],true);
        // }
        this.receivedFeature.selectedFeaturesInfo = [];
        this.newFeatureArray = [];
        for (let i = 0; i < this.receivedFeature.feature12Nc.length; i++) {
          this.onCheckboxChnge(this.receivedFeature.feature12Nc[i], false);
        }
      } else {
        this.receivedFeature.selectedFeaturesInfo = [];
        this.countQuickFeature = 0;
        let selectedFeature = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if( selectedFeature.selectedFeaturesInfo){
          selectedFeature.selectedFeaturesInfo.forEach((element) => {
            this.receivedFeature.selectedFeaturesInfo.push(element);
            this.receivedFeature.selectedFeaturesInfo =
              this.receivedFeature.selectedFeaturesInfo.sort();
          });
        }
        
        // for(let i=0; i< this.receivedFeature.selectedFeaturesInfo.length; i++) {
        //   if(this.receivedFeature.selectedFeaturesInfo[i]==='Clolite') {
        //     this.receivedFeature.selectedFeaturesInfo[i] = 'CloLite'
        //   }
        //   this.onCheckboxChnge(this.receivedFeature.selectedFeaturesInfo[i],true);
        // }
        this.receivedFeature.selectedFeaturesInfo = [];
        this.newFeatureArray = [];
        for (let i = 0; i < this.receivedFeature.feature12Nc.length; i++) {
          this.onCheckboxChnge(this.receivedFeature.feature12Nc[i], false);
        }
        this.configurationText = "Standard configuration";
        this.headerText = "Guided configuration";
        this.quickConfig = false;
        // this.apiService.sendModes('Advanced');
      }
    } else {
      this.quickConfig = false;
      let selectedFeature = JSON.parse(
        localStorage.getItem("configurationData")
      );
      // selectedFeature.selectedFeaturesInfo.forEach(element => {
      //   this.newFeatureArray.push(element);
      // });
      this.receivedFeature.selectedFeaturesInfo = [];
      this.newFeatureArray = [];
      this.countQuickFeature = 0;
      for (let i = 0; i < this.receivedFeature.feature12Nc.length; i++) {
        this.onCheckboxChnge(this.receivedFeature.feature12Nc[i], false);
      }
      this.apiService.sendQuickConfig("standard");
    }

    this.apiService.getmodes.subscribe((resp) => {
      if (resp === "Standard") {
        this.configurationText = "Standard configuration";
        this.changeConfig();
      } else if (resp === "Advanced") {
        this.configurationText = "Guided configuration";
        this.changeConfig();
      }
    });
    let booleanValue = true;
    this.receivedFeature.selectedFeaturesInfo.forEach((feature) => {
      if (
        [
          "0-10v / 1-10v",
          "Psu",
          "Alo",
          "Aoc",
          "Clo",
          "CloLite",
          "Clolite",
          "clolite-",
          "Startup time",
          "Lumin",
          "LumInfo",
          "Dtl",
          "DTL",
          "Min Dim Level",
          "Min dim level",
          "Corridor",
          "corridor-",
          "corridor",
          "DC Emergency",
          "Touch and Dim",
          "ztv-",
          "alo-",
          "aoc-",
          "touchdim",
          "touchdim-",
          "dynadimmer-",
          "codedmains-",
          "Dyna Dimmer",
          "owp-",
        ].includes(feature)
      ) {
        this.allFeatures = true;
        booleanValue = false;
      }
    });
    if (booleanValue) {
      this.allFeatures = false;
    }

    /*  setTimeout(() => {
      this.apiService.getBaseUrl();
      this.apiService.getReadData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.readerBaseUrl = url;
            this.getDeviceReader().subscribe(
              (resp) => {
                if (resp) {
                  this.unsubscribe.next();
                  this.isReadDevice = false;
                  this.loading = false;
                }
              },
              (err) => {
                this.isReadDevice = true;
                this.isNormalFlow = true;
                this.loading = false;
              }
            );
          }
        });
    }, 0); */
    setTimeout(() => {
      this.apiService.getBaseUrl();
      this.apiService.getReadData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.readerBaseUrl = url;
            this.getDeviceReader().subscribe(
              (resp) => {
                if (resp) {
                  this.unsubscribe.next();
                  this.isReadDevice = false;
                  this.loading = false;
                }
              },
              (err) => {
                this.isReadDevice = true;
                this.isNormalFlow = true;
                this.loading = false;
              }
            );
          }
        });
    }, 4000);
    setTimeout(() => {
      let impotredData = JSON.parse(localStorage.getItem("importFeatureData"));
      if (impotredData) {
        let successfulFeatures: any[] = impotredData.successfulFeatures;
        if (impotredData.failedFeatures.length > 0) {
          impotredData.failedFeatures.forEach((element) => {
            if (
              element["message"].split(" ").join("").toLowerCase().trim() ===
              "invaliddata."
            ) {
              successfulFeatures.push(element["featureName"]);
            }
          });
        }

        successfulFeatures.forEach((successfulfeat) => {
          if (successfulfeat.toString().toUpperCase() === "ZTV") {
            this.onCheckboxChnge("ztv-", true);
          } else if (successfulfeat.toString().toUpperCase() === "AOC") {
            this.onCheckboxChnge("aoc-", true);
          } else if (successfulfeat.toString().toUpperCase() === "ALO") {
            this.onCheckboxChnge("alo-", true);
          } else if (successfulfeat.toString().toUpperCase() === "DALIPSU") {
            this.onCheckboxChnge("srpsu-", true);
          } else if (successfulfeat.toString().toUpperCase() === "AST") {
            this.onCheckboxChnge("ast-", true);
          } else if (successfulfeat.toString().toUpperCase() === "CLO") {
            this.onCheckboxChnge("clo-", true);
          } else if (successfulfeat.toString().toUpperCase() === "CLOLITE") {
            this.onCheckboxChnge("clolite-", true);
          } else if (
            successfulfeat.toString().toUpperCase() ===
            "LumInfoFormat".toUpperCase()
          ) {
            this.onCheckboxChnge("Lumin", true);
          }
          //MOWEB 1.0.1
          else if (successfulfeat.toString().toUpperCase() === "DYNADIMMER") {
            this.onCheckboxChnge("dynadimmer-", true);
          } else if (successfulfeat.toString().toUpperCase() === "LINESWITCH") {
            this.onCheckboxChnge("lineswitch-", true);
          } else if (successfulfeat.toString().toUpperCase() === "CODEDMAINS") {
            this.onCheckboxChnge("codedmains-", true);
          } else if (successfulfeat.toString().toUpperCase() === "MTP") {
            this.onCheckboxChnge("mtp-", true);
          } else if (successfulfeat.toString().toUpperCase() === "AMPDIM") {
            //MOWEB 1.1

            this.onCheckboxChnge("ampdim-", true);
          } else if (successfulfeat.toString().toUpperCase() === "DTL") {
            this.onCheckboxChnge("dtl-", true);
          } else if (
            successfulfeat.toString().toUpperCase() === "DCEMERGENCY"
          ) {
            this.onCheckboxChnge("dcemergency-", true);
          } else if (
            successfulfeat.toString().toUpperCase() === "MINDIMLEVEL"
          ) {
            this.onCheckboxChnge("mindimlevel-", true);
          } else if (successfulfeat.toString().toUpperCase() === "TOUCHDIM") {
            this.onCheckboxChnge("touchdim-", true);
          } else if (
            successfulfeat.toString().toUpperCase() === "DIIA253MAINTENANCE"
          ) {
            this.onCheckboxChnge("diia253maintenance-", true);
          } else if (successfulfeat.toString().toUpperCase() === "OWP") {
            this.onCheckboxChnge("owp-", true);
          } else if (successfulfeat.toString().toUpperCase() === "CORRIDOR") {
            this.onCheckboxChnge("corridor-", true);
          } else if (
            successfulfeat.toString().toUpperCase() === "DIMMINGINTERFACE"
          ) {
            this.onCheckboxChnge("dimminginterface-", true);
          }
          //MOWEB 1.0.1 DONE
        });
        localStorage.removeItem("importFeatureData");
      }
    }, 4000);

    
  }

  canShowZtv(){
    if(this.ztvSchema.length > 0) {
      let version = this.ztvSchema[0]['featureVersion'];
      if(version == 6 || version == 7) return false;
    }
    return true;
  }

  featureDataFetched(feature){
    this.completedApiCalls[feature] = true;
    if(this.deviceFeatures.every(feature => this.completedApiCalls[feature])){
      this.afterAllApiCompleted();
    }
  }
  afterAllApiCompleted(){
    
    let dcflowtype = JSON.parse(localStorage.getItem("normalFlow"));
    let checkFlow = localStorage.getItem("loadffflow");
    let multiDriver = JSON.parse(localStorage.getItem("multidevice"));
    let configData = JSON.parse(localStorage.getItem("configurationData"));
    let multiDeviceSelectedFeatures =  JSON.parse(localStorage.getItem("multideviceSelectedFeatures"));

    this.increamentLoader();
    let finalSelectedList = this.initialSelectedFeatures;
    setTimeout(() => {
      let readingCheck = localStorage.getItem("readingDevice");
      if(readingCheck != "true") {
        this.deviceFeatures.forEach(feature => {
          if(feature == 'dalipsu-') feature = 'srpsu-';
          if(this.initialSelectedFeatures.includes(feature))this.onCheckboxChnge(feature, true)
        })
      }
      if (checkFlow != null && checkFlow === "false" && dcflowtype === true)
        this.validateIncludeInConfig();

      if (readingCheck === "true") {
        this.validateIncludeInConfigRead();
        finalSelectedList = [];
      }

      let isWritingChck = JSON.parse(localStorage.getItem("isWriting"));
      if (isWritingChck)  finalSelectedList = this.UncheckUnSelectedConfigFeat(false);
      if(multiDriver && multiDeviceSelectedFeatures) {
        this.checkMultiDeviceSelectedFeatures();
        finalSelectedList = multiDeviceSelectedFeatures;
      }
      localStorage.setItem("readingDevice", JSON.stringify(false));
      localStorage.setItem("loadffflow", "false");
      localStorage.setItem("multidevice", "false");
      localStorage.removeItem("ExcludedDCFeat");
      localStorage.removeItem("multideviceSelectedFeatures");
      localStorage.setItem("isWriting", JSON.stringify(false))
      setTimeout(() => {
        if(configData){
          configData['selectedFeaturesInfo'] = finalSelectedList;
        }
        this.loadingComponetsRen = false;
        this.decreamentLoader();
        localStorage.setItem('configurationData',  JSON.stringify(configData));
      }, 1000)
    }, 4000);
  }

  checkMultiDeviceSelectedFeatures(){
    let multiDeviceSelectedFeatures =  JSON.parse(localStorage.getItem("multideviceSelectedFeatures"));
    this.deviceFeatures.forEach(feature => {
      if(multiDeviceSelectedFeatures.includes(feature)) this.onCheckboxChnge(feature, true);
      else  this.onCheckboxChnge(feature, false);
    })
  }

  refreshReadersList() {
    this.apiService.getBaseUrl();

    this.apiService.getReadData
      .pipe(takeUntil(this.readBaseUrlUnSubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.readBaseUrlUnSubscribe.next();
          let status = this.getReader();
          let readerSelection = this.getReaderList();

          this.statusObs$ = status
            .pipe(
              flatMap((data) => {
                this.statusCallData = data;
                this.statusCallDataSwVersion =
                  this.statusCallData.serverSwVersion
                    .toString()
                    .split(".")
                    .join("");
                this.latestDcsVersion = JSON.parse(
                  localStorage.getItem("latestDcsVersion")
                );
                if (
                  !isNullOrUndefined(this.latestDcsVersion) &&
                  !isNullOrUndefined(this.statusCallDataSwVersion)
                ) {
                  if (
                    parseInt(this.latestDcsVersion) >
                    parseInt(this.statusCallDataSwVersion)
                  ) {
                    this.diasbleConfig = false;
                  } else {
                    this.diasbleConfig = true;
                  }
                }
                return readerSelection.pipe(
                  catchError((err) => {
                    // do stuff with err from API1 request
                    return throwError(err);
                  })
                );
              }),
              catchError((err) => {
                // do stuff with err from API2 request
                return throwError(err);
              })
            )
            .subscribe(
              (results) => {
                this.readerData = results;
                this.isEnableConfigDevice = true;
                localStorage.setItem("readerDevices", JSON.stringify(results));

                let readers = JSON.parse(
                  localStorage.getItem("detectedReaderInfo")
                );
                let newReader = false;
                if (
                  !isNullOrUndefined(readers && readers["selectedReader"]) &&
                  results[0]["peripheralType"] !==
                    readers["selectedReader"]["peripheralType"]
                ) {
                  newReader = true;
                }

                if (
                  !readers ||
                  (readers && isNullOrUndefined(readers["selectedReader"])) ||
                  newReader
                ) {
                  let detectedReader = {
                    readerType: "SimpleSet",
                    selectedReader: this.readerData[0],
                    readerConnected: true,
                    isDcsConnected: true,
                  };

                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReader)
                  );
                  let selevtedReadArr: any[] = [];
                  selevtedReadArr.push(this.readerData[0]);
                  localStorage.setItem(
                    "readerInfo",
                    JSON.stringify(selevtedReadArr)
                  );
                }
              },
              (error) => {
                this.diasbleConfig = false;
                localStorage.removeItem("readerDevices");
                if (
                  error &&
                  !isNullOrUndefined(error.target && error.target.url) &&
                  error.target.url === "http://localhost:2020/server/v1/status"
                ) {
                  this.isDcsConnected = false;
                } else {
                  this.isDcsConnected = true;
                }
                let detectedReader = {
                  readerType: "SimpleSet",
                  selectedReader: null,
                  readerConnected: false,
                  isDcsConnected: this.isDcsConnected,
                };

                localStorage.setItem(
                  "detectedReaderInfo",
                  JSON.stringify(detectedReader)
                );
                this.isEnableConfigDevice = false;
              }
            );
        }
      });
  }

  getReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
  }

  getReaderList() {
    return this.apiService.apiCall(
      "get",
      this.readerBaseUrl + this.readerListUrl
    );
  }
  reValidateDtlConfigCheckbox() {
    let mode = JSON.parse(localStorage.getItem("modes"));
    if (
      this.dtlFinalDefaultColor === true &&
      this.loadingComponetsRen === false &&
      mode === "Advanced"
    ) {
      if (this.restoreDtl && this.configDtlCheckbox == undefined) {
        this.onCheckboxChnge("dtl-", this.dtlFinalDefaultColor);
      } else if (this.configDtlCheckbox == undefined && !this.restoreDtl) {
        this.onCheckboxChnge("dtl-", false);
      } else if (
        !isNullOrUndefined(this.configDtlCheckbox) &&
        !this.restoreDtl
      ) {
        this.onCheckboxChnge("dtl-", this.configDtlCheckbox);
      }
    }
  }

  checkDtlConfigCheckbox(value) {
    this.configDtlCheckbox = value;
    this.restoreDtl = false;
  }
  validateIncludeInConfigRead() {
    if (this.loadingComponetsRen === false) {
      this.onCheckboxChnge("alo-", false);
      this.onCheckboxChnge("ast-", false);
      this.onCheckboxChnge("aoc-", false);
      this.onCheckboxChnge("srpsu-", false);
      this.onCheckboxChnge("clo-", false);
      this.onCheckboxChnge("clolite-", false);
      this.onCheckboxChnge("touchdim-", false);
      this.onCheckboxChnge("diia253maintenance-", false);
      this.onCheckboxChnge("Lumin", false);
      this.onCheckboxChnge("dtl-", false);
      this.onCheckboxChnge("mindimlevel-", false);
      this.onCheckboxChnge("ztv-", false);
      this.onCheckboxChnge("corridor-", false);
      this.onCheckboxChnge("mtp-", false);
      this.onCheckboxChnge("owp-", false);
      this.onCheckboxChnge("ampdim-", false);
      this.onCheckboxChnge("lineswitch-", false);
      this.onCheckboxChnge("codedmains-", false);
      this.onCheckboxChnge("dcemergency-", false);
      this.onCheckboxChnge("dimminginterface-", false);
      this.onCheckboxChnge("dynadimmer-", false);
      this.onCheckboxChnge("flextune-", false);
      this.onCheckboxChnge("multiconfigmode-", false);
    }
  }
  setFeatureAPI(callAPIFlag: boolean) {
    this.apiService.setAocAPI(callAPIFlag);
    this.apiService.setAloAPI(callAPIFlag);
    this.apiService.setCloLiteAPI(callAPIFlag);
    this.apiService.setCloAPI(callAPIFlag);
    this.apiService.setStartUpTimeAPI(callAPIFlag);
    this.apiService.setDaliPSUAPI(callAPIFlag);
    this.apiService.setCodedMainsAPI(callAPIFlag);
    this.apiService.setLineSwitchAPI(callAPIFlag);
    this.apiService.setDimmingInterfaceAPI(callAPIFlag);
    this.apiService.setowpAPI(callAPIFlag);
    this.apiService.setZtvAPI(callAPIFlag);
    this.apiService.setLuminAPI(callAPIFlag);
    this.apiService.setDtlAPI(callAPIFlag);
    this.apiService.setCorridorAPI(callAPIFlag);
    this.apiService.setMtpAPI(callAPIFlag);
    this.apiService.setAmpDimAPI(callAPIFlag);
    this.apiService.setDCEmeregncyAPI(callAPIFlag);
    this.apiService.setMinDimLevelAPI(callAPIFlag);
    this.apiService.setTouchDimAPI(callAPIFlag);
    this.apiService.setDynaDimmerAPI(callAPIFlag);
    this.apiService.setMulticonfigAPI(callAPIFlag);
  }
  validateIncludeInConfig() {
    if (this.loadingComponetsRen === false) {
      if (!(this.owpDefaultColor === ""))
        this.onCheckboxChnge("owp-", !this.owpDefaultColor);
      if (!(this.aloDefaultColor === ""))
        this.onCheckboxChnge("alo-", !this.aloDefaultColor);
      if (!(this.startupDefaultColor === ""))
        this.onCheckboxChnge("ast-", !this.startupDefaultColor);
      if (!(this.aocDefaultColor === ""))
        this.onCheckboxChnge("aoc-", !this.aocDefaultColor);
      if (!(this.srpsuDefaultColor === ""))
        this.onCheckboxChnge("srpsu-", !this.srpsuDefaultColor);
      if (!(this.cloLiteDefaultColor === ""))
        this.onCheckboxChnge("CLO_CLOLITE", !this.cloLiteDefaultColor);
      if (!(this.luminDefaultColor === ""))
        this.onCheckboxChnge("Lumin", !this.luminDefaultColor);
      if (!(this.dtlDefaultColor === ""))
        this.onCheckboxChnge("dtl-", !this.dtlDefaultColor);
      if (!(this.flextuneDefaultColor === ""))
        this.onCheckboxChnge("flextune-", !this.flextuneDefaultColor);
      if (!(this.minDimLevelDefaultColor === ""))
        this.onCheckboxChnge("mindimlevel-", !this.minDimLevelDefaultColor);
      if (!(this.touchDimDefaultColor === ""))
        this.onCheckboxChnge("touchdim-", !this.touchDimDefaultColor);
      if (!(this.diia253maintenanceDefaultColor === ""))
        this.onCheckboxChnge(
          "diia253maintenance-",
          !this.diia253maintenanceDefaultColor
        );
      if (!(this.ztvDefaultColor === ""))
        this.onCheckboxChnge("ztv-", !this.ztvDefaultColor);
      if (!(this.ztvoutputDefaultColor === ""))
        this.onCheckboxChnge("ztvoutput-", !this.ztvoutputDefaultColor);
      if (!(this.lipDefaultColor === ""))
        this.onCheckboxChnge("lip-", !this.lipDefaultColor);
      if (!(this.rsoDefaultColor === ""))
        this.onCheckboxChnge("rso-", !this.rsoDefaultColor);
      if (!(this.mtpDefaultColor === ""))
        this.onCheckboxChnge("mtp-", !this.mtpDefaultColor);
      if (!(this.corrdiorDefaultColor === ""))
        this.onCheckboxChnge("corridor-", !this.corrdiorDefaultColor);
      if (!(this.dcemergencyDefaultColor === ""))
        this.onCheckboxChnge("dcemergency-", !this.dcemergencyDefaultColor);
      if (!(this.ampdimDefaultColor === ""))
        this.onCheckboxChnge("ampdim-", !this.ampdimDefaultColor);
      if (!(this.lineSwitchDefaultColor === ""))
        this.onCheckboxChnge("lineswitch-", !this.lineSwitchDefaultColor);
      if (!(this.codedmainsDefaultColor === ""))
        this.onCheckboxChnge("codedmains-", !this.codedmainsDefaultColor);
      if (!(this.dynaDefaultColor === ""))
        this.onCheckboxChnge("dynadimmer-", !this.dynaDefaultColor);
      if (!this.multiconfigDefaultColor)
        this.onCheckboxChnge("multiconfigmode-", !this.multiconfigDefaultColor);
    }
  }
  dcSelectFeatinFLP() {
    const dcFeatures = JSON.parse(
      localStorage.getItem("readerConfigurationData")
    );

    let DcselectedFeatsInfo = dcFeatures.selectedFeaturesInfo;
    if (DcselectedFeatsInfo) {
      for (let index = 0; index <= DcselectedFeatsInfo.length - 1; index++) {
        const element = DcselectedFeatsInfo[index];

        this.onCheckboxChnge(element, true);
      }
    }
  }
  UncheckUnSelectedConfigFeat(status) {
    let selectedList = [];
    let ConfigureExludedFeat = JSON.parse(
      localStorage.getItem("ExcludedDCFeat")
    );

    if (
      ConfigureExludedFeat != undefined &&
      ConfigureExludedFeat["FeatureListExcluded"].length > 0
    ) {
      this.deviceFeatures.forEach(feature => {
        if(ConfigureExludedFeat["FeatureListExcluded"].includes(feature)) this.onCheckboxChnge(feature, status);
        else  this.onCheckboxChnge(feature, !status);
        if(!status) selectedList.push(feature);
      })
    }
    return selectedList;
  }

  revalidateIncludeinConfigCheckBox() {
    try {
      const lineSwitchLoaddedFetaure = JSON.parse(
        localStorage.getItem("configurationData")
      );
      if (lineSwitchLoaddedFetaure.resp != undefined) {
        /* if (!isNullOrUndefined(this.cloSchema && this.cloFeatureParametersObj["CloPoint1"]) && this.cloFeatureParametersObj["CloEnabled"] === this.cloSchema['properties']['CloEnabled']['default'] &&
          this.cloFeatureParametersObj["CloPoint1"]["Time"] === this.cloSchema['properties']['CloPoint1']['properties']['Time']['const'] &&
          this.cloFeatureParametersObj["CloPoint1"]["Percentage"] === this.cloSchema['properties']['CloPoint1']['properties']['Percentage']['default']) {
         
            this.cloDefaultColor = true;
            
        } else {
          this.cloDefaultColor = false;
          
        } */
        /*       if (lineSwitchLoaddedFetaure.resp.CLO != undefined) {
          this.onCheckboxChnge("clo-", true);
        } else if (this.cloDefaultColor === true)
          this.onCheckboxChnge("clo-", false); */

        //MOWEB 1.0.1 DONE MOWEB 1.1
        /*   if (lineSwitchLoaddedFetaure.resp.Dynadimmer != undefined) {
          this.onCheckboxChnge("dynadimmer-", true);
        } else if (this.dynaDefaultColor === true)
          this.onCheckboxChnge("dynadimmer-", false);
 */
        if (lineSwitchLoaddedFetaure.resp.DTL != undefined) {
          // this.onCheckboxChnge("dtl-", true);
        } else if (this.dtlDefaultColor === true)
          this.onCheckboxChnge("dtl-", false);

        if (lineSwitchLoaddedFetaure.resp.MTP != undefined) {
          this.onCheckboxChnge("mtp-", true);
        } else if (this.mtpDefaultColor === true)
          this.onCheckboxChnge("mtp-", false);
        //MOWEB 1.0.1 DONE
      } else {
        //MOWEB 1.0.1 DONE
        /*  if (this.dynaDefaultColor === true) MOWEB 1.1
          this.onCheckboxChnge("dynadimmer-", false); */
        if (this.dtlDefaultColor === true) this.onCheckboxChnge("dtl-", false);
        //MOWEB 1.0.1 DONE
      }
      // if (this.lineSwitchDefaultColor === true)
      //   this.onCheckboxChnge("lineswitch-", false);
      //if (this.mtpDefaultColor === true) this.onCheckboxChnge("mtp-", false);
      //if (this.dtlDefaultColor === true) this.onCheckboxChnge("dtl-", false);
      if (this.ampdimDefaultColor === true)
        this.onCheckboxChnge("ampdim-", false);
    } catch (error) {}
  }

  RemoveItem(item) {
    const index: number = this.receivedFeature.feature12Nc.indexOf(item);
    if (index !== -1) {
      this.receivedFeature.feature12Nc.splice(index, 1);
    }
  }
  getDeviceReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
    // return this.service.apiCall('get', '/assets/reader/status.json');
  }
  /////////////SCHEMA API CALLS - BASED on Features////

  callAllApi() {
    // let call1
    // let call2
    // let call3
    // let call4
    // let call5
    // let call6
    // let call7
    // let call8
    setTimeout(() => {
      this.customLoader = true;
    }, 100);
    forkJoin([
      this.receivedFeature.feature12Nc.filter((item) => {
        if (item.match(/[^,]*aoc[^,]*/g)) {
          this.Feature12nc = item;
          this.aocSelectedFeature = "Aoc";
          this.apiService.setAocAPI(true);
          //this.aocSchemaListDetails();
        }
        if (item.match(/[^,]*codedmains[^,]*/g)) {
          this.Feature12nc = item;
          this.codedmainsSelectedFeature = "Codedmains";
          this.apiService.setCodedMainsAPI(true);
          //this.codedmainsListSchemaDetails();
        }
        //for line switch
        if (item.match(/[^,]*lineswitch-[^,]*/g)) {
          this.Feature12nc = item;
          this.lineswitchSelectedFeature = "Line Switch";
          this.apiService.setLineSwitchAPI(true);
          //this.lineswitchSchemaListDetails();
        }

        if (item.match(/[^,]*alo[^,]*/g)) {
          this.Feature12nc = item;
          this.aloSelectedFeature = "Alo";
          this.apiService.setAloAPI(true);
          //this.aloschemaListDetails();
        }
        if (item.match(/[^,]*dimminginterface[^,]*/g)) {
          this.Feature12nc = item;
          this.dimmingInterface = "dimmingInterface";
          this.apiService.setDimmingInterfaceAPI(true);
          //this.dimmingInterfaceSchemaListDetails();
        }
        if (item.match(/[^,]*clo-[^,]*/g)) {
          this.Feature12nc = item;
          this.cloSelectedFeature = "Clo";
          var dataAdd = JSON.parse(localStorage.getItem("configurationData"));
          if (
            (dataAdd && dataAdd["resp"] && dataAdd["resp"]["CLOLite"]) ||
            (dataAdd &&
              dataAdd["resp"] &&
              dataAdd["resp"]["CLOLite"] &&
              (dataAdd["resp"]["CLOLite"]["CloLiteEnabled"] == false ||
                dataAdd["resp"]["CLOLite"]["CloLiteEnabled"] == true))
          ) {
            if (dataAdd["resp"]["CLOLite"]) {
              //check if key exists
              delete dataAdd["resp"]["CLOLite"]; //remove the key from object
            }
            localStorage.setItem("configurationData", JSON.stringify(dataAdd));
          }
          this.apiService.sendCloText("Clo");
          this.apiService.setCloAPI(true);
          //this.cloSchemaListDetails();
        }
        if (item.match(/[^,]*clolite[^,]*/g)) {
          this.Feature12nc = item;
          this.cloLiteSelectedFeature = "Clolite";
          var dataAdd = JSON.parse(localStorage.getItem("configurationData"));
          if (
            dataAdd &&
            dataAdd["resp"] &&
            dataAdd["resp"]["CLO"] &&
            (dataAdd["resp"]["CLO"]["CloEnabled"] == false ||
              dataAdd["resp"]["CLO"]["CloEnabled"] == true)
          ) {
            if (dataAdd["resp"]["CLO"]) {
              //check if key exists
              delete dataAdd["resp"]["CLO"]; //remove the key from object
            }
            localStorage.setItem("configurationData", JSON.stringify(dataAdd));
          }
          this.apiService.sendCloLiteText("Clolite");
          this.apiService.setCloLiteAPI(true);
          //this.CloLIteListDetails();
        }
        if (item.match(/[^,]*dalipsu[^,]*/g)) {
          this.Feature12nc = item;
          this.psuSelectedFeature = "SrPsu";
          this.apiService.setDaliPSUAPI(true);
          //this.psuSchemaListDetails();
        }
        if (item.match(/[^,]*ztv[^,]*/g)) {
          this.Feature12nc = item;
          this.ztvSelectedFeature = "Ztv";
          this.apiService.setZtvAPI(true);
          //this.ztvListDetails();
        }
        if (item.match(/[^,]*luminfoformat[^,]*/g)) {
          this.Feature12nc = item;
          this.luminSelectedFeature = "luminfo";
          this.apiService.setLuminAPI(true);
          //this.lumInfoListDetails();
        }
        if (item.match(/[^,]*ast[^,]*/g)) {
          this.Feature12nc = item;
          this.startupSelectedFeature = "Ast";
          this.apiService.setStartUpTimeAPI(true);
          //this.startupSchemaListDetails();
        }
        if (item.match(/[^,]*dtl-[^,]*/g)) {
          this.Feature12nc = item;
          this.dtlSelectedFeature = "DTL";
          this.apiService.setDtlAPI(true);
          //this.DtlSchemaListDetails();
        }
        if (item.match(/[^,]*flextune-[^,]*/g)) {
          this.Feature12nc = item;
          this.dtlSelectedFeature = "Flextune";
          this.apiService.setFlextuneAPI(true);
        }
        if (item.match(/[^,]*corridor-[^,]*/g)) {
          this.Feature12nc = item;
          this.corridorSelectedFeature = "Corridor";
          this.apiService.setCorridorAPI(true);
          //this.corridorSchemaListDetails();
        }
        if (item.match(/[^,]*mtp-[^,]*/g)) {
          this.Feature12nc = item;
          this.mtpSelectedFeature = "Mtp";
          this.apiService.setMtpAPI(true);
          //this.mtpSchemaListDetails();
        }
        if (item.match(/[^,]*ztvoutput-[^,]*/g)) {
          this.Feature12nc = item;
          this.ztvoutputSelectedFeature = "ztvoutput";
          this.apiService.setztvoutputAPI(true);
        }
        if (item.match(/[^,]*rso-[^,]*/g)) {
          this.Feature12nc = item;
          this.rsoSelectedFeature = "rso";
          this.apiService.setrsoAPI(true);
        }
        if (item.match(/[^,]*lip-[^,]*/g)) {
          this.Feature12nc = item;
          this.lipSelectedFeature = "lip";
          this.apiService.setlipAPI(true);
        }
        if (item.match(/[^,]*ampdim-[^,]*/g)) {
          this.Feature12nc = item;
          this.ampDimSelectedFeature = "Amp Dim";
          this.apiService.setAmpDimAPI(true);
          //this.ampdimSchemaListDetails();
        }
        if (item.match(/[^,]*dcemergency-[^,]*/g)) {
          this.Feature12nc = item;
          this.dcemergencySelectedFeature = "DC Emergency";
          this.apiService.setDCEmeregncyAPI(true);
          //this.dcemergencySchemaListDetails();
        }
        if (item.match(/[^,]*mindimlevel-[^,]*/g)) {
          this.Feature12nc = item;
          this.minDimLevelSelectedFeature = "Mini Dim Level";
          this.apiService.setMinDimLevelAPI(true);
          //this.minDimLevelSchemaListDetails();
        }
        if (item.match(/[^,]*touchdim-[^,]*/g)) {
          this.Feature12nc = item;
          this.touchDimSelectedFeature = "Touch and Dim";
          this.apiService.setTouchDimAPI(true);
          //this.touchDimSchemaListDetails();
        }
        if (item.match(/[^,]*diia253maintenance-[^,]*/g)) {
          this.Feature12nc = item;
          this.touchDimSelectedFeature = "diia253maintenance";
          this.apiService.setdiia253maintenanceAPI(true);
        }
        //for owp
        if (item.match(/[^,]*owp-[^,]*/g)) {
          this.Feature12nc = item;
          this.owpSelectedFeature = "owp";
          this.apiService.setowpAPI(true);
        }
        if (item.match(/[^,]*dynadimmer-[^,]*/g)) {
          this.Feature12nc = item;
          this.dynadimSelectedFeature = "Dyna Dimmer";

          // var dataAdd = JSON.parse(localStorage.getItem('saveSetData'));
          // if (dataAdd && dataAdd['resp'] && dataAdd['resp']['DynaDimmer'])
          // {
          //   if (dataAdd['resp']['DynaDimmer']) {  //check if key exists
          //     delete dataAdd['resp']['DynaDimmer'];  //remove the key from object
          //   }
          //   localStorage.setItem('saveSetData', JSON.stringify(dataAdd));
          // }
          this.apiService.sendDynaDimmerText("Dyna Dimmer");
          this.apiService.setDynaDimmerAPI(true);
          //this.dynaDimmerSchemaListDetails();
        }
        if (item.match(/[^,]*multiconfigmode-[^,]*/g)) {
          this.Feature12nc = item;
          this.multiconfig = "multiconfigmode";
          this.apiService.setMulticonfigAPI(true);
          //this.dynaDimmerSchemaListDetails();
        }
      }),
    ]).subscribe((allresp) => {
      this.customLoader = false;
    });

    setTimeout(() => {
      this.customLoader = false;
    }, 9000);

    const index: number = this.receivedFeature.feature12Nc.indexOf("ztv-");
    if (index === -1) {
      this.isPageReady = true;
    }

    // return forkJoin([call1,call3, call4, call5, call6, call7, call8, call8]);
  }
  ampdimSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('ampdim'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.ampDimSchemaDetailsApi().subscribe(
              (resp: any) => {
                const ampDimJSON: any[] = Array.of(resp);
                ampDimJSON.forEach((key) => {
                  key["key"] = "AmpDim";
                  key["type"] = "AmpDimComponent";
                });
                this.AmpDimSchema = ampDimJSON;
                this.featureDataFetched(featureName)
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 12);
  }
  ampDimSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*ampdim[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.ampdimSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.ampdimSoftVer
          )
      );
    }
  }
  ngOnChanges() {}

  // public schemaApiCalls()
  // {
  //   this.callSchemaAPIs();
  // }
  OnSliderClick() {
    this.sliderClicked = true;
    this.apiService.aocSliderClickEvent({ sliderClick: this.sliderClicked });
  }
  getCLOData() {
    // if (
    //   localStorage.getItem("normalFlow") === "true" &&
    //   this.cloLiteSelectedFeature
    // ) {
    //   // this.apiService.sendliteHour("120");
    //   // this.apiService.sendLitePower("100");
    //   //this.apiService.setEnableGraph(false);
    // }
    this.enableGraphSub = this.apiService.checkEnableGraph
      .pipe(takeUntil(this.checkEnableGraphUnSub))
      .subscribe((cloEnabled) => {
        if (!isNullOrUndefined(cloEnabled)) {
          cloEnabled = Boolean(cloEnabled);
          var dataAdd;
          var addNew;
          if (this.cloSelectedFeature === "Clo") {
            addNew = {
              CLO: {
                CloEnabled: {},
              },
            };
          }
          let touchDimObj = {};
          let keyVal = "CLO";
          dataAdd = JSON.parse(localStorage.getItem("configurationData"));
          if (dataAdd && !isNullOrUndefined(dataAdd["resp"])) {
            if (
              dataAdd &&
              !isNullOrUndefined(dataAdd["resp"]) &&
              dataAdd["resp"][keyVal]
            ) {
              dataAdd = dataAdd;
            } else {
              touchDimObj = Object.assign({}, addNew);
              dataAdd["resp"][keyVal] = touchDimObj;
            }
          } else {
            dataAdd["resp"] = addNew;
          }
          this.cloEnabledValue = cloEnabled;
          if (
            typeof this.cloEnabledValue == "boolean" &&
            this.cloEnabledValue == true
          ) {
            this.cloFeatureParametersObj["CloEnabled"] = true;
            this.checkCLOEdited = true;
          } else {
            this.cloFeatureParametersObj["CloEnabled"] = false;
            this.checkCLOEdited = false;
          }
          if (this.cloSelectedFeature === "Clo") {
            dataAdd["resp"]["CLO"]["CloEnabled"] =
              this.cloFeatureParametersObj["CloEnabled"];
            //console.log(dataAdd)
            localStorage.setItem("configurationData", JSON.stringify(dataAdd));
            if (!isNullOrUndefined(this.cloFeatureParametersObj["CloPoint1"])) {
              if (
                !isNullOrUndefined(
                  this.cloSchema && this.cloFeatureParametersObj["CloPoint1"]
                ) &&
                this.cloFeatureParametersObj["CloEnabled"] ===
                  this.cloSchema["properties"]["CloEnabled"]["default"] &&
                this.cloFeatureParametersObj["CloPoint1"]["Time"] ===
                  this.cloSchema["properties"]["CloPoint1"]["properties"][
                    "Time"
                  ]["const"] &&
                this.cloFeatureParametersObj["CloPoint1"]["Percentage"] ===
                  this.cloSchema["properties"]["CloPoint1"]["properties"][
                    "Percentage"
                  ]["default"] &&
                this.cloFeatureParametersObj["CloPoint2"] === undefined
              ) {
                this.cloDefaultColor = true;
              } else {
                this.cloDefaultColor = false;
              }
            } else {
              if (
                (!isNullOrUndefined(
                  this.cloSchema &&
                    dataAdd["resp"]["CLO"] &&
                    dataAdd["resp"]["CLO"]["CloPoint1"]
                ) &&
                  (dataAdd["resp"]["CLO"]["CloEnabled"] !=
                    this.cloSchema["properties"]["CloEnabled"]["default"] ||
                    dataAdd["resp"]["CLO"]["CloPoint1"]["Time"] !=
                      this.cloSchema["properties"]["CloPoint1"]["properties"][
                        "Time"
                      ]["const"] ||
                    dataAdd["resp"]["CLO"]["CloPoint1"]["Percentage"] !=
                      this.cloSchema["properties"]["CloPoint1"]["properties"][
                        "Percentage"
                      ]["default"])) ||
                dataAdd["resp"]["CLO"]["CloPoint2"] !== undefined
              ) {
                this.cloDefaultColor = false;
              } else {
                this.cloDefaultColor = true;
              }
            }
          }
        }
      });
    this.cloGraphSub = this.apiService.getCloGraphData
      .pipe(takeUntil(this.getGraphDataUnSub))
      .subscribe((data) => {
        if (data) {
          var dataAdd;
          var addNew;
          if (this.cloSelectedFeature === "Clo") {
            addNew = {
              CLO: {
                CloEnabled: {},
              },
            };
          }
          let touchDimObj = {};
          let keyVal = "CLO";
          dataAdd = JSON.parse(localStorage.getItem("configurationData"));
          if (dataAdd && !isNullOrUndefined(dataAdd["resp"])) {
            if (
              dataAdd &&
              !isNullOrUndefined(dataAdd["resp"]) &&
              dataAdd["resp"][keyVal]
            ) {
              dataAdd = dataAdd;
            } else {
              touchDimObj = Object.assign({}, addNew);
              dataAdd["resp"][keyVal] = touchDimObj;
            }
          } else {
            if (!isNullOrUndefined(dataAdd)) {
              dataAdd["resp"] = addNew;
            }
          }
          if (data["graphPoints"] != undefined) {
            setTimeout(() => {
              this.cloFeatureParametersObj = {};
              if (
                typeof this.cloEnabledValue == "boolean" &&
                this.cloEnabledValue == true
              ) {
                this.cloFeatureParametersObj["CloEnabled"] = true;
              } else {
                this.cloFeatureParametersObj["CloEnabled"] = false;
              }

              this.graphPointsCollection = "";
              if (data["graphPoints"].length > 16) {
                data["graphPoints"].pop();
              }
              if (
                data["graphPoints"].length == 0 &&
                !isNullOrUndefined(this.cloSchema)
              ) {
                this.cloFeatureParametersObj["CloPoint1"] = {
                  Time: this.cloSchema["properties"]["CloPoint1"]["properties"][
                    "Time"
                  ]["const"],
                  Percentage:
                    this.cloSchema["properties"]["CloPoint1"]["properties"][
                      "Percentage"
                    ]["default"],
                };
              } else {
                // data['graphPoints'].pop();
                data["graphPoints"].forEach((value, index) => {
                  this.graphPointsCollection = {
                    Time: Number(data["graphPoints"][index]["hour"]),
                    Percentage: Number(data["graphPoints"][index]["power"]),
                  };
                  this.cloFeatureParametersObj["CloPoint" + (index + 1)] =
                    this.graphPointsCollection;
                  if (this.cloSelectedFeature === "Clo") {
                    setTimeout(() => {
                      dataAdd["resp"]["CLO"] = this.cloFeatureParametersObj;
                      //console.log(dataAdd)
                      localStorage.setItem(
                        "configurationData",
                        JSON.stringify(dataAdd)
                      );
                    }, 500);
                  }
                });
              }
              // this.cloEnableCreateFile=false;
            }, 0);
          }
        }
      });
    this.apiService.checkGraphValueEdited.subscribe((data) => {
      if (data.length == 1) {
        this.checkCLOEdited = false;
      } else {
        this.checkCLOEdited = true;
      }
    });
    this.checkCloDefaultCheckbox();
  }

  checkCloDefaultCheckbox() {
    this.enableSub = this.apiService.getCloSchema
      .pipe(takeUntil(this.checkCloScehma))
      .subscribe((cloEnabled) => {
        if (this.cloSelectedFeature === "Clo") {
          let lineSwitchLoaddedFetaure = JSON.parse(
            localStorage.getItem("configurationData")
          );

          if (
            !isNullOrUndefined(lineSwitchLoaddedFetaure) &&
            !isNullOrUndefined(lineSwitchLoaddedFetaure.resp) &&
            !isNullOrUndefined(lineSwitchLoaddedFetaure.resp.CLO)
          ) {
            if (lineSwitchLoaddedFetaure.resp.CLO.CloEnabled === true) {
              if(this.loadingComponetsRen === false) 
              this.onCheckboxChnge(
                "clo-",
                lineSwitchLoaddedFetaure.resp.CLO.CloEnabled
              );
            } else if (
              lineSwitchLoaddedFetaure.resp.CLO.CloEnabled === false &&
              !isNullOrUndefined(lineSwitchLoaddedFetaure.resp.CLO.CloPoint1) &&
              !isNullOrUndefined(this.cloSchema)
            ) {
              if (
                lineSwitchLoaddedFetaure.resp.CLO.CloPoint1.Time !=
                  this.cloSchema["properties"]["CloPoint1"]["properties"][
                    "Time"
                  ]["const"] ||
                lineSwitchLoaddedFetaure.resp.CLO.CloPoint1.Percentage !=
                  this.cloSchema["properties"]["CloPoint1"]["properties"][
                    "Percentage"
                  ]["default"]
              ) {
                if(this.loadingComponetsRen === false) this.onCheckboxChnge("clo-", true);
              }
            }
          }
        }
      });
  }

  aloschemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('alo'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.schemaDetailsApi().subscribe(
              (resp: any) => {
                const usersJson: any[] = Array.of(resp);
                usersJson.forEach((key) => {
                  key["key"] = "ALO";
                });
                this.aloSchema = usersJson;
                this.percentage = usersJson[0].properties.AloPercentage.default;
                if (usersJson[0].properties.AloMinLevel) {
                  this.minAloValueSchema =
                    usersJson[0].properties.AloMinLevel.default;
                }
                // this.customLoader = false;
                this.getPercent(this.percentage);
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 0);
  }

  dimmingInterfaceSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('dimminginterface'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.dimmingSchemaDetailsApi().subscribe(
              (resp: any) => {
                const usersJson: any[] = Array.of(resp);
                usersJson.forEach((key) => {
                  key["key"] = "Dimming";
                  key["type"] = "DimmingComponent";
                });
                this.dimmingInterfaceSchema = usersJson;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName);
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 5);
  }

  dimmingSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*dimminginterface[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.dimminginterfaceSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.dimminginterfaceSoftVer
          )
      );
    }
  }

  //MULTI CONFIG
  multiconfigSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('multiconfigmode-'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.multiconfigSchemaDetailsApi().subscribe(
              (resp: any) => {
                const configJSON: any[] = Array.of(resp);
                configJSON.forEach((key) => {
                  key["key"] = "Multiconfig";
                  key["type"] = "MulticonfigComponent";
                });
                this.multiconfigSchema = configJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }

  multiconfigSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*multiconfigmode[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.multiconfigSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.multiconfigSoftVer
          )
      );
      // return of(mockmulticonfig);
    }
  }

  dimmingModelChanges() {}

  multiconfigModelChanges(){}

  getPercent(getAlopercent) {
    this.AloPercentage = getAlopercent;
  }
  schemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*alo[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.softwareVerSchema)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.softwareVerSchema
          )
      );
    }
  }
  aocSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('aoc'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.aocschemaDeatailsApi().subscribe(
              (resp: any) => {
                const aocJson: any[] = Array.of(resp);
                aocJson.forEach((key) => {
                  key["key"] = "AOC";
                  key["type"] = "slider";
                });
                this.schema = aocJson;
                localStorage.setItem("aocSchema", JSON.stringify(this.schema));
                // this.customLoader = false;
                this.aocDefaultValue = aocJson[0].properties.AocCurrent.default;
                this.aocMinValue = aocJson[0].properties.AocCurrent.minimum;
                this.aocMaxValue = aocJson[0].properties.AocCurrent.maximum;
                this.aoctextboxValue = this.aocDefaultValue;
                this.isHidden = aocJson[0].properties.AocEnabled.hidden;
                this.featureDataFetched(featureName)
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 0);
  }
  aocschemaDeatailsApi() {
    let version = this.receivedFeature.aocSoftVer
      ? this.receivedFeature.aocSoftVer
      : this.receivedFeature.softwareVerSchema;
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*aoc[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.aocSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace("{version}", "DeviceVersion=" + version)
      );
    }
  }
  cloSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('clo-'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.closchemaDeatailsApi().subscribe(
              (resp: any) => {
                this.cloSchema = resp;
                if (resp.featureVersion == 2) {
                  this.apiService.setGraphRepresentation("Interpolation");
                }
                if (resp.featureVersion == 1) {
                  this.apiService.setGraphRepresentation("Non-Interpolation");
                }
                this.apiService.setCloShema(true);
                this.featureDataFetched(featureName);
                // this.customLoader = false;
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 0);
  }
  closchemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*clo-[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.cloSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.cloSoftVer
          )
      );
    }
  }

  modelChanges(targetField, model, schema) {
    this.sliderChangedCheck(targetField, model, schema);
  }
  sliderChangedCheck(targetField, model, schema) {
    this.AocCurrentValue = Object.values(targetField)[0];
    schema.forEach((field, index) => {
      if (field["key"] === Object.keys(targetField)[0]) {
        if (
          field.properties.AocCurrent.default !== Object.values(targetField)[0]
        ) {
          this.valueChange = true;
        } else {
          this.valueChange = false;
        }
        this.apiService.isEdited(this.valueChange);
      }
    });
  }
  // >>>>>>> Stashed changes

  startupSlidermodelChanges(targetField, model, schema) {
    this.startupModelChanges(targetField, model, schema);
  }
  startupModelChanges(targetField, model, schema) {
    schema.forEach((field, index) => {
      if (field["key"] === Object.keys(targetField)[0]) {
        if (
          field.properties.AdjustableStartupTime.default !==
          Object.values(targetField)[0]
        ) {
          this.startupValueChange = true;
        } else {
          this.startupValueChange = false;
        }
      }
    });
  }

  // aloSlidermodelChanges(targetField, model, schema) {
  //   setTimeout(() => {
  //     this.alomodelChanges(targetField, model, schema);
  //   }, 500);
  // }

  // alomodelChanges(targetField, model, schema) {
  //   let aloData = JSON.parse(localStorage.getItem("saveSetData"));
  //   schema.forEach((field, index) => {
  //     if (field["key"] === Object.keys(targetField)[0] || this.AloPercentage) {
  //       if (
  //         field.properties.AloMinLevel.default !== Object.values(targetField)[0]
  //       ) {
  //         this.aloValueChange = true;
  //       } else {
  //         this.aloValueChange = false;
  //       }
  //     }
  //   });
  // }
  openModal() {
    this.apiService.sentConfigurationData("featureData");
    const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
    modalRef.componentInstance.featureInfo = this.exportFeature;
  }

  addRemoveFeature() {
    this.router.navigate(["/deviceList"]);
    this.apiService.modalConfigurationData("configData");
    const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
    var device = JSON.parse(localStorage.getItem("configurationData"));
    modalRef.componentInstance.deviceInfo = device;
  }

  openInfo(e) {
    e.stopPropagation();
  }

  openFeaturePopUpInfo(ngbEvent, event) {
    this.ngbEvent = ngbEvent;
    event.stopPropagation();
  }

  psuSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('dalipsu'));
    setTimeout(() => {
      this.apiService.getEnvValue();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            this.url = url;
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.psuschemaDeatailsApi().subscribe(
              (resp: any) => {
                const psuJson: any[] = Array.of(resp);
                psuJson.forEach((key) => {
                  key["key"] = "Power Supply";
                  key["type"] = "powerSupply";
                });
                this.psuSchema = psuJson;
                // this.customLoader = false;
                this.featureDataFetched(featureName)
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 100);
  }

  psuschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*dalipsu[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.dalipsuSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.dalipsuSoftVer
          )
      );
    }
  }

  startupSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('ast'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.startupSchemaDetailsApi().subscribe(
              (resp: any) => {
                const startupJson: any[] = Array.of(resp);
                startupJson.forEach((key) => {
                  key["key"] = "Startup time";
                  key["type"] = "StartuptimeComponent";
                });
                this.startupSchema = startupJson;
                // this.customLoader = false;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
      this.apiService.startupSliderClick.subscribe((response) => {
        var data;
        var addNew = {
          resp: {
            Ast: {},
          },
        };
        if (localStorage.getItem("saveSetData")) {
          data = JSON.parse(localStorage.getItem("saveSetData"));
        } else {
          data = addNew;
        }
        this.startupFeatureParametersObj = [];
        let startupValue = response;
        this.startupFeatureParametersObj.push({
          AdjustableStartupTime: Number(startupValue),
        });
        this.startupSchema.forEach((field, index) => {
          if (
            field["properties"].AdjustableStartupTime.default !==
            Number(startupValue)
          ) {
            this.startupValueChange = true;
          } else {
            this.startupValueChange = false;
          }
          data["resp"]["Ast"] = this.startupFeatureParametersObj[0];
          this.apiService.isStartupEdited(this.startupValueChange);
          localStorage.setItem("saveSetData", JSON.stringify(data));
        });
      });
    }, 0);
  }
  dtlModelChanges(model, schema, value) {}
  flextuneModelChanges(model, schema, value) {}
  owpModelChanges(model, schema, value) {}
  FlextuneSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('flextune-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.FlextuneSchemaDetailsApi().subscribe(
              (resp: any) => {
                const flextuneJSON: any[] = Array.of(resp);
                flextuneJSON.forEach((key) => {
                  key["key"] = "Flextune";
                  key["type"] = "FlextuneComponent";
                });
                this.FlextuneSchema = flextuneJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  DtlSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('dtl-'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.DtlSchemaDetailsApi().subscribe(
              (resp: any) => {
                const dtlJSON: any[] = Array.of(resp);
                dtlJSON.forEach((key) => {
                  key["key"] = "DTL";
                  key["type"] = "DtlComponent";
                });
                this.DtlSchema = dtlJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
          }
        });
    }, 0);
  }
  dcemergencySchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('dcemergency-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.dcemergencySchemaDetailsApi().subscribe(
              (resp: any) => {
                const dcemergencyJSON: any[] = Array.of(resp);
                dcemergencyJSON.forEach((key) => {
                  key["key"] = "DC Emergency";
                  key["type"] = "DcemergencyComponent";
                });
                this.dcemergencySchema = dcemergencyJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }

  touchDimSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('touchdim-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.touchDimSchemaDetailsApi().subscribe(
              (resp: any) => {
                const touchDimJSON: any[] = Array.of(resp);
                touchDimJSON.forEach((key) => {
                  key["key"] = "TouchAndDim";
                  key["type"] = "TouchDimComponent";
                });
                this.touchDimSchema = touchDimJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  diia253maintenanceSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('diia253maintenance-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.diia253maintenanceDetailsApi().subscribe(
              (resp: any) => {
                const diia253maintenanceJSON: any[] = Array.of(resp);
                diia253maintenanceJSON.forEach((key) => {
                  key["key"] = "DaliMComponent";
                  key["type"] = "DaliMComponent";
                });
                this.diia253maintenanceSchema = diia253maintenanceJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  //DYNA
  dynaDimmerSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('dynadimmer-'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.dynaDimmerSchemaDetailsApi().subscribe(
              (resp: any) => {
                const dynaDimmerJSON: any[] = Array.of(resp);
                dynaDimmerJSON.forEach((key) => {
                  key["key"] = "Dyna Dimmer";
                  key["type"] = "dynaDimmer";
                });
                this.dynaDimmerSchema = dynaDimmerJSON;

                if (localStorage.getItem("dynaDimmerSchema")) {
                  localStorage.removeItem("dynaDimmerSchema");
                }

                localStorage.setItem(
                  "dynaDimmerSchema",
                  JSON.stringify(this.dynaDimmerSchema)
                );
                this.isDataLoaded = true;
                if (resp.featureName === "Dynadimmer") {
                  this.setDynaDimmerPerSchema(resp);
                }
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }

  

  setDynaDimmerPerSchema(resp: any) {
    this.apiService.setDynaDimmerInterDependency(false);
    let dynaDimmerDimmingIntefaceDependence = resp.$interSchemaDependency;
    let requiredDynaProperties = resp.requiredProperties;

    if (
      dynaDimmerDimmingIntefaceDependence === "DynadimmerConfigDependency_v1"
    ) {
      this.apiService.setDynaDimmerInterDependency(true);
    }
    this.apiService.showDynaEnabled(false);
    this.apiService.showDynaTimeRefernece(false);
    this.apiService.showDynaOverrideEnabled(false);
    for (let reqProps of requiredDynaProperties) {
      if (reqProps === "DynadimmerEnabled") {
        this.apiService.showDynaEnabled(true);
      } else if (reqProps === "TimeReference") {
        this.apiService.showDynaTimeRefernece(true);
      } else if (reqProps === "OverrideMode") {
        this.apiService.showDynaOverrideEnabled(true);
      }
    }
    if (resp.properties.MidnightShift) {
      let midNightShift = resp.properties.MidnightShift;
      this.apiService.sendDynaMidPointShiftMode(true);

      this.apiService.setDynaMidPointShiftDefaultsSchemaProps([
        "" + midNightShift.minimum,
        "" + midNightShift.maximum,
        "" + midNightShift.default,
      ]);
    }

    if (resp.properties.DynadimmerEnabled) {
      let dynaDimmerEn = resp.properties.DynadimmerEnabled;

      if (dynaDimmerEn.const === true || dynaDimmerEn.const === false) {
        this.apiService.sendDynaDimmerEnabledMode(dynaDimmerEn.const);
        this.apiService.setDyanEnabledDefault(dynaDimmerEn.const);
      } else if (
        dynaDimmerEn.default === true ||
        dynaDimmerEn.default === false
      ) {
        this.apiService.sendDynaDimmerEnabledMode(dynaDimmerEn.default);
        this.apiService.setDyanEnabledDefault(dynaDimmerEn.default);
      } else if (
        dynaDimmerEn.default === undefined &&
        dynaDimmerEn.const === undefined
      ) {
        this.apiService.sendDynaDimmerEnabledMode(true);
        this.apiService.setDyanEnabledDefault(true);
      }
    }
    if (resp.properties.TimeReference) {
      let dynaTimeRef = resp.properties.TimeReference;
      if (dynaTimeRef.const === true || dynaTimeRef.const === false) {
        this.apiService.sendDynaTimeReferenceMode(dynaTimeRef.const);
        this.apiService.setDyanTimeRefDefault(dynaTimeRef.const);
      }
      if (dynaTimeRef.default === true || dynaTimeRef.default === false) {
        this.apiService.sendDynaTimeReferenceMode(dynaTimeRef.default);
        this.apiService.setDyanTimeRefDefault(dynaTimeRef.default);
      }
    }
    if (resp.properties.OverrideMode) {
      let overrrideEn = resp.properties.OverrideMode;
      if (overrrideEn.const === true || overrrideEn.const === false) {
        this.apiService.sendDynaOveerideMode(overrrideEn.const);
        this.apiService.setDyanOverrideDefault(overrrideEn.const);
      }
      if (overrrideEn.default === true || overrrideEn.default === false) {
        this.apiService.sendDynaOveerideMode(overrrideEn.default);
        this.apiService.setDyanOverrideDefault(overrrideEn.default);
      }
    }
    // if(resp.properties.NumberOfScenes){
    //   let defaultNumberOfScenes = resp.properties.NumberOfScenes.default;
    // }
    // let sceneDefaults:any[]=[];
    // if(resp.properties.Scene1){
    //   let defaultDynaScene1 = {
    //     "defaultHourValue": resp.properties.Scene1.properties.Hour.default,
    //     "defaultMinuteValue": resp.properties.Scene1.properties.Minute.default,
    //     "defaultDimLevelValue": resp.properties.Scene1.properties.DimLevel.default,
    //     "defaultFadeTimeValue": resp.properties.Scene1.properties.FadeTime.default,

    //   }
    //   sceneDefaults.push(defaultDynaScene1);
    // }
    //   if(resp.properties.Scene2){
    //     let defaultDynaScene2 = {
    //       "defaultHourValue": resp.properties.Scene2.properties.Hour.default,
    //       "defaultMinuteValue": resp.properties.Scene2.properties.Minute.default,
    //       "defaultDimLevelValue": resp.properties.Scene2.properties.DimLevel.default,
    //       "defaultFadeTimeValue": resp.properties.Scene2.properties.FadeTime.default,

    //     }
    //     sceneDefaults.push(defaultDynaScene2);
    //   }
    //     if(resp.properties.Scene3){
    //       let defaultDynaScene3 = {
    //         "defaultHourValue": resp.properties.Scene3.properties.Hour.default,
    //         "defaultMinuteValue": resp.properties.Scene3.properties.Minute.default,
    //         "defaultDimLevelValue": resp.properties.Scene3.properties.DimLevel.default,
    //         "defaultFadeTimeValue": resp.properties.Scene3.properties.FadeTime.default,

    //       }
    //       sceneDefaults.push(defaultDynaScene3);}
    //       if(resp.properties.Scene4){
    //         let defaultDynaScene4 = {
    //           "defaultHourValue": resp.properties.Scene4.properties.Hour.default,
    //           "defaultMinuteValue": resp.properties.Scene4.properties.Minute.default,
    //           "defaultDimLevelValue": resp.properties.Scene4.properties.DimLevel.default,
    //           "defaultFadeTimeValue": resp.properties.Scene4.properties.FadeTime.default,

    //         }
    //         sceneDefaults.push(defaultDynaScene4);
    //       }
    //         if(resp.properties.Scene5){
    //           let defaultDynaScene5 = {
    //             "defaultHourValue": resp.properties.Scene5.properties.Hour.default,
    //             "defaultMinuteValue": resp.properties.Scene5.properties.Minute.default,
    //             "defaultDimLevelValue": resp.properties.Scene5.properties.DimLevel.default,
    //             "defaultFadeTimeValue": resp.properties.Scene5.properties.FadeTime.default,

    //           }
    //           sceneDefaults.push(defaultDynaScene5);
    // }
  }

  dynaDimmerSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*dynadimmer[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.dynadimmerSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.dynadimmerSoftVer
          )
      );
    }
  }

  

  corridorSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*corridor[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.corridorSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.corridorSoftVer
          )
      );
    }
  }
  mtpSchemaDetailsApi() {
    let version = this.receivedFeature.mtpSoftVer
      ? this.receivedFeature.mtpSoftVer
      : this.receivedFeature.softwareVerSchema;
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*mtp[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.mtpSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.mtpSoftVer
          )
      );
    }
  }

  codedmainsSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*codedmains[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.codedmainsSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.codedmainsSoftVer
          )
      );
    }
  }
  owpSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*owp[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.owpSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.owpSoftVer
          )
      );
    }
  }
  lineswitchSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*lineswitch[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.lineswitchSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.lineswitchSoftVer
          )
      );
    }
  }
  mtpSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('mtp-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.mtpSchemaDetailsApi().subscribe(
              (resp: any) => {
                const mtpJSON: any[] = Array.of(resp);
                mtpJSON.forEach((key) => {
                  key["key"] = "Mtp";
                  key["type"] = "MtpComponent";
                });
                this.MtpSchema = mtpJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  corridorSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('corridor-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.corridorSchemaDetailsApi().subscribe(
              (resp: any) => {
                const corridorJSON: any[] = Array.of(resp);
                corridorJSON.forEach((key) => {
                  key["key"] = "Corridor";
                  key["type"] = "CorridorComponent";
                });
                this.CorridorSchema = corridorJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 1300);
  }
  lineswitchSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('lineswitch-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.lineswitchSchemaDetailsApi().subscribe(
              (resp: any) => {
                const lineswitchJSON: any[] = Array.of(resp);
                lineswitchJSON.forEach((key) => {
                  key["key"] = "LineSwitch";
                  key["type"] = "LineSwitchComponent";
                });
                this.LineSwitchSchema = lineswitchJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 100);
  }
  codedmainsListSchemaDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('codedmains-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.codedmainsSchemaDetailsApi().subscribe(
              (resp: any) => {
                const codedmainsJSON: any[] = Array.of(resp);
                codedmainsJSON.forEach((key) => {
                  key["key"] = "Codedmains";
                  key["type"] = "CodedmainsComponent";
                });
                this.codedmainsSchema = codedmainsJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  lineswitchModelChanges() {}
  codedmainsModelChanges() {}
  corrdorModelChanges() {}
  mtpModelChanges() {}
  ampDimModelChanges() {}
  dcemergencyModelChanges() {}

  touchDimModelChanges() {}
  diia253maintenanceModelChanges() {}
  dynaDimModelChanges() {}

  startupSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*ast[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.astSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.astSoftVer
          )
      );
    }
  }
  FlextuneSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*flextune[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.flextuneSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.flextuneSoftVer
          )
      );
    }
  }
  DtlSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*dtl[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.dtlSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.dtlSoftVer
          )
      );
    }
  }
  /* MinDim Level Start*/

  minDimLevelSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*mindimlevel[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.mindimlevelSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.mindimlevelSoftVer
          )
      );
    }
  }

  minDimLevelSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('mindimlevel-'));
    setTimeout(() => {
      this.apiService.getEnvValue();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.minDimLevelSchemaDetailsApi().subscribe(
              (resp: any) => {
                const minDimLevelJSON: any[] = Array.of(resp);
                minDimLevelJSON.forEach((key) => {
                  key["key"] = "minDimLevel";
                  key["type"] = "MinDimLevelComponent";
                });
                this.minDimLevelSchema = minDimLevelJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName);
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 16);
  }

  minDimLevelModelChanges() {}

  /*Min Dim level End */

  dcemergencySchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*dcemergency[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.dcemergencySoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.dcemergencySoftVer
          )
      );
    }
  }
  owpSchemaListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('owp-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.owpSchemaDetailsApi().subscribe(
              (resp: any) => {
                const owpJSON: any[] = Array.of(resp);
                owpJSON.forEach((key) => {
                  key["key"] = "Owp";
                  key["type"] = "OwpComponent";
                });
                this.owpSchema = owpJSON;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName);
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 1300);
  }
  diia253maintenanceDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*diia253maintenance[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.diia253maintenanceSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.diia253maintenanceSoftVer
          )
      );
    }
  }
  touchDimSchemaDetailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*touchdim[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.touchdimSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.touchdimSoftVer
          )
      );
    }
  }

  psuModelChanges(targetField, model, schema) {}
  //Lumin Infor Stnard configuration///
  lumInfoListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('luminfoformat'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.lumInfoschemaDeatailsApi().subscribe(
              (resp: any) => {
                const lmuJson: any[] = Array.of(resp);
                lmuJson.forEach((key) => {
                  key["key"] = "Luminaire information";
                  key["type"] = "LumInfoComponent";
                });
                this.lmuSchema = lmuJson;
                // this.customLoader = false;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  lumInfoschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*luminfo[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.luminSoftVer)) {
      this.loadingComponetsRen = true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.luminSoftVer
          )
      );
    }
  }
  lumInfoModelChanges(targetField, model, schema) {}

  CloLIteListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('clolite'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.cloLiteschemaDeatailsApi().subscribe(
              (resp: any) => {
                this.cloLiteSchema = resp;
                if (resp.featureVersion == 2) {
                  this.apiService.setGraphRepresentation("Interpolation");
                }
                if (resp.featureVersion == 1) {
                  this.apiService.setGraphRepresentation("Interpolation");
                }
                // this.customLoader = false;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  cloLiteschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*clolite[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.cloliteSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.cloliteSoftVer
          )
      );
    }
  }
  ztvoutputListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('ztvoutput-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.ztvoutputschemaDeatailsApi().subscribe(
              (resp: any) => {
                const ztvoutputJson: any[] = Array.of(resp);
                ztvoutputJson.forEach((key) => {
                  key["key"] = "ztvoutput";
                  key["type"] = "ZtvoutputComponent";
                });
                this.ztvoutputSchema = ztvoutputJson;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  lipListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('lip-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.lipschemaDeatailsApi().subscribe(
              (resp: any) => {
                this.lipSchema = resp;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  rsoListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('rso-'));
    setTimeout(() => {
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            // this.url = url;
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.rsoschemaDeatailsApi().subscribe(
              (resp: any) => {
                this.rsoSchema = resp;
                this.isPageReady = true;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                this.isPageReady = true;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  ztvoutputschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*ztvoutput-[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.ztvoutputSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.ztvoutputSoftVer
          )
      );
    }
  }
  lipschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*lip-[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.lipSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.lipSoftVer
          )
      );
    }
  }
  rsoschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*rso-[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.rsoSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.rsoSoftVer
          )
      );
    }
  }
  //ZtV start from here
  ztvListDetails() {
    let featureName = this.deviceFeatures.find((item) => item.includes('ztv'));
    setTimeout(() => {
      // this.customLoader = true;
      this.apiService.getEnvValue_FP();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (this.url.length) {
            this.unsubscribe.next();
            this.envAloschemaDetails = this.url + this.ioTschemaDetails;
            this.ztvschemaDeatailsApi().subscribe(
              (resp: any) => {
                const ztvJson: any[] = Array.of(resp);
                ztvJson.forEach((key) => {
                  key["key"] = "Ztv";
                  key["type"] = "ZtvComponent";
                });
                this.ztvSchema = ztvJson;
                // this.customLoader = false;
                if (
                  ztvJson[0]["$interSchemaDependency"] ==
                  "ZtvConfigDependency_v1" //MOWEB 1.1
                ) {
                  this.RemoveItem("ztv-");
                  this.isZtvDependDimming = true;
                } else {
                  this.isZTVFeatureAvailable = true;
                }
                this.isPageReady = true;
                this.featureDataFetched(featureName);
              },
              (err) => {
                this.featureDataFetched(featureName)
                this.customLoader = false;
                this.loading = false;
                this.isPageReady = true;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
          }
        });
    }, 0);
  }
  ztvschemaDeatailsApi() {
    this.receivedFeature.feature12Nc.filter((item) => {
      if (item.match(/[^,]*ztv[^,]*/g)) {
        this.Feature12nc = item;
      }
    });
    if (!isNullOrUndefined(this.receivedFeature.ztvSoftVer)) {
      this.loadingComponetsRen=true;
      return this.apiService.apiCall(
        "get",
        this.envAloschemaDetails
          .replace(
            "{nc12}",
            "Feature12nc=" + this.Feature12nc + this.receivedFeature.device12nc
          )
          .replace("/v/", "&")
          .replace(
            "{version}",
            "DeviceVersion=" + this.receivedFeature.ztvSoftVer
          )
      );
    }
  }
  ztvoutputModelChanges(targetField, model, schema) {}
  rsoModelChanges(targetField, model, schema) {}
  ztvModelChanges(targetField, model, schema) {}
  //ZTV end

  // Standard configuration //

  changeConfig() {
    if (this.configurationText === "Standard configuration") {
      this.allFeature = [];
      this.configurationText = "Guided configuration";
      this.headerText = "Standard configuration";
      this.quickConfig = true;
      if (this.aocSelected == false) {
        this.apiService.sendAOCValidData("");
      }
      if (this.startupSelected == false) {
        this.apiService.sendStartupValidData("valid");
      }
      this.apiService.checkGraphIsValid.subscribe((data) => {
        if (data == "invalid" && this.cloSelected == true) {
          this.isClo = true;
        } else {
          this.isClo = false;
        }

        if (data == "invalid" && this.cloLiteSelected == true) {
          this.isCloLite = true;
        } else {
          this.isCloLite = false;
        }
      });
      this.apiService.sendQuickConfig("quickConfig");
      localStorage.setItem("quickConfiguration", JSON.stringify("quickConfig"));
      // this.countQuickFeature = 0;
      this.receivedFeature.selectedFeaturesInfo = [];
      let selectedFeature = JSON.parse(
        localStorage.getItem("configurationData")
      );
      // selectedFeature.feature12Nc.forEach(element => {
      //   element = element.slice(0, -1);
      //   selectedFeature.feature12Nc = element.charAt(0).toUpperCase() + element.slice(1);
      //   if(selectedFeature.feature12Nc === 'Luminfoformat3' || selectedFeature.feature12Nc === 'Luminfoformat1') {
      //     selectedFeature.feature12Nc = 'LumInfo';
      //   }
      //   if(selectedFeature.feature12Nc === "Ast"){
      //     selectedFeature.feature12Nc = "Startup time";
      //   }
      //   if(selectedFeature.feature12Nc === "Ztv"){
      //     selectedFeature.feature12Nc = "0-10v / 1-10v";
      //   }
      //   if(selectedFeature.feature12Nc === "Dcemergency"){
      //     selectedFeature.feature12Nc = "DC Emergency";
      //   }
      //   if(selectedFeature.feature12Nc === "Mindimlevel"){
      //     selectedFeature.feature12Nc = "Min Dim Level";
      //   }
      if (
        selectedFeature.feature12Nc === "Touchdim" ||
        selectedFeature.feature12Nc === "touchdim-"
      ) {
        selectedFeature.feature12Nc = "Touch and Dim";
      }
      //   this.allFeature.push(selectedFeature.feature12Nc);
      //   this.receivedFeature.selectedFeaturesInfo.push(selectedFeature.feature12Nc);
      //   this.receivedFeature.selectedFeaturesInfo = this.receivedFeature.selectedFeaturesInfo.sort();
      // });
      for (let i = 0; i < this.receivedFeature.feature12Nc.length; i++) {
        if (this.receivedFeature.selectedFeaturesInfo[i] === "Clolite") {
          this.receivedFeature.selectedFeaturesInfo[i] = "CloLite";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "Dtl") {
          this.receivedFeature.selectedFeaturesInfo[i] = "DTL";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "corridor-") {
          this.receivedFeature.selectedFeaturesInfo[i] = "Corridor";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "MinDimLevel") {
          this.receivedFeature.selectedFeaturesInfo[i] = "Min dim level";
        }
        if (
          this.receivedFeature.selectedFeaturesInfo[i] === "luminaireinputpower"
        ) {
          this.receivedFeature.selectedFeaturesInfo[i] = "luminaireinputpower";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "rso") {
          this.receivedFeature.selectedFeaturesInfo[i] = "rso";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "lip") {
          this.receivedFeature.selectedFeaturesInfo[i] = "lip";
        }
        if (this.receivedFeature.selectedFeaturesInfo[i] === "owp") {
          this.receivedFeature.selectedFeaturesInfo[i] = "owp";
        }
        // this.onCheckboxChnge(this.receivedFeature.feature12Nc[i],true);
      }
      this.receivedFeature.selectedFeaturesInfo.forEach((feature) => {
        this.openFeature.push(feature);
      });
    } else {
      this.apiService.checkALOGraph.subscribe((data) => {
        if (data === true) {
          this.aloEnableCreateFile = true;
        } else {
          this.aloEnableCreateFile = false;
        }
      });
      this.receivedFeature.selectedFeaturesInfo = [];
      let selectedFeature = JSON.parse(
        localStorage.getItem("configurationData")
      );
      if (selectedFeature) {
        selectedFeature.selectedFeaturesInfo.forEach((element) => {
          this.receivedFeature.selectedFeaturesInfo.push(element);
          this.receivedFeature.selectedFeaturesInfo =
            this.receivedFeature.selectedFeaturesInfo.sort();
        });
      }
      this.configurationText = "Standard configuration";
      this.headerText = "Guided configuration";
      this.quickConfig = false;
      this.apiService.sendQuickConfig("standard");
      localStorage.setItem("quickConfiguration", JSON.stringify("standard"));
      // this.newFeatureArray = []
      this.receivedFeature.selectedFeaturesInfo.forEach((feature) => {
        this.openFeature.push(feature);
      });
    }

    let booleanValue = true;
    this.receivedFeature.selectedFeaturesInfo.forEach((feature) => {
      if (
        [
          "0-10v / 1-10v",
          "Psu",
          "Alo",
          "Aoc",
          "Clo",
          "CloLite",
          "Clolite",
          "Startup time",
          "Lumin",
          "LumInfo",
          "Dtl",
          "DTL",
          "Min Dim Level",
          "Min dim level",
          "Corridor",
          "DC Emergency",
          "Touch and Dim",
          "ztv-",
          "alo-",
          "aoc-",
          "touchdim",
          "touchdim-",
          "corridor-",
          "corridor",
          "mtp-",
          "ampdim-",
          "lineswitch-",
          "diia253maintenance-",
          "owp-",
          "dimminginterface-",
          "codedmains-",
          "luminaireinputpower-",
          "rso-",
          "lip-",
        ].includes(feature)
      ) {
        this.allFeatures = true;
        booleanValue = false;
      }
    });
    if (booleanValue) {
      this.allFeatures = false;
    }

    this.configOmitOnRefresh();
    //this.validateIncludeInConfig();
  }

  countAllFeatures() {
    if (this.count === this.selectedFEaturesLength) {
      var newConfigurationID = JSON.parse(
        localStorage.getItem("configurationData")
      );
      var readerConfigurationData1 = JSON.parse(
        localStorage.getItem("readerConfigurationData")
      );

      if (this.restartConfiguration) {
        this.startConfiguration();
        this.restartConfiguration = false;
      } else {
        this.startConfiguration(newConfigurationID.configurationID);
      }
    }
  }
  isObjectNotEmpty(featureObjects: {}) {
    try {
      if (JSON.stringify(featureObjects) === "{}") {
        this.selectedFEaturesLength = this.selectedFEaturesLength - 1;
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  countAllFeaturesUnique(uniqueID?) {
    if (this.count === this.selectedFEaturesLength) {
      this.startConfiguration(uniqueID);
    }
  }
  // Creating a Feature File
  generateFile() {
    this.updateConfigurationDataByFeatureSelected();
    if (
      this.saveFeatureFileClicked === false &&
      this.configureDeviceClicked === false
    ) {
      this.saveFeatureFileClicked = true;

      this.isDisabledButton = true;
      this.isOpenModal = false;
      this.isError = false;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let token = currentUser["token"];
      let configurationID = this.receivedFeature.configurationID;
      let headers = new HttpHeaders({
        Authorization: "Bearer " + token,
      });
      let settingObj = [];
      this.count = 0;
      var newConfigurationID = JSON.parse(
        localStorage.getItem("configurationData")
      );

      this.count = 0;
      this.selectedFEaturesLength =
        newConfigurationID["selectedFeaturesInfo"].length;
      let avaiableZTVVerified = false;
      newConfigurationID["selectedFeaturesInfo"].forEach((element) => {
        //|| element === 'dynadimmer-' || element === 'luminfoformat1-' || element === 'luminfoformat0-' || element === 'dcemergency-'
        if (
          //element === "owp-" ||
          element === "luminfoformat1-"
          //||
          //element === "corridor-" ||
          //element === "touchdim-" ||
          //element === "ztv-" //MOWEB 1.1
        ) {
          this.selectedFEaturesLength = this.selectedFEaturesLength - 1;

          if (this.isZtvDependDimming) {
            const index: number =
              newConfigurationID["selectedFeaturesInfo"].indexOf("ztv-");
            if (index !== -1) {
              newConfigurationID["selectedFeaturesInfo"].splice(index, 1);
            }
          } else if (
            this.isZTVFeatureAvailable &&
            !avaiableZTVVerified &&
            element === "ztv-"
          ) {
            avaiableZTVVerified = true;
            this.selectedFEaturesLength = this.selectedFEaturesLength + 1;
          }
        }
      });

      this.apiService.getEnvValue();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            this.loading = true;
            this.envSetConfigUrl = url + this.setConfigUrl;
            this.unsubscribe.next();
            newConfigurationID.selectedFeaturesInfo.forEach((element) => {
              if (element === "alo-") {
                let isaloMinLevelTrue = false;
                if (this.aloSelected && this.isAloQuickDisable) {
                  this.aloSchema[0]["requiredProperties"].forEach((element) => {
                    if (element === "AloMinLevel") {
                      isaloMinLevelTrue = true;
                    }
                  });
                  if (isaloMinLevelTrue) {
                    this.aloFeature = {
                      featureName: "ALO",
                      featureParameters: [
                        {
                          AloEnabled: this.aloData["AloEnabledVal"],
                          AloPercentage: this.aloData["AloPercentageVal"],
                          AloMinLevel: this.aloData["AloMinLevelVal"],
                        },
                      ],
                    };
                  } else {
                    this.aloFeature = {
                      featureName: "ALO",
                      featureParameters: [
                        {
                          AloEnabled: this.aloData["AloEnabledVal"],
                          AloPercentage: this.aloData["AloPercentageVal"],
                        },
                      ],
                    };
                  }
                }
                if (this.isObjectNotEmpty(this.aloFeature)) {
                  this.count++;
                  settingObj.push(this.aloFeature);
                }
              }

              if (element === "clo-") {
                if (this.cloSelected && this.isCloQuickDisable) {
                  this.validateCLoPoints();
                  this.cloFeature = {
                    featureName: "CLO",
                    featureParameters: [this.cloFeatureParametersObj],
                  };
                }
                if (this.isObjectNotEmpty(this.cloFeature)) {
                  this.count++;
                  settingObj.push(this.cloFeature);
                }
              }
              if (element === "dynadimmer-") {
                var dynaConfig = JSON.parse(
                  localStorage.getItem("dynaSaveSetData")
                );
               
                if (this.dynaDimmerSelected && this.isdynaDimmerQuickDisable) {
                  this.dynaFeature = {
                    featureName: "Dynadimmer",
                    featureParameters: dynaConfig,
                  };
                }
                if (this.isObjectNotEmpty(this.dynaFeature)) {
                  this.count++;
                  settingObj.push(this.dynaFeature);
                }
              }
              if (element === "aoc-") {
                if (this.aocSelected && this.isAocQuickDisable) {
                  if (
                    this.schema[0]["properties"].AocEnabled.aocEnabledLabel ===
                    2
                  ) {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [
                        {
                          AocCurrent: this.aocObj["AocCurrent"],
                          // AocEnabled:
                          //   this.schema[0]["properties"].AocEnabled.const,
                        },
                      ],
                    };
                  } else if (
                    this.schema[0]["properties"].AocEnabled.aocEnabledLabel ===
                    1
                  ) {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [
                        {
                          AocCurrent: this.aocObj["AocCurrent"],
                          AocEnabled: this.aocObj["AocEnabled"],
                        },
                      ],
                    };
                  } else {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [this.aocObj],
                    };
                  }
                }
                if (this.isObjectNotEmpty(this.aocFeature)) {
                  this.count++;
                  settingObj.push(this.aocFeature);
                }
              }
              if (element === "ast-") {
                if (this.startupSelected && this.isStartupQuickDisable) {
                  this.startupFeature["featureName"] = "AST";
                  this.startupFeature["featureParameters"] =
                    this.startupFeatureParametersObj;
                }
                if (this.isObjectNotEmpty(this.startupFeature)) {
                  this.count++;
                  settingObj.push(this.startupFeature);
                }
              }
              if (element === "ztv-") {
                if (this.isZtvQuickDisable && this.ztvSelectedFeature) {
                  if (this.ztvSchema[0]["featureVersion"] === 5) {
                    const data = JSON.parse(
                      localStorage.getItem("saveSetData")
                    );
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "ZtvDimToOffEnabled"
                      )
                    ) {
                      const ZtvDimToOffEnabled =
                        data.resp.Ztv.ZtvDimToOffEnabled;
                      this.ztvValues["ZtvDimToOffEnabled"] = ZtvDimToOffEnabled;
                    }
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "FadeOnTime"
                      )
                    ) {
                      if (data.resp.Ztv.FadeOnTime === 0) {
                        const FadeOnTime = 0;
                        this.ztvValues["FadeOnTime"] = FadeOnTime;
                      } else if (data.resp.Ztv.FadeOnTime !== 0) {
                        const FadeOnTime = data.resp.Ztv.FadeOnTime;
                        this.ztvValues["FadeOnTime"] = FadeOnTime;
                      }
                    }
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "FadeOffTime"
                      )
                    ) {
                      if (data.resp.Ztv.FadeOffTime === 0) {
                        const FadeOffTime = 0;
                        this.ztvValues["FadeOffTime"] = FadeOffTime;
                      } else if (data.resp.Ztv.FadeOffTime !== 0) {
                        const FadeOffTime = data.resp.Ztv.FadeOffTime;
                        this.ztvValues["FadeOffTime"] = FadeOffTime;
                      }
                    }
                    // this.ztvValues['ZtvDimToOffEnabled'] = this.ztvSchema[0]['properties'].ZtvDimToOffEnabled.const
                    // this.ztvValues['FadeOnTime'] = this.ztvSchema[0]['properties'].FadeOnTime.const
                    // this.ztvValues['FadeOffTime'] = this.ztvSchema[0]['properties'].FadeOffTime.const
                  }
                  this.ztvFeature = {
                    featureName: "ZTV",
                    featureParameters: [this.ztvValues],
                  };
                }
                if (this.isObjectNotEmpty(this.ztvFeature)) {
                  this.count++;
                  settingObj.push(this.ztvFeature);
                }
              }

              if (element === "Psu" || element === "srpsu-") {
                if (this.psuSelected && this.isPsuQuickDisable) {
                  if (
                    this.countPSUEnable > 0 ||
                    this.countPSUHeart > 0 ||
                    this.countPSULast > 0
                  ) {
                    this.countPSU = 1;
                  } else {
                    this.countPSU = 0;
                  }
                  this.psuFeature = {
                    featureName: "DALIPSU",
                    featureParameters: [this.psuFeatureParamtersObj],
                  };
                }
                if (this.isObjectNotEmpty(this.psuFeature)) {
                  this.count++;
                  settingObj.push(this.psuFeature);
                }
              }
              if (element === "luminfoformat3-") {
                let cctBoolValue;
                if (localStorage.getItem("CCTBoolen")) {
                  cctBoolValue = JSON.parse(localStorage.getItem("CCTBoolen"));
                }
                if (this.luminSelected && !this.isLuminQuickDisable) {
                  if (element === "luminfoformat3-") {
                    for (let i in this.newLuminObj) {
                      if (
                        (i === "DistributionType" ||
                          i === "LuminaireManufactureYear" ||
                          i === "LuminaireManufactureWeek" ||
                          i === "CRI") &&
                        (this.newLuminObj[i] === "" ||
                          this.newLuminObj[i] === null)
                      ) {
                        this.newLuminObj[i] = 255;
                      } else if (i === "CCT" && cctBoolValue) {
                        this.newLuminObj[i] = 65534;
                      } else if (
                        (i === "PowerMinDim" ||
                          i === "NominalInputPower" ||
                          i === "NominalLightOutput" ||
                          i === "MinAcMainsVoltage" ||
                          i === "MaxAcMainsVoltage" ||
                          i === "CCT") &&
                        (this.newLuminObj[i] === "" ||
                          this.newLuminObj[i] === null)
                      ) {
                        if (i === "NominalLightOutput") {
                          this.newLuminObj[i] = 16777215;
                        } else {
                          this.newLuminObj[i] = 65535;
                        }
                      } else if (
                        i === "UseDynamicDateIndicator" &&
                        this.newLuminObj[i]
                      ) {
                        this.newLuminObj["LuminaireManufactureWeek"] = 255;
                        this.newLuminObj["LuminaireManufactureYear"] = 255;
                      } else if (
                        (i === "OemIdentification" &&
                          this.newLuminObj[i] === "") ||
                        this.newLuminObj[i] === null
                      ) {
                        this.newLuminObj[i] = "18446744073709551615";
                      } else if (
                        (i === "OemGtin" && this.newLuminObj[i] === "") ||
                        this.newLuminObj[i] === null
                      ) {
                        this.newLuminObj[i] = "281474976710655";
                      }
                    }
                  }
                  for (let i in this.newLuminObj) {
                    if (
                      this.lmuSchema[0]["properties"][i]["type"] == "integer"
                    ) {
                      this.newLuminObj[i] = Number(this.newLuminObj[i]);
                    } else if (
                      i === "LuminaireManufactureDate" &&
                      this.newLuminObj[i] === ""
                    ) {
                      this.newLuminObj[i] = "1234";
                    }
                  }
                  for (let element in this.lmuSchema[0]["properties"]) {
                    if (element === "ContentIdentifier") {
                      this.newLuminObj["ContentIdentifier"] = this.lmuSchema[0][
                        "properties"
                      ]["ContentIdentifier"].const
                        ? this.lmuSchema[0]["properties"]["ContentIdentifier"]
                            .const
                        : this.lmuSchema[0]["properties"]["ContentIdentifier"]
                            .default;
                    }
                  }
                  this.luminFeature = {
                    featureName: "LumInfoFormat",
                    featureParameters: [this.newLuminObj],
                  };

                  if (this.isObjectNotEmpty(this.luminFeature)) {
                    this.count++;
                    settingObj.push(this.luminFeature);
                  }
                }
              }
              if (element === "luminfoformat0-") {
                for (let i in this.newLuminObj) {
                  if (
                    (i === "OemGtin" && this.newLuminObj[i] === "") ||
                    this.newLuminObj[i] === null
                  ) {
                    this.newLuminObj[i] = "281474976710655";
                  } else if (
                    (i === "OemIdentification" && this.newLuminObj[i] === "") ||
                    this.newLuminObj[i] === null
                  ) {
                    this.newLuminObj[i] = "18446744073709551615";
                  }
                }
                this.luminFeature = {
                  featureName: "LumInfoFormat",
                  featureParameters: [this.newLuminObj],
                };

                if (this.isObjectNotEmpty(this.luminFeature)) {
                  this.count++;
                  settingObj.push(this.luminFeature);
                }
              }
              if (
                element === "CloLite" ||
                element === "Clolite" ||
                element === "clolite-"
              ) {
                if (this.cloLiteSelected && this.isCloQuickDisable) {
                  this.cloFeature = {
                    featureName: "CLOLite",
                    featureParameters: [
                      {
                        CloLiteEnabled:
                          this.cloLiteFeatureObj["CloLiteEnabled"],
                        CloLiteInitialPowerLevel:
                          this.cloLiteFeatureObj["CloLiteInitialPowerLevel"],
                        CloLiteMaximumWorkingHours:
                          this.cloLiteFeatureObj["CloLiteMaximumWorkingHours"],
                      },
                    ],
                  };
                }
                if (this.isObjectNotEmpty(this.cloFeature)) {
                  this.count++;
                  settingObj.push(this.cloFeature);
                }
              }

              // dtl start here
              if (
                this.isDtlQuickDisable &&
                this.dtlSelectedFeature &&
                (element === "dtl-" || element === "DTL")
              ) {
                if (this.dtlFeatureObj["DtlLampOffDisabled"]) {
                  this.DtlLapmOffValue = 200;
                } else {
                  if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
                    this.DtlLapmOffValue = parseInt(
                      this.dtlFeatureObj["DtlLampOffVal"]
                    );
                  }
                }
                this.dtlFeature = {
                  featureName: "DTL",
                  featureParameters: [
                    {
                      DtlEnabled: this.dtlFeatureObj["DtlEnabled"],
                      DtlOutputPercentage: parseInt(
                        this.dtlFeatureObj["DtlOutputPercentageVal"]
                      ),
                      // DtlDimStart: parseInt(
                      //   this.dtlFeatureObj["DtlDimStartVal"]
                      // ),
                      // DtlLampOff: parseInt(this.DtlLapmOffValue),
                      // DtlDimStop: parseInt(this.dtlFeatureObj["DtlDimStopVal"]),
                    },
                  ],
                };

                if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStartVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlDimStart"] =
                    parseInt(this.dtlFeatureObj["DtlDimStartVal"]);
                }
                if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlLampOff"] =
                    parseInt(this.DtlLapmOffValue);
                }
                if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStopVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlDimStop"] =
                    parseInt(this.dtlFeatureObj["DtlDimStopVal"]);
                }

                if (this.isObjectNotEmpty(this.dtlFeature)) {
                  this.count++;
                  settingObj.push(this.dtlFeature);
                }
              }
              // Min DIm Level start here
              if (
                this.isMinDimLevelQuickDisable &&
                this.minDimLevelSelectedFeature &&
                (element === "Min Dim Level" ||
                  element === "mindimlevel-" ||
                  element === "Min dim level")
              ) {
                this.minDimFeature = {
                  featureName: "MinDimLevel",
                  featureParameters: [
                    {
                      Enabled: this.minDimLevelFeatureObj["Enabled"],
                      MinDimLevel: this.minDimLevelFeatureObj["MinDimLevelVal"],
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.minDimFeature)) {
                  this.count++;
                  settingObj.push(this.minDimFeature);
                }
              }
              //Min DIm Level End Here

              // corridor start here
              if (
                this.corridorSelected &&
                this.isCorridorQuickDisable &&
                element === "corridor-"
              ) {
                if (this.corridorFeatureObj["infiniteDisabled"]) {
                  this.ProlongTimeValue = 2550;
                } else {
                  this.ProlongTimeValue =
                    this.corridorFeatureObj["ProlongTimeValue"];
                }

                this.corridorFeature = {
                  featureName: "Corridor",
                  featureParameters: [
                    {
                      NormalLevel: this.corridorFeatureObj["NormalLevelValue"],
                      BackgroundLevel:
                        this.corridorFeatureObj["BackgroundLevelValue"],
                      DelayTime: this.corridorFeatureObj["DelayTimeValue"],
                      ActivationTime:
                        this.corridorFeatureObj["ActivationTimeValue"],
                      ProlongTime: this.ProlongTimeValue,
                      FadeTimeIndex:
                        this.corridorFeatureObj["FadeTimeIndexValue"],
                      CorridorEnabled: !isNullOrUndefined(
                        this.corridorFeatureObj["CorridorEnabled"]
                      )
                        ? this.corridorFeatureObj["CorridorEnabled"]
                        : false,
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.corridorFeature)) {
                  this.count++;
                  settingObj.push(this.corridorFeature);
                }
              }
              // corridor end here
              //amp dim start here
              if (
                this.ampDimSelected &&
                this.isAmpDimQuickDisable &&
                element === "ampdim-"
              ) {
                this.ampDimFeature = {
                  featureName: "AmpDim",
                  featureParameters: [
                    {
                      VmainsStart: this.ampDimFeatureObj["VmainsStartValue"],
                      VmainsStop: this.ampDimFeatureObj["VmainsStopValue"],
                      StartDimLevel:
                        this.ampDimFeatureObj["StartDimLevelValue"],
                      StopDimLevel: this.ampDimFeatureObj["StopDimLevelValue"],
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.ampDimFeature)) {
                  this.count++;
                  settingObj.push(this.ampDimFeature);
                }
              }
              //amp dim end here
              //codedmains starts here
              if (
                this.codedmainsSelected &&
                this.iscodedmainsDisable &&
                element === "codedmains-"
              ) {
                let fallbackSceneVal;
                if (this.codedmainsFeatureObj["ischeckboxChecked"]) {
                  fallbackSceneVal = 255;
                } else {
                  fallbackSceneVal = this.codedmainsFeatureObj["FallbackScene"];
                }
                this.codedmainsFeature = {
                  featureName: "CodedMains",
                  featureParameters: [
                    {
                      FadeTime: this.codedmainsFeatureObj["FadeTime"],
                      FastFadeTime: this.codedmainsFeatureObj["FastFadeTime"],
                      FallbackDetectionTime:
                        this.codedmainsFeatureObj["FallbackDetectionTime"],
                      PowerOnLevel: this.codedmainsFeatureObj["PowerOnLevel"],
                      FallbackScene: fallbackSceneVal,
                      Scene1: this.codedmainsFeatureObj["Scene1"],
                      Scene2: this.codedmainsFeatureObj["Scene2"],
                      Scene3: this.codedmainsFeatureObj["Scene3"],
                      Scene4: this.codedmainsFeatureObj["Scene4"],
                      Scene5: this.codedmainsFeatureObj["Scene5"],
                      Scene6: this.codedmainsFeatureObj["Scene6"],
                      Scene7: this.codedmainsFeatureObj["Scene7"],
                      Scene8: this.codedmainsFeatureObj["Scene8"],
                      Scene9: this.codedmainsFeatureObj["Scene9"],
                      Scene10: this.codedmainsFeatureObj["Scene10"],
                      Scene11: this.codedmainsFeatureObj["Scene11"],
                      Scene12: this.codedmainsFeatureObj["Scene12"],
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.codedmainsFeature)) {
                  this.count++;
                  settingObj.push(this.codedmainsFeature);
                }
              }
              //codedmains ends here
              //mtp start here
              if (
                this.mtpSelected &&
                this.isMtpQuickDisable &&
                element === "mtp-"
              ) {
                this.mtpFeature = {
                  featureName: "MTP",
                  featureParameters: [
                    {
                      DimLevel: this.mtpFeatureObj["DimLevelValue"],
                      DimStart: this.mtpFeatureObj["DimStartValue"],
                      DimStop: this.mtpFeatureObj["DimStopValue"],
                      Enabled: this.mtpFeatureObj["Enabled"],
                      NtcBeta: this.mtpFeatureObj["NtcBeta"],
                      NtcResistance: this.mtpFeatureObj["NtcResistance"],
                      SelectedNtc: this.mtpFeatureObj["SelectedNtc"],
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.mtpFeature)) {
                  this.count++;
                  settingObj.push(this.mtpFeature);
                }
              }
              //mtp end here
              // line-switch start here
              if (
                this.lineSwitchSelected &&
                this.isLineSwitchDisable &&
                element === "lineswitch-"
              ) {
                if (this.LineSwitchSchema[0].featureVersion === 4) {
                  this.lineswitchFeature = {
                    featureName: "LineSwitch",
                    featureParameters: [
                      {
                        HighVLevel:
                          this.lineSwitchFeatureObj["HighVLevelValue"],
                        LowVLevel:
                          this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                        FadeUpTime:
                          this.lineSwitchFeatureObj[
                            "lineswitchFadeUpTimeValue"
                          ],
                        FadeDownTime:
                          this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                        SwitchOffDelayTime:
                          this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
                        "2ndInactiveLevelEnabled":
                          this.lineSwitchFeatureObj["2ndInactiveLevelEnabled"],
                        "2ndInactiveDimLevel":
                          this.lineSwitchFeatureObj[
                            "lineswitch2ndInactiveDimLevelValue"
                          ],
                        "2ndInactiveLevelDelayTime":
                          this.lineSwitchFeatureObj[
                            "lineswitch2ndInactiveLevelDelayTimeValue"
                          ],
                      },
                    ],
                  };
                } else {
                  this.lineswitchFeature = {
                    featureName: "LineSwitch",
                    featureParameters: [
                      {
                        HighVLevel:
                          this.lineSwitchFeatureObj["HighVLevelValue"],
                        LineSwitchEnabled:
                          this.lineSwitchFeatureObj["isLineSwitchEnabled"],
                        LowVLevel:
                          this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                        FadeUpTime:
                          this.lineSwitchFeatureObj[
                            "lineswitchFadeUpTimeValue"
                          ],
                        FadeDownTime:
                          this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                        SwitchOffDelayTime:
                          this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
                      },
                    ],
                  };
                }
                if (this.isObjectNotEmpty(this.lineswitchFeature)) {
                  this.count++;
                  settingObj.push(this.lineswitchFeature);
                }
              }
              // line-switch end here
              //DC Emergency start here
              if (
                this.dcemergencySelected &&
                this.isDcemergencyQuickDisable &&
                element === "dcemergency-"
              ) {
                if (this.dcemergencyFeatureParametersObj !== undefined) {
                  this.dcemergencyFeatureParametersObj["LevelInArcPower"] =
                    true;

                  this.dcemergencyFeature = {
                    featureName: "DCEmergency",
                    featureParameters: [
                      {
                        DcEmergencyLevel: parseInt(
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyLevel"
                          ]
                        ),
                        DcEmergencyEnabled:
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyEnabled"
                          ],
                        DcEmergencyDimmingEnabled:
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyDimmingEnabled"
                          ],
                        LevelInArcPower:
                          this.dcemergencyFeatureParametersObj[
                            "LevelInArcPower"
                          ],
                      },
                    ],
                  };
                }
                if (this.isObjectNotEmpty(this.dcemergencyFeature)) {
                  this.count++;
                  settingObj.push(this.dcemergencyFeature);
                }
              }
              //DC Emergency End here
              //TouchDim start here
              if (
                this.touchDimSelected &&
                this.isTouchDimQuickDisable &&
                element === "touchdim-"
              ) {
                this.touchDimFeature = {
                  featureName: "TouchDim",
                  featureParameters: [
                    {
                      PowerOnLevel: this.touchDimObj["LastKnownEnabled"]
                        ? 255
                        : parseInt(this.touchDimObj["PowerOnLevel"]),
                      TouchDimEnabled: this.touchDimObj["TouchDimEnabled"],
                      IgnoreToggleLimits:
                        this.touchDimObj["IgnoreToggleLimits"],
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.touchDimFeature)) {
                  this.count++;
                  settingObj.push(this.touchDimFeature);
                }
              }
              //TouchDim End here
              //diia253maintenance start here
              if (
                this.diia253maintenanceSelected &&
                this.isdiia253maintenanceQuickDisable &&
                element === "diia253maintenance-"
              ) {
                let RatedLifeValue =
                  this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
                    "" ||
                  this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
                    undefined
                    ? 255
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj["RatedLife"]
                      );
                let ReferenceTemperature =
                  this.diia253maintenanceFeatureParametersObj[
                    "ReferenceTemperature"
                  ] === "" ||
                  this.diia253maintenanceFeatureParametersObj[
                    "ReferenceTemperature"
                  ] === undefined
                    ? 195
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj[
                          "ReferenceTemperature"
                        ]
                      );
                let RatedStarts =
                  this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
                    "" ||
                  this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
                    undefined
                    ? 65535
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj[
                          "RatedStarts"
                        ]
                      );
                this.diia253maintenanceFeature = {
                  featureName: "Diia253Maintenance",
                  featureParameters: [
                    {
                      RatedLife: RatedLifeValue,
                      ReferenceTemperature: ReferenceTemperature,
                      RatedStarts: RatedStarts,
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.diia253maintenanceFeature)) {
                  this.count++;
                  settingObj.push(this.diia253maintenanceFeature);
                }
              }
              //diia253maintenance End here
              //owp start here
              if (this.owpSelected && this.isOwpDisable && element === "owp-") {
                var res = this.owpFeatureParametersObj["OwpEnabled"];
                var result = res.map((item) => {
                  if(item.toString().toUpperCase()==="STEPDIMMING")
                    return "StepDimming"
                  else
                  return item.replace(/\s+/g, "").toUpperCase();
                });
                var exist =
                  this.owpFeatureParametersObj.hasOwnProperty("OwpPassword");
                this.owpFeature = {
                  featureName: "owp",
                  featureParameters: [
                    {
                      OwpPassword: exist
                        ? this.owpFeatureParametersObj["OwpPassword"]
                        : [],
                      OwpEnabled: result,
                    },
                  ],
                };
                if (this.isObjectNotEmpty(this.owpFeature)) {
                  this.count++;
                  settingObj.push(this.owpFeature);
                }
              }
              //owp end here

              //FLEXTUNE start here
              if (this.flextuneSelected && !this.isFlextuneQuickDisable && element === "flextune-") {
                this.count++;
                settingObj.push({
                  featureName: 'FlexTune',
                  featureParameters: [this.flextuneData]
                })
              }
              //FLEXTUNE end here

             //multiconfig start here
             if (this.multiconfigSelected  && element === "multiconfigmode-") {
              this.count++;
              settingObj.push({
                featureName: 'MultiConfigMode',
                featureParameters: [this.multiconfigData]
              });
            }
            //multiconfig end here

              //dimming interface start here
              if (
                this.dimmingInterfaceSelected &&
                element === "dimminginterface-"
              ) {
                this.dimmingInterfaceFeature = {
                  featureName: "DimmingInterface",
                  featureParameters: [this.dimmingInterfaceObj],
                };
                if (this.isObjectNotEmpty(this.dimmingInterfaceSelected)) {
                  this.count++;
                  settingObj.push(this.dimmingInterfaceFeature);
                }
              }
              //dimming interface End here
            });
          }
          if (settingObj.length > 0) {
            let configurationData = JSON.parse(
              localStorage.getItem("configurationData")
            );
            let dataSet = [];
            for (
              var i = 0;
              i < newConfigurationID["selectedFeaturesInfo"].length;
              i++
            ) {
              dataSet.push({
                name: newConfigurationID["selectedFeaturesInfo"][i].slice(
                  0,
                  -1
                ),
                action: "included",
              });
            }
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
            this._dataLayerService.logUserDetails({
              UserId: localStorage.getItem("UserId"),
              companyId: decodedObj.company["companyId"],
              companyName: decodedObj.company["companyName"],
              userRole: decodedObj.role,
            });
            this._dataLayerService.setSelectedDeviceInfo({
              event: "saving",
              savingType: "Save Feature File",
              "12NC": configurationData.device12nc,
              features: dataSet,
            });
            var readerConfigurationData1 = JSON.parse(
              localStorage.getItem("readerConfigurationData")
            );

            if (
              readerConfigurationData1 !== null &&
              newConfigurationID["configurationID"] ===
                readerConfigurationData1["configurationID"]
            ) {
              var deviceReaderData = JSON.parse(
                localStorage.getItem("readerConfigurationData")
              )["device12nc"];
              var deviceReaderVersionData = JSON.parse(
                localStorage.getItem("readerConfigurationData")
              )["softwareVersion"];

              let deviceObj = {
                device12nc: deviceReaderData,
                softwareVersion: deviceReaderVersionData,
              };

              this.apiService
                .getStartConfiguration(deviceObj, this.url)
                .subscribe((data1) => {
                  this.newConfigurationIDExtra = data1["configurationID"];
                  /*This block is called after get ConfigurationID and generate feature file.Here setTimeout was removed.*/
                  //setTimeout(() => {
                  this.apiService
                    .setAllfeatureAPI(
                      url,
                      this.newConfigurationIDExtra,
                      settingObj
                    )
                    .subscribe(
                      (data) => {
                        this.countAllFeaturesUnique(
                          this.newConfigurationIDExtra
                        );
                        setTimeout(() => {
                          this.saveFeatureFileClicked = false;
                        }, 5000);
                      },
                      (err) => {
                        this.saveFeatureFileClicked = false;
                        this.loading = false;
                        this.customLoader = false;
                        this.loading = false;
                        const modalRef = this.modalService.open(
                          ErrorComponent,
                          {
                            keyboard: false,
                            centered: true,
                            windowClass: "error-style, error-style1",
                          }
                        );
                        modalRef.componentInstance.errorName = err["message"];
                        modalRef.componentInstance.errorDescription =
                          err["description"];
                      }
                    );
                  // }, 5000);
                  /* End block */
                });
            } else {
              this.apiService
                .setAllfeatureAPI(
                  url,
                  newConfigurationID.configurationID,
                  settingObj
                )
                .subscribe(
                  (data) => {
                    this.countAllFeatures();
                    setTimeout(() => {
                      this.saveFeatureFileClicked = false;
                    }, 5000);
                  },
                  (err) => {
                    this.saveFeatureFileClicked = false;
                    this.loading = false;
                    this.customLoader = false;

                    const modalRef = this.modalService.open(ErrorComponent, {
                      keyboard: false,
                      centered: true,
                      windowClass: "error-style, error-style1",
                    });
                    modalRef.componentInstance.errorName = err["message"];
                    modalRef.componentInstance.errorDescription =
                      err["description"];
                  }
                );
            }
            // this.apiService.setAllfeatureAPI(url, newConfigurationID.configurationID, settingObj).subscribe((data) => {
            //  this.countAllFeatures()
            // })
          }
        });
      localStorage.setItem("exportedFeatureFile", JSON.stringify(true));
      this.configOmitOnRefresh();
      setTimeout(() => {
        this.saveFeatureFileClicked = false;
      }, 5000);
    }
  }
  updateConfigurationDataByFeatureSelected() {
    let tempConfigurationData = JSON.parse(
      localStorage.getItem("configurationData")
    );

    let fullfeatureSetUserSelected: string[] = [];
    if (!isNullOrUndefined(tempConfigurationData.feature12Nc)) {
      tempConfigurationData["feature12Nc"].forEach((element) => {
        if (this.verifySelectedFeatureCount(element)) {
          if (element === "dalipsu-") fullfeatureSetUserSelected.push("srpsu-");
          else fullfeatureSetUserSelected.push(element);
        }
      });
    }

    tempConfigurationData.selectedFeaturesInfo = fullfeatureSetUserSelected;

    localStorage.setItem(
      "configurationData",
      JSON.stringify(tempConfigurationData)
    );
  }

  getFeatureFile(configurationID, url) {
    var configurationDataValue = JSON.parse(
      localStorage.getItem("configurationData")
    );
    let owpAccessPwd = "";
    this.apiService.getowpAccessPwd.subscribe((data) => {
      owpAccessPwd = data;
    });
    this.envFeatureFileUrl = url + this.featureFileUrl;
    if (
      configurationDataValue["selectedFeaturesInfo"].includes("owp-") &&
      owpAccessPwd != ""
    ) {
      this.envFeatureFileUrl =
        this.envFeatureFileUrl + "?owpAccessPassword=" + owpAccessPwd;
    }
    return this.apiService.generatefile(
      this.envFeatureFileUrl,
      configurationID
    );
  }

  getConfigFile(configurationID, url) {
    this.envConfigFileUrl = url + this.deviceConfigFileUrl;
    var configurationDataValue = JSON.parse(
      localStorage.getItem("configurationData")
    );
    let owpAccessPwd = "";
    this.apiService.getowpAccessPwd.subscribe((data) => {
      owpAccessPwd = data;
    });
    if (
      configurationDataValue["selectedFeaturesInfo"].includes("owp-") &&
      owpAccessPwd != ""
    ) {
      this.envConfigFileUrl =
        this.envConfigFileUrl + "?owpAccessPassword=" + owpAccessPwd;
    }
    return this.apiService.generatefile(this.envConfigFileUrl, configurationID);
  }

  exportFeatureFile(configurationID) {
    this.loading = true;
    let file = [];
    this.apiService.getEnvValue();
    this.apiService.getEnvData.pipe(takeUntil(this.unsubscribe)).subscribe(
      (url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.envFeatureFileUrl = url + this.featureFileUrl;
          let featureFile = this.getFeatureFile(configurationID, url);
          let configFile = this.getConfigFile(configurationID, url);

          if (this.isSelectedDCFFile) {
            file.push(configFile);
          }

          if (this.isSelectedSaveFeatureFile) {
            file.push(featureFile);
          }

          // var currentDate = new Date('dd-MM-yyy');
          // this.apiService
          //   .generatefile(this.envFeatureFileUrl, configurationID)
          forkJoin(file).subscribe(
            (response) => {
              this.loading = false;
              this.isDisabledButton = true;
              let encryptedText = [];
              encryptedText = response;
              this.softwareVersion = this.softwareVersion = JSON.parse(
                localStorage.getItem("comparefirmwarenoLatest")
              );
              if (this.softwareVersion.length > 3) {
                this.softwareVersion = this.softwareVersion.slice(0, -2);
              }

              encryptedText.forEach((encryptedfile, i) => {
                let blob: any = new Blob([encryptedfile], {
                  type: "application/plain; charset=utf-8",
                });
                let ext = "";
                if (encryptedText.length == 2) {
                  if (encryptedText.length == 2 && i == 0) {
                    ext = ".json";
                  } else if (encryptedText.length == 2 && i == 1) {
                    ext = ".xml";
                  }
                } else {
                  if (this.isSelectedDCFFile) {
                    ext = ".json";
                  }
                  if (this.isSelectedSaveFeatureFile) {
                    ext = ".xml";
                  }
                }

                saveAs(
                  blob,
                  this.receivedFeature.device12nc +
                    "_" +
                    this.receivedFeature.deviceName +
                    "_" +
                    this.softwareVersion +
                    "_" +
                    this.currentDate +
                    ext
                );
              });
              if (response) {
                // localStorage.removeItem("configurationData")
                // localStorage.removeItem('quickConfiguration');
                // this.router.navigate(['/deviceList']);
                localStorage.setItem(
                  "featureFileCReation",
                  JSON.stringify(true)
                );
                localStorage.removeItem("configId");
                this.loading = false;
                // localStorage.removeItem('configurationData');
              }
            },
            (err) => {
              this.loading = false;
              // alert(JSON.stringify(err));
              let myObj = JSON.parse(err);
              setTimeout(() => {
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = myObj["message"];
                modalRef.componentInstance.errorDescription =
                  myObj["description"];
              }, 500);
            }
          );
        }
      },
      (error) => {
        this.loading = false;
        let myObj = JSON.parse(error);
        // alert(error['description']);
        this.loading = false;
        const modalRef = this.modalService.open(ErrorComponent, {
          keyboard: false,
          centered: true,
          windowClass: "error-style, error-style1",
        });
        modalRef.componentInstance.errorName = myObj["message"];
        modalRef.componentInstance.errorDescription = myObj["description"];
      }
    );
  }

  startConfiguration(id?) {
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.startConfigURL = url;
          this.softwareVersion = JSON.parse(
            localStorage.getItem("comparefirmwarenoLatest")
          );
          if (this.softwareVersion.length > 3) {
            this.softwareVersion = this.softwareVersion.slice(0, -2);
          }
          // till demo purpose
          let mockDevice = {};
          if (this.receivedFeature.deviceName === "XH075C120V075KPF1") {
            mockDevice = {
              device12nc: this.receivedFeature.device12nc,
              softwareVersion: this.softwareVersion,
            };
          } else {
            mockDevice = {
              device12nc: this.receivedFeature.device12nc,
              deviceName: this.receivedFeature.deviceName,
              softwareVersion: this.softwareVersion,
            };
          }
          this.apiService
            .getStartConfiguration(mockDevice, this.startConfigURL)
            .subscribe(
              (data) => {
                localStorage.removeItem("deviceVersion");
                var configurationID;
                configurationID = data["configurationID"];
                if (localStorage.getItem("configurationData")) {
                  var dataFromConfig = JSON.parse(
                    localStorage.getItem("configurationData")
                  );
                  this.receivedFeature = {
                    deviceName: dataFromConfig.deviceName,
                    resp: dataFromConfig.resp,
                    device12nc: dataFromConfig.device12nc,
                    softwareVerSchema: dataFromConfig.softwareVerSchema,
                    picture: dataFromConfig.picture,
                    luminSoftVer: dataFromConfig.luminSoftVer,
                    dtlSoftVer: dataFromConfig.dtlSoftVer,
                    flextuneSoftVer: dataFromConfig.flextuneSoftVer,
                    multiconfigSoftVer: dataFromConfig.multiconfigSoftVer,
                    mindimlevelSoftVer: dataFromConfig.mindimlevelSoftVer,
                    dalipsuSoftVer: dataFromConfig.dalipsuSoftVer,
                    selectedFeaturesInfo: dataFromConfig.selectedFeaturesInfo,
                    feature12Nc: dataFromConfig.feature12Nc,
                    ztvSoftVer: dataFromConfig.ztvSoftVer,
                    ztvoutputSoftVer: dataFromConfig.ztvoutputSoftVer,
                    lipSoftVer: dataFromConfig.lipSoftVer,
                    rsoSoftVer: dataFromConfig.rsoSoftVer,
                    mtpSoftVer: dataFromConfig.mtpSoftVer,
                    dimminginterfaceSoftVer:
                      dataFromConfig.dimminginterfaceSoftVer,
                    ampdimSoftVer: dataFromConfig.ampdimSoftVer,
                    aocSoftVer: dataFromConfig.aocSoftVer,
                    touchdimSoftVer: dataFromConfig.touchdimSoftVer,
                    diia253maintenanceSoftVer:
                      dataFromConfig.diia253maintenanceSoftVer,
                    corridorSoftVer: dataFromConfig.corridorSoftVer,
                    astSoftVer: dataFromConfig.astSoftVer,
                    cloliteSoftVer: dataFromConfig.cloliteSoftVer,
                    cloSoftVer: dataFromConfig.cloSoftVer,
                    dynadimmerSoftVer: dataFromConfig.dynadimmerSoftVer,
                    dcemergencySoftVer: dataFromConfig.dcemergencySoftVer,
                    lineswitchSoftVer: dataFromConfig.lineswitchSoftVer,
                    owpSoftVer: dataFromConfig.owpSoftVer,
                    codedmainsSoftVer: dataFromConfig.codedmainsSoftVer,
                  };
                  this.softwareVersion = dataFromConfig.softwareVersion;
                }

                if (this.isZtvDependDimming) {
                  this.RemoveItem("ztv-");
                }

                this.receivedFeature.feature12Nc =
                  this.receivedFeature.feature12Nc.sort();
                this.sortAllFeature();
                this.startConfigurationData["configurationID"] =
                  configurationID;
                this.receivedFeature.configurationID = configurationID;
                this.startConfigurationData["resp"] = this.receivedFeature.resp;
                this.startConfigurationData["deviceName"] =
                  this.receivedFeature.deviceName;
                this.startConfigurationData["picture"] =
                  this.receivedFeature.picture;
                this.startConfigurationData["device12nc"] =
                  this.receivedFeature.device12nc;
                this.startConfigurationData["softwareVersion"] =
                  this.softwareVersion;
                this.startConfigurationData["softwareVerSchema"] =
                  this.receivedFeature.softwareVerSchema;
                this.startConfigurationData["selectedFeaturesInfo"] =
                  this.receivedFeature.selectedFeaturesInfo;
                this.startConfigurationData["feature12Nc"] =
                  this.receivedFeature.feature12Nc;
                this.startConfigurationData["luminSoftVer"] =
                  this.receivedFeature.luminSoftVer;
                this.startConfigurationData["mtpSoftVer"] =
                  this.receivedFeature.mtpSoftVer;
                this.startConfigurationData["dimminginterfaceSoftVer"] =
                  this.receivedFeature.dimminginterfaceSoftVer;
                this.startConfigurationData["ampdimSoftVer"] =
                  this.receivedFeature.ampdimSoftVer;
                this.startConfigurationData["dtlSoftVer"] =
                  this.receivedFeature.dtlSoftVer;
                this.startConfigurationData["flextuneSoftVer"] =
                  this.receivedFeature.flextuneSoftVer;
                this.startConfigurationData["multiconfigSoftVer"] = this.receivedFeature.multiconfigSoftVer;
                this.startConfigurationData["mindimlevelSoftVer"] =
                  this.receivedFeature.mindimlevelSoftVer;
                this.startConfigurationData["dalipsuSoftVer"] =
                  this.receivedFeature.dalipsuSoftVer;
                this.startConfigurationData["ztvSoftVer"] =
                  this.receivedFeature.ztvSoftVer;
                this.startConfigurationData["ztvoutputSoftVer"] =
                  this.receivedFeature.ztvoutputSoftVer;
                this.startConfigurationData["lipSoftVer"] =
                  this.receivedFeature.lipSoftVer;
                this.startConfigurationData["rsoSoftVer"] =
                  this.receivedFeature.rsoSoftVer;
                this.startConfigurationData["aocSoftVer"] =
                  this.receivedFeature.aocSoftVer;
                this.startConfigurationData["touchdimSoftVer"] =
                  this.receivedFeature.touchdimSoftVer;
                this.startConfigurationData["diia253maintenanceSoftVer"] =
                  this.receivedFeature.diia253maintenanceSoftVer;
                this.startConfigurationData["corridorSoftVer"] =
                  this.receivedFeature.corridorSoftVer;
                this.startConfigurationData["astSoftVer"] =
                  this.receivedFeature.astSoftVer;
                this.startConfigurationData["cloliteSoftVer"] =
                  this.receivedFeature.cloliteSoftVer;
                this.startConfigurationData["cloSoftVer"] =
                  this.receivedFeature.cloSoftVer;
                this.startConfigurationData["dynadimmerSoftVer"] =
                  this.receivedFeature.dynadimmerSoftVer;
                this.startConfigurationData["dcemergencySoftVer"] =
                  this.receivedFeature.dcemergencySoftVer;
                this.startConfigurationData["lineswitchSoftVer"] =
                  this.receivedFeature.lineswitchSoftVer;
                this.startConfigurationData["owpSoftVer"] =
                  this.receivedFeature.owpSoftVer;
                this.startConfigurationData["codedmainsSoftVer"] =
                  this.receivedFeature.codedmainsSoftVer;
                this.receivedFeature.device12nc =
                  this.receivedFeature.device12nc;
                localStorage.setItem(
                  "configurationData",
                  JSON.stringify(this.startConfigurationData)
                );
                this.apiService.setConfigurationData(
                  this.startConfigurationData
                );
                // this.loading=false;
                if (id) {
                  this.exportFeatureFile(id);
                }
              },
              (err) => {
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
        }
      });
  }
  onCheckboxChnge(value, checked) {
    var configArray = JSON.parse(localStorage.getItem("configurationData"));
    // if(value === 'Clolite') {
    //   value = 'CloLite'
    // }
    // if(value === 'Srpsu') {
    //   value = 'Psu'
    // }
    if (value === "dalipsu-") value = "srpsu-";
    if (value === "CLO_CLOLITE") {
      let list12NcName: any[] = configArray.feature12Nc;

      list12NcName.forEach((element) => {
        if (
          element.toString() === "clolite-" ||
          element.toString() === "cloLite-"
        ) {
          value = element;
        }
      });
    }
    if (value === "Lumin") {
      let list12NcName: any[] = configArray.feature12Nc;

      list12NcName.forEach((element) => {
        if (
          //MOWEB 1.0.1 DONE
          /* element === "luminfoformat0-" || */
          //MOWEB 1.0.1 DONE
          element === "luminfoformat0-" ||
          element.toString() === "luminfoformat3-"
        ) {
          value = element;
        }
      });
    }
    if (checked) {
      let existsIndex = this.newFeatureArray.indexOf(value);
      if (existsIndex >= 0) {
        this.newFeatureArray.splice(existsIndex, 1);
        this.countQuickFeature = this.countQuickFeature - 1;
        this.countConfigure(false);
      }
      this.newFeatureArray.push(value);
      if (value === "Alo" || value === "alo-") {
        this.isAloQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.aloSelected = checked;
        this.countConfigure(checked);
        // this.aloschemaListDetails();
      }
      if (value === "Dtl" || value === "DTL" || value === "dtl-") {
        this.isDtlQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.dtlSelected = checked;
        this.apiService.getdtlInputError.subscribe((isDtlError) => {
          this.dtlEnableCreatFile = isDtlError;
        });
        this.countConfigure(checked);
        // this.aloschemaListDetails();
      }
      if (value === "FlexTune" || value === "flextune-") {
        this.countQuickFeature = this.countQuickFeature + 1;
        this.flextuneSelected = true;
      }
      if (
        value === "Min Dim Level" ||
        value === "Min dim level" ||
        value === "mindimlevel-"
      ) {
        this.isMinDimLevelQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.minDimLevelSelected = checked;
        this.apiService.getminDimLevelInputError.subscribe((isminDimError) => {
          this.minDimLevelEnableCreatFile = isminDimError;
        });
        // this.aloschemaListDetails();
      }
      if (
        value === "Corridor" ||
        value === "corridor-" ||
        value === "corridor"
      ) {
        this.isCorridorQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.corridorSelected = checked;
        this.apiService.getCorridorError.subscribe((isCorridorError) => {
          this.corridorEnableCreatFile = isCorridorError;
        });
        // this.aloschemaListDetails();
      }
      if (value === "Mtp" || value === "mtp-" || value === 'MTP') {
        this.isMtpQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.mtpSelected = checked;
        this.countConfigure(checked);
        this.apiService.getMtpError.subscribe((isMtpError) => {
          this.mtpEnableCreatFile = isMtpError;
        });
      }
      if (value === "ztvoutput-") {
        this.isztvoutputQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.ztvoutputSelected = checked;
        this.countConfigure(checked);
        this.apiService.getztvoutputError.subscribe((isztvoutputError) => {
          this.ztvoutputEnableCreatFile = isztvoutputError;
        });
      }
      if (value === "rso-") {
        this.isrsoQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.rsoSelected = checked;
        this.countConfigure(checked);
        this.apiService.getrsoError.subscribe((isrsoError) => {
          this.rsoEnableCreatFile = isrsoError;
        });
      }
      if (value === "lip-") {
        this.islipQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.lipSelected = checked;
        this.countConfigure(checked);
        this.apiService.getlipError.subscribe((islipError) => {
          this.lipEnableCreatFile = islipError;
        });
      }
      if (value === "Amp Dim" || value === "ampdim" || value === "ampdim-") {
        this.isAmpDimQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.ampDimSelected = checked;
        this.countConfigure(checked);
        this.apiService.getampdimError.subscribe((isAmpDimError) => {
          this.ampDimCreatFile = isAmpDimError;
        });
      }
      if (value === "Line Switch" || value === "lineswitch-") {
        this.isLineSwitchDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.lineSwitchSelected = checked;
        this.countConfigure(checked);
        this.apiService.getLineSwitchError.subscribe((isLineSwitchError) => {
          this.lineSwitchEnableCreatFile = isLineSwitchError;
        });
      }
      if (value === "Owp" || value === "owp-") {
        this.isOwpDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.owpSelected = checked;
        this.countConfigure(checked);
        this.apiService.getowpError.subscribe((isowpError) => {
          this.owpEnableCreatFile = isowpError;
        });
      }
      if (value === "codedmains" || value === "codedmains-") {
        this.iscodedmainsDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.codedmainsSelected = checked;
        this.countConfigure(checked);
        this.apiService.getcodedmainsError.subscribe((iscodedmainsError) => {
          this.codedmainsEnableCreatFile = iscodedmainsError;
        });
      }
      if (value === "Clo" || value === "clo-") {
        this.countConfigure(checked);
        //  this.isCloQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.cloSelected = checked;
        //  if(this.receivedFeature.selectedFeaturesInfo === 'Clo') {
        //   this.cloSchemaListDetails();
        //  } else {
        //   // this.CloLIteListDetails();
        //  }
        this.apiService.checkGraphIsValid.subscribe((data) => {
          if (data == "invalid") {
            // this.isCloQuickDisable = true;
            // this.buttonDisable = true;
            this.isClo = true;
          } else {
            // this.isCloQuickDisable = false;
            // this.buttonDisable = false;
            this.isClo = false;
          }
        });
      }
      if (value === "CloLite" || value === "Clolite" || value === "clolite-") {
        this.countConfigure(checked);
        this.iscloLiteQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.cloLiteSelected = checked;
        this.isClo = false;
        //  if(this.receivedFeature.selectedFeaturesInfo === 'Clo') {
        //   this.cloSchemaListDetails();
        //  } else {
        //   // this.CloLIteListDetails();
        //  }
        this.apiService.checkGraphIsValid.subscribe((data) => {
          if (data == "invalid" && this.cloLiteSelected == true) {
            // this.isCloQuickDisable = true;
            // this.buttonDisable = true;
            this.isCloLite = true;
          } else {
            // this.isCloQuickDisable = false;
            // this.buttonDisable = false;
            this.isCloLite = false;
          }
        });
      }

      //DYNA
      if (value === "Dyna Dimmer" || value === "dynadimmer-") {
        this.countConfigure(checked);
        this.isdynaDimmerQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.dynaDimmerSelected = checked;
        this.isdynaDimmer = false;
      }
      if (value === "Aoc" || value === "aoc-") {
        this.countConfigure(checked);
        this.isAocQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.aocSelected = checked;
        // this.aocSchemaListDetails();
        this.apiService.getformInputValidation.subscribe((data) => {
          if (data == true) {
            this.aocDisableCreateFile = true;
            //this.isAocQuickDisable = true;
            this.buttonDisable = true;
          } else {
            this.aocDisableCreateFile = false;
            //this.isAocQuickDisable = false;
            this.buttonDisable = false;
          }
        });
      }
      if (value === "Startup time" || value === "ast-") {
        this.isStartupQuickDisable = true;
        this.countConfigure(checked);
        this.countQuickFeature = this.countQuickFeature + 1;
        this.startupSelected = checked;
        // this.aocSchemaListDetails();
        this.apiService.checkStartupIsValid.subscribe((data) => {
          if (!isNullOrUndefined(data) && data != "") {
            if (data == "invalid" && this.startupSelected == true) {
              this.startUpValueValid = true;
            } else {
              this.startUpValueValid = false;
            }
          }
        });
      }
      if (value === "0-10v / 1-10v" || value === "ztv-") {
        this.countConfigure(checked);
        this.isZtvQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.ztvSelected = checked;
        // this.aocSchemaListDetails();
        this.apiService.getztvInputError.subscribe((isZtvError) => {
          if (isZtvError && this.ztvSelected == true) {
            this.isZtvError = true;
            this.buttonDisable = true;
          } else {
            this.isZtvError = false;
            this.buttonDisable = false;
          }
        });
      }

      if (value === "Psu" || value === "Srpsu" || value === "srpsu-") {
        this.countConfigure(checked);
        this.isPsuQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.psuSelected = checked;
        // this.psuSchemaListDetails();
      }
      if (
        value === "Lumin" ||
        value === "LumInfo" ||
        value.match(/[^,]*luminfofor[^,]*/g)
      ) {
        this.countConfigure(checked);
        this.isLuminQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.luminSelected = checked;
        // this.lumInfoListDetails();
        this.apiService.getLuminFormError.subscribe((data) => {
          if (data && this.luminSelected == true) {
            this.disableLuminInfo = true;
            this.isLuminQuickDisable = true;
          } else {
            this.disableLuminInfo = false;
            this.isLuminQuickDisable = false;
          }
        });
      }

      if (value === "DC Emergency" || value === "dcemergency-") {
        this.countConfigure(checked);
        this.isDcemergencyQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.dcemergencySelected = checked;
        this.apiService.getDcemergencyInputError.subscribe(
          (isDcEmergencyError) => {
            if (isDcEmergencyError && this.dcemergencySelected == true) {
              this.dcemergencyValueValid = true;
              this.buttonDisable = true;
            } else {
              this.dcemergencyValueValid = false;
              this.buttonDisable = false;
            }
          }
        );
      }
      if (value === "diia253maintenance" || value === "diia253maintenance-") {
        this.isdiia253maintenanceQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.diia253maintenanceSelected = checked;
        this.countConfigure(checked);
        this.apiService.getdiia253maintenanceInputError.subscribe(
          (isdiia253maintenanceError) => {
            if (
              isdiia253maintenanceError &&
              this.diia253maintenanceSelected == true
            ) {
              this.diia253maintenanceValueValid = true;
              this.buttonDisable = true;
            } else {
              this.diia253maintenanceValueValid = false;
              this.buttonDisable = false;
            }
          }
        );
      }
      if (value === "Owp" || value === "owp-") {
        this.isOwpDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.owpSelected = checked;
        this.countConfigure(checked);
        this.apiService.getowpError.subscribe((isowpError) => {
          if (isowpError && this.owpSelected == true) {
            this.owpValueValid = true;
            this.buttonDisable = true;
          } else {
            this.owpValueValid = false;
            this.buttonDisable = false;
          }
        });
      }
      if (value === "Touch and Dim" || value === "touchdim-") {
        this.isTouchDimQuickDisable = true;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.touchDimSelected = checked;
        this.countConfigure(checked);
        this.apiService.getTouchDimInputError.subscribe((isTouchDimError) => {
          if (isTouchDimError && this.touchDimSelected == true) {
            this.touchDimValueValid = true;
            this.buttonDisable = true;
          } else {
            this.touchDimValueValid = false;
            this.buttonDisable = false;
          }
        });
      }
      if (value === "dimminginterface-") {
        this.countQuickFeature = this.countQuickFeature + 1;
        this.dimmingInterfaceSelected = true;
      }
      if(value === 'multiconfigmode-'){
        this.countQuickFeature = this.countQuickFeature + 1;
        this.multiconfigSelected = true;
      }
    } else {
      let index = this.newFeatureArray.indexOf(value);
      if (index > -1) {
        this.newFeatureArray.splice(index, 1);
      }
      if (value === "Dyna Dimmer" || value === "dynadimmer-") {
        this.isdynaDimmerQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.dynaDimmerSelected = checked;
        this.isdynaDimmer = false;
        this.countConfigure(checked);
      }
      if (value === "Alo" || value === "alo-") {
        this.countConfigure(checked);
        this.isAloQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.aloSelected = checked;
      }
      if (value === "Dtl" || value === "DTL" || value === "dtl-") {
        this.countConfigure(checked);
        this.isDtlQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.dtlSelected = checked;
        this.dtlEnableCreatFile = false;
        // this.aloschemaListDetails();
      }
      if (
        value === "Min Dim Level" ||
        value === "Min dim level" ||
        value === "mindimlevel-"
      ) {
        this.isMinDimLevelQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.minDimLevelSelected = checked;
        this.minDimLevelEnableCreatFile = false;
        // this.aloschemaListDetails();
      }
      if (
        value === "Corridor" ||
        value === "corridor-" ||
        value === "corridor"
      ) {
        this.isCorridorQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.corridorSelected = checked;
        this.corridorEnableCreatFile = false;
        // this.aloschemaListDetails();
      }
      //to be updated after api is done
      if (value === "Line Switch" || value === "lineswitch-") {
        this.countConfigure(checked);
        this.isLineSwitchDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.lineSwitchSelected = checked;
        this.lineSwitchEnableCreatFile = false;
        //this.countConfigure(checked);
      }
      if (value === "codedmains" || value === "codedmains-") {
        this.countConfigure(checked);
        this.iscodedmainsDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.codedmainsSelected = checked;
        this.codedmainsEnableCreatFile = false;
      }
      if (value === "Mtp" || value === "mtp-") {
        this.countConfigure(checked);
        this.isMtpQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.mtpSelected = checked;
        this.mtpEnableCreatFile = false;
      }
      if (value === "ztvoutput-") {
        this.countConfigure(checked);
        this.isztvoutputQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.ztvoutputSelected = checked;
        this.ztvoutputEnableCreatFile = false;
      }
      if (value === "rso-") {
        this.countConfigure(checked);
        this.isrsoQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.rsoSelected = checked;
        this.rsoEnableCreatFile = false;
      }
      if (value === "lip-") {
        this.countConfigure(checked);
        this.islipQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.lipSelected = checked;
        this.lipEnableCreatFile = false;
      }
      if (value === "Amp Dim" || value === "ampdim" || value === "ampdim-") {
        this.countConfigure(checked);
        this.isAmpDimQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature + 1;
        this.ampDimSelected = checked;

        this.ampDimCreatFile = false;
      }
      if (value === "CloLite" || value === "Clolite" || value === "clolite-") {
        this.countConfigure(checked);
        this.iscloLiteQuickDisable = false;
        this.isClo = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.cloLiteSelected = checked;
        //  if(this.receivedFeature.selectedFeaturesInfo === 'Clo') {
        //   this.cloSchemaListDetails();
        //  } else {
        //   // this.CloLIteListDetails();
        //  }
        this.apiService.checkGraphIsValid.subscribe((data) => {
          if (data == "invalid" || this.cloLiteSelected == false) {
            // this.isCloQuickDisable = false;
            // this.buttonDisable = false;
            this.isCloLite = false;
          } else {
            // this.isCloQuickDisable = true;
            // this.buttonDisable = true;
            this.isCloLite = true;
          }
        });
      }
      if (value === "Clo" || value === "clo-") {
        this.countConfigure(checked);
        //  this.isCloQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.cloSelected = checked;
        this.isCloLite = false;
        this.apiService.checkGraphIsValid.subscribe((data) => {
          if (data == "invalid") {
            // this.isCloQuickDisable = false;
            // this.buttonDisable = false;
            this.isClo = true;
          } else {
            // this.isCloQuickDisable = true;
            // this.buttonDisable = true;
            this.isClo = false;
          }
        });
      }
      if (value === "Aoc" || value === "aoc-") {
        this.countConfigure(checked);
        this.isAocQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.aocSelected = checked;
        this.apiService.getformInputValidation.subscribe((data) => {
          if (data == false) {
            this.aocDisableCreateFile = false;
            //this.isAocQuickDisable = false;
            this.buttonDisable = false;
            // this.apiService.sendAOCValidData("invalid");
          } else {
            this.aocDisableCreateFile = true;
            //this.isAocQuickDisable = true;
            this.buttonDisable = true;
            // this.apiService.sendAOCValidData("");
          }
        });
      }
      if (value === "Startup time" || value === "ast-") {
        this.countConfigure(checked);
        this.isStartupQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.startupSelected = checked;
        this.apiService.checkStartupIsValid.subscribe((data) => {
          if (!isNullOrUndefined(data) && data != "") {
            if (data == "invalid" && this.startupSelected == false) {
              this.startUpValueValid = false;
              // this.apiService.sendStartupValidData("invalid");
            } else {
              this.startUpValueValid = true;
              // this.apiService.sendStartupValidData("valid");
            }
          }
        });
      }
      if (value === "0-10v / 1-10v" || value === "ztv-") {
        this.countConfigure(checked);
        this.isZtvQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.ztvSelected = checked;
        this.apiService.getztvInputError.subscribe((isZtvError) => {
          if (isZtvError || this.ztvSelected == false) {
            this.isZtvError = false;
            this.buttonDisable = false;
            // this.apiService.ztvInputError(false);
          } else {
            this.isZtvError = true;
            this.buttonDisable = true;
            // this.apiService.ztvInputError(true);
          }
        });
      }
      if (value === "Psu" || value === "Srpsu" || value === "srpsu-") {
        this.countConfigure(checked);
        this.isPsuQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.psuSelected = checked;
      }
      if (
        value === "Lumin" ||
        value === "LumInfo" ||
        value.match(/[^,]*luminfofor[^,]*/g)
      ) {
        this.countConfigure(checked);
        this.isLuminQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.luminSelected = checked;
        this.disableLuminInfo = false;
        this.apiService.getLuminFormError.subscribe((data) => {
          if (data || this.luminSelected == false) {
            this.disableLuminInfo = false;
            this.isLuminQuickDisable = false;
          } else {
            this.disableLuminInfo = true;
            this.isLuminQuickDisable = true;
          }
        });
      }

      if (value === "DC Emergency" || value === "dcemergency-") {
        this.countConfigure(checked);
        this.isDcemergencyQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.dcemergencySelected = checked;
        this.apiService.getDcemergencyInputError.subscribe(
          (isDCEmergencyError) => {
            if (isDCEmergencyError || this.dcemergencySelected == false) {
              this.dcemergencyValueValid = false;
              this.buttonDisable = false;
            } else {
              this.dcemergencyValueValid = true;
              this.buttonDisable = true;
            }
          }
        );
      }

      if (value === "Touch and Dim" || value === "touchdim-") {
        this.countConfigure(checked);
        this.isTouchDimQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.touchDimSelected = checked;
        this.apiService.getTouchDimInputError.subscribe((isTouchDimError) => {
          if (isTouchDimError || this.touchDimSelected == false) {
            this.touchDimValueValid = false;
            this.buttonDisable = false;
          } else {
            this.touchDimValueValid = true;
            this.buttonDisable = true;
          }
        });
      }
      if (value === "diia253maintenance" || value === "diia253maintenance-") {
        this.countConfigure(checked);
        this.isdiia253maintenanceQuickDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.diia253maintenanceSelected = checked;
        this.apiService.getdiia253maintenanceInputError.subscribe(
          (isdiia253maintenanceError) => {
            if (
              isdiia253maintenanceError ||
              this.diia253maintenanceSelected == false
            ) {
              this.diia253maintenanceValueValid = false;
              this.buttonDisable = false;
            } else {
              this.diia253maintenanceValueValid = true;
              this.buttonDisable = true;
            }
          }
        );
      }
      if (value === "Owp" || value === "owp-") {
        this.countConfigure(checked);
        this.isOwpDisable = false;
        this.countQuickFeature = this.countQuickFeature - 1;
        this.owpSelected = checked;
        this.apiService.getowpError.subscribe((isowpError) => {
          if (isowpError || this.owpSelected == false) {
            this.owpValueValid = false;
            this.buttonDisable = false;
          } else {
            this.owpValueValid = true;
            this.buttonDisable = true;
          }
        });
      }
      if (value === "dimminginterface-") {
        this.countQuickFeature = this.countQuickFeature - 1;
        this.dimmingInterfaceSelected = false;
        // this.disableDimmingInterface
      }
      if (value === "multiconfigmode-") {
        this.countQuickFeature = this.countQuickFeature - 1;
        this.multiconfigSelected = false;
        // this.disableDimmingInterface
      }
      if (value === "flextune-" || value==='FlexTune') {
        this.countQuickFeature = this.countQuickFeature - 1;
        this.flextuneSelected = false;
        // this.disableDimmingInterface
      }

      if (this.countQuickFeature < 0) {
        this.countQuickFeature = 0;
      }
    }
    if (!isNullOrUndefined(configArray)) {
      configArray["selectedFeaturesInfo"] = this.newFeatureArray;
    }
    
    this.countConfigureFeature = 0;
    this.countQuickFeature = 0;
    this.newFeatureArray.forEach((ele) => {
      if (
        ele === "alo-" ||
        ele === "aoc-" ||
        ele === "clo-" ||
        ele === "cloLite-" ||
        ele === "clolite-" ||
        ele === "ztv-" ||
        ele === "ast-" ||
        ele === "srpsu-" ||
        ele === "luminfoformat0-" ||
        ele === "luminfoformat3-" ||
        ele === "dynadimmer-" ||
        ele === "ampdim-" ||
        ele === "lineswitch-" ||
        ele === "diia253maintenance-" ||
        ele === "owp-" ||
        ele === "dcemergency-" ||
        ele === "dtl-" ||
        ele === "mindimlevel-" ||
        ele === "mtp-" ||
        ele === "Touch and Dim" ||
        ele === "touchdim-" ||
        ele === "corridor" ||
        ele === "codedmains-" ||
        ele === "corridor-" ||
        ele === "ztvoutput-" ||
        ele === "rso-" ||
        ele === "lip-" ||
        ele === 'multiconfigmode-' ||
        ele === 'flextune-'
      ) {
        this.countConfigureFeature = this.countConfigureFeature + 1;
        this.countQuickFeature = this.countQuickFeature + 1;
      }
      if (ele === "dimminginterface-") {
        this.countQuickFeature = this.countQuickFeature + 1;
      }
    });

    localStorage.setItem("configurationData", JSON.stringify(configArray));
    if (
      configArray["feature12Nc"].includes("dimminginterface-") &&
      configArray["feature12Nc"].includes("ztv-") &&
      (value === "dimminginterface-" || value === "ztv-")
    ) {
      this.apiService.sendDimmingCheckValidation("success");
    }
    if (
      !this.newFeatureArray.includes("dimminginterface-") &&
      !this.newFeatureArray.includes("ztv-")
    ) {
      this.apiService.validtionOfDimming("valid");
    }
  }

  ngDoCheck() {
    if (
      localStorage.getItem("detectedReaderInfo") &&
      localStorage.getItem("detectedReaderInfo") != "undefined"
    ) {
      let readers = JSON.parse(localStorage.getItem("detectedReaderInfo"));
      if (readers && !isNullOrUndefined(readers["selectedReader"])) {
        this.isEnableConfigDevice = true;
        if (readers["selectedReader"]["peripheralType"] === "LCN9640") {
          this.isEnabledConfigBox = true;
        } else {
          this.isEnabledConfigBox = false;
        }
      } else {
        this.isEnableConfigDevice = false;
      }
    } else {
      this.isEnabledConfigBox = false;
      this.isEnableConfigDevice = false;
    }

    if (
      localStorage.getItem("storeSaveFeatureFile") &&
      localStorage.getItem("storeSaveFeatureFile") != "undefined"
    ) {
      let saveFeature = JSON.parse(
        localStorage.getItem("storeSaveFeatureFile")
      );
      this.isSelectedSaveFeatureFile = saveFeature["storeSaveFeatureFileCheck"];
    } else {
      this.isSelectedSaveFeatureFile = false;
    }

    if (
      localStorage.getItem("storeDCFFile") &&
      localStorage.getItem("storeDCFFile") != "undefined"
    ) {
      let dcf = JSON.parse(localStorage.getItem("storeDCFFile"));
      this.isSelectedDCFFile = dcf["storeDCFFileCheck"];
    } else {
      this.isSelectedDCFFile = false;
    }
  }

  standardDoubleArrowClick() {
    if (this.doubleArrowVal === true) {
      this.doubleArrowVal = false;
    } else {
      this.doubleArrowVal = true;
    }
    // for (var featureName in this.openFeature) {
    //   this.openFeature[featureName]=this.doubleArrowVal;
    // }
    for (var i = 0; i < this.openFeature.length; i++) {
      if (
        this.openFeature[i] === "CloLite" ||
        this.openFeature[i] === "clolite-"
      ) {
        this.openFeature[i] = "clolite-";
      }
      if (this.doubleArrowVal) {
        if (this.openFeature[i] === "multiconfigmode-") this.openFeature["multiconfigmode-"] = true;
        if (this.openFeature[i] === "aoc-") this.openFeature["aoc-"] = true;
        if (this.openFeature[i] == "alo-") this.openFeature["alo-"] = true;
        if (this.openFeature[i] == "srpsu-") this.openFeature["srpsu-"] = true;
        if (this.openFeature[i] == "dalipsu-")
          this.openFeature["srpsu-"] = true;
        if (
          this.openFeature[i] == "luminfoformat1-" ||
          this.openFeature[i] == "luminfoformat3-" ||
          this.openFeature[i] == "luminfoformat0-"
        )
          this.openFeature["LUMINFO"] = true;
        if (this.openFeature[i] == "clo-") this.openFeature["clo-"] = true;
        if (this.openFeature[i] == "clolite-")
          this.openFeature["clolite-"] = true;
        if (
          this.openFeature[i] == "MIN DIM LEVEL" ||
          this.openFeature[i] == "mindimlevel-"
        ) {
          this.openFeature["mindimlevel-"] = true;
          //this.openFeature["mindimlevel-"] = true;
        }
        if (this.openFeature[i] == "corridor-")
          this.openFeature["corridor-"] = true;
        if (this.openFeature[i] == "mtp-") this.openFeature["mtp-"] = true;
        if (this.openFeature[i] == "ampdim-")
          this.openFeature["ampdim-"] = true;
        if (this.openFeature[i] === "lineswitch-")
          this.openFeature["lineswitch-"] = true;
        if (this.openFeature[i] === "codedmains-")
          this.openFeature["codedmains-"] = true;
        if (this.openFeature[i] == "ztv-") {
          this.openFeature["ztv-"] = true;
        }
        if (this.openFeature[i] == "ztvoutput-") {
          this.openFeature["ztvoutput-"] = true;
        }
        if (this.openFeature[i] == "luminaireinputpower-") {
          this.openFeature["luminaireinputpower-"] = true;
        }
        if (this.openFeature[i] == "rso-") {
          this.openFeature["rso-"] = true;
        }
        if (this.openFeature[i] == "lip-") {
          this.openFeature["lip-"] = true;
        }
        if (this.openFeature[i] == "dimminginterface-")
          this.openFeature["dimminginterface-"] = true;
        if (this.openFeature[i] == "dynadimmer-")
          this.openFeature["dynadimmer-"] = true;
        if (this.openFeature[i] == "ast-") {
          this.openFeature["ast-"] = true;
        }
        if (this.openFeature[i] == "dcemergency-") {
          this.openFeature["dcemergency-"] = true;
        }
        if (this.openFeature[i] == "touchdim-") {
          this.openFeature["touchdim-"] = true;
        }
        if (this.openFeature[i] == "diia253maintenance-") {
          this.openFeature["diia253maintenance-"] = true;
        }
        if (this.openFeature[i] == "owp-") {
          this.openFeature["owp-"] = true;
        }
        if (this.openFeature[i] == "dtl-") {
          this.openFeature["dtl-"] = true;
        }
        if (this.openFeature[i] == "flextune-") {
          this.openFeature["flextune-"] = true;
        }
      } else {
        if (this.openFeature[i] === "multiconfigmode-") this.openFeature["multiconfigmode-"] = false;
        if (this.openFeature[i] === "aoc-") this.openFeature["aoc-"] = false;
        if (this.openFeature[i] == "alo-") this.openFeature["alo-"] = false;
        if (this.openFeature[i] == "srpsu-") this.openFeature["srpsu-"] = false;
        if (this.openFeature[i] == "dalipsu-")
          this.openFeature["srpsu-"] = false;
        if (
          this.openFeature[i] == "luminfoformat1-" ||
          this.openFeature[i] == "luminfoformat3-" ||
          this.openFeature[i] == "luminfoformat0-"
        )
          this.openFeature["LUMINFO"] = false;
        if (this.openFeature[i] == "luminfoformat0-")
          this.openFeature["LUMINFO"] = false;
        if (this.openFeature[i] == "clo-") this.openFeature["clo-"] = false;
        if (this.openFeature[i] == "clolite-")
          this.openFeature["clolite-"] = false;
        if (this.openFeature[i] == "dtl-") this.openFeature["dtl-"] = false;
        if (this.openFeature[i] == "dimminginterface-")
          this.openFeature["dimminginterface-"] = false;
        if (
          this.openFeature[i] == "MIN DIM LEVEL" ||
          this.openFeature[i] == "mindimlevel-"
        ) {
          this.openFeature["mindimlevel-"] = false;
          //this.openFeature["mindimlevel-"] = false;
        }
        if (this.openFeature[i] == "mtp-") this.openFeature["mtp-"] = false;
        if (this.openFeature[i] == "ampdim-")
          this.openFeature["ampdim-"] = false;
        if (this.openFeature[i] == "corridor-")
          this.openFeature["corridor-"] = false;
        if (this.openFeature[i] === "codedmains-")
          this.openFeature["codedmains-"] = false;
        if (this.openFeature[i] === "lineswitch-")
          this.openFeature["lineswitch-"] = false;
        if (this.openFeature[i] == "ztv-") {
          this.openFeature["ztv-"] = false;
        }
        if (this.openFeature[i] == "ztvoutput-") {
          this.openFeature["ztvoutput-"] = false;
        }
        if (this.openFeature[i] == "luminaireinputpower-") {
          this.openFeature["luminaireinputpower-"] = false;
        }
        if (this.openFeature[i] == "rso-") {
          this.openFeature["rso-"] = false;
        }
        if (this.openFeature[i] == "lip-") {
          this.openFeature["lip-"] = false;
        }
        if (this.openFeature[i] == "ast-") {
          this.openFeature["ast-"] = false;
        }
        if (this.openFeature[i] == "dcemergency-") {
          this.openFeature["dcemergency-"] = false;
        }
        if (this.openFeature[i] == "touchdim-") {
          this.openFeature["touchdim-"] = false;
        }
        if (this.openFeature[i] == "diia253maintenance-") {
          this.openFeature["diia253maintenance-"] = false;
        }
        if (this.openFeature[i] == "owp-") {
          this.openFeature["owp-"] = false;
        }
        if (this.openFeature[i] == "dynadimmer-") {
          this.openFeature["dynadimmer-"] = false;
        }
        if (this.openFeature[i] == "flextune-") {
          this.openFeature["flextune-"] = false;
        }
      }
    }
  }
  singleArrowFun(feature) {
    let configurationData = JSON.parse(
      localStorage.getItem("configurationData")
    );
    if (this.openFeature[feature] === true) {
      this.openFeature[feature] = false;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
      this._dataLayerService.logUserDetails({
        UserId: localStorage.getItem("UserId"),
        companyId: decodedObj.company["companyId"],
        companyName: decodedObj.company["companyName"],
        userRole: decodedObj.role,
      });
      this._dataLayerService.resetcollapsedFeatures(feature);
      this._dataLayerService.setSelectedDeviceInfo({
        event: "collapseFeature",
        "12NC": configurationData.device12nc,
        features: [
          {
            name: feature,
            action: "collapse",
          },
        ],
      });
    } else {
      this.openFeature[feature] = true;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
      this._dataLayerService.logUserDetails({
        UserId: localStorage.getItem("UserId"),
        companyId: decodedObj.company["companyId"],
        companyName: decodedObj.company["companyName"],
        userRole: decodedObj.role,
      });
      this._dataLayerService.resetcollapsedFeatures(feature);
      this._dataLayerService.setSelectedDeviceInfo({
        event: "collapseFeature",
        "12NC": configurationData.device12nc,
        features: [
          {
            name: feature,
            action: "expanded",
          },
        ],
      });
    }
    this.doubleArrowVal = false;
    for (var value in this.openFeature) {
      if (this.openFeature[value] === true) {
        this.doubleArrowVal = true;
      }
    }
  }

  configOmitOnRefresh() {
    let obj = {};
    let omitObj = {};
    let quickConfigVal = JSON.parse(localStorage.getItem("quickConfiguration"));
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .pipe(takeUntil(this.prefencesUnsubscribe))
            .subscribe((response) => {
              if (response) {
                this.prefencesUnsubscribe.next();
                if (response["storeConfigMode"]) {
                  obj["storeConfigModeData"] = quickConfigVal;
                  obj["storeConfigModeCheck"] =
                    response["storeConfigMode"]["storeConfigModeCheck"];
                } else {
                  obj["storeConfigModeData"] = "standard";
                  obj["storeConfigModeCheck"] = false;
                }

                if (response["storeOmitOption"]) {
                  omitObj["storeDevicePopupValue"] =
                    response["storeOmitOption"]["storeDevicePopupValue"];
                  omitObj["storeDevicePopupCheck"] =
                    response["storeOmitOption"]["storeDevicePopupCheck"];
                } else {
                  omitObj["storeDevicePopupValue"] = "omit";
                  omitObj["storeDevicePopupCheck"] = false;
                }

                localStorage.setItem("storeConfigMode", JSON.stringify(obj));
                localStorage.setItem(
                  "storeOmitOption",
                  JSON.stringify(omitObj)
                );
                // this.apiService.postCallForUserPreferences();
              }
            });
        }
      });
  }

  restoreStartupDefault() {
    let data = JSON.parse(localStorage.getItem("saveSetData"));
    this.apiService.setFormInputValidation(false);
    this.apiService.sendStartupValidData("valid");
    this.startupDefaultColor = true;
    this.startupSchema.forEach((field, index) => {
      this.apiService.setStartupDefaultValue(
        field["properties"].AdjustableStartupTime.default
      );
      data["resp"]["Ast"] = {
        AdjustableStartupTime:
          field["properties"].AdjustableStartupTime.default,
      };
    });
    localStorage.setItem("saveSetData", JSON.stringify(data));
  }

  restoreDcemergencyDefault() {
    this.dcemergencyDefaultColor = true;
    this.apiService.sendDcemergencyDefaultData(true);
  }

  restoreTouchAndDimDefault() {
    this.touchDimDefaultColor = true;
    let ngModelval: any = [];
    var data = JSON.parse(localStorage.getItem("saveSetData"));
    this.touchDimSchema.forEach((field, index) => {
      if (
        field["properties"] &&
        field["properties"].TouchDimEnabled &&
        field["properties"].TouchDimEnabled.default
      ) {
        ngModelval["TouchDimEnabled"] =
          field["properties"].TouchDimEnabled.default;
      }
      if (
        field["properties"] &&
        field["properties"].IgnoreToggleLimits &&
        field["properties"].IgnoreToggleLimits.default
      ) {
        ngModelval["IgnoreToggleLimits"] =
          field["properties"].IgnoreToggleLimits.default;
      }
      if (
        field["properties"] &&
        field["properties"].PowerOnLevel &&
        field["properties"].PowerOnLevel.default
      ) {
        ngModelval["PowerOnLevel"] = field["properties"].PowerOnLevel.default;
      }
      ngModelval["LastKnownEnabled"] = true;
      this.apiService.sendTouchDimObj(ngModelval);
    });
    data["resp"]["TouchDim"] = ngModelval;
    localStorage.setItem("saveSetData", JSON.stringify(data));
    this.apiService.setTouchDimDefaultColor(this.touchDimDefaultColor);
    this.apiService.sendTouchDimDefaultData(true);
  }

  restoreAocDefault() {
    var data = JSON.parse(localStorage.getItem("saveSetData"));
    this.apiService.setFormInputValidation(false);
    this.aocDisableCreateFile = false;
    this.aocDefaultColor = true;
    this.schema.forEach((field, index) => {
      this.apiService.setAocDefaultValue(
        field["properties"].AocCurrent.default
      );
      data["resp"]["AOC"] = {
        AocCurrent: field["properties"].AocCurrent.default,
        AocEnabled: this.isHidden ? true : false,
      };
      this.aocQuickValue = field["properties"].AocCurrent.default;
      this.isAocEnabled = true;
      localStorage.setItem("saveSetData", JSON.stringify(data));
    });
    this.apiService.sendAOCValidData(false);
  }
  restoreztvoutputDefault() {
    this.ztvoutputDefaultColor = true;
    this.apiService.setztvoutputDefaultValue("valid");
    this.apiService.setztvoutputDefaultColor(this.ztvoutputDefaultColor);
  }
  restorelipDefault() {
    this.lipDefaultColor = true;
    this.apiService.setlipDefaultValue("valid");
    this.apiService.setlipDefaultColor(this.lipDefaultColor);
  }
  restorersoDefault() {
    this.rsoDefaultColor = true;
    this.apiService.setrsoDefaultValue("valid");
    this.apiService.setrsoDefaultColor(this.rsoDefaultColor);
  }
  restoreZtvDefault() {
    this.ztvDefaultColor = true;
    let ngModelval: any = [];
    this.isZtvError = false;
    let ztvData = JSON.parse(localStorage.getItem("saveSetData"));
    this.ztvSchema.forEach((field, index) => {
      if (
        field["properties"] &&
        field["properties"].ZtvCurveSelection &&
        field["properties"].ZtvCurveSelection.default
      ) {
        ngModelval["ZtvCurveSelection"] =
          field["properties"].ZtvCurveSelection.default;
      }
      if (
        field["properties"] &&
        field["properties"].ZtvMinDimLevel &&
        field["properties"].ZtvMinDimLevel.default
      ) {
        ngModelval["ZtvMinDimLevel"] =
          field["properties"].ZtvMinDimLevel.default;
      }
      if (
        field["properties"] &&
        field["properties"].ZtvEnabled &&
        (field["properties"].ZtvEnabled.default ||
          field["properties"].ZtvEnabled.const)
      ) {
        ngModelval["ZtvEnabled"] = field["properties"].ZtvEnabled.default
          ? field["properties"].ZtvEnabled.default
          : field["properties"].ZtvEnabled.const;
      }
      if (
        field["requiredProperties"].includes("FadeOffTime") &&
        field["properties"] &&
        field["properties"].FadeOffTime
      ) {
        ngModelval["FadeOffTime"] = field["properties"].FadeOffTime.default;
      }
      if (
        field["requiredProperties"].includes("FadeOnTime") &&
        field["properties"] &&
        field["properties"].FadeOnTime
      ) {
        if (field["properties"].FadeOnTime.default === 0) {
          ngModelval["FadeOnTime"] = 0;
        } else {
          ngModelval["FadeOnTime"] = field["properties"].FadeOnTime.default;
        }
      }
      if (
        field["requiredProperties"].includes("ZtvDimToOffEnabled") &&
        field["properties"] &&
        field["properties"].ZtvDimToOffEnabled
      ) {
        if (field["properties"].ZtvDimToOffEnabled.default === false) {
          ngModelval["ZtvDimToOffEnabled"] = false;
        } else {
          ngModelval["ZtvDimToOffEnabled"] =
            field["properties"].ZtvDimToOffEnabled.default;
        }
      }
      this.apiService.sendZtvObj(ngModelval);
    });
    ztvData["resp"]["Ztv"] = ngModelval;
    localStorage.setItem("saveSetData", JSON.stringify(ztvData));
    this.apiService.setZtvRestoreBtnClick("clicked");
    this.buttonDisable = false;
  }

  restoreSrpsuDefault() {
    this.srpsuDefaultColor = true;
    let srpsuDefaultValues: any = [];
    this.apiService.setSrpsuDefaultValue(srpsuDefaultValues);
  }
  restorediia253maintenanceDefault() {
    this.diia253maintenanceDefaultColor = true;
    this.apiService.setdiia253maintenanceDefaultValue("valid");
    this.apiService.setdiia253maintenanceDefaultColor(true);
  }
  restoreAloDefault() {
    let aloData = JSON.parse(localStorage.getItem("saveSetData1"));
    this.aloDefaultColor = true;
    let aloDefaultValues: any = [];
    this.aloEnableCreateFile = false;
    this.aloSchema.forEach((field, index) => {
      aloDefaultValues["AloPercentage"] =
        field["properties"].AloPercentage.default;
      if (field["properties"].AloMinLevel) {
        aloDefaultValues["AloMinLevel"] =
          field["properties"].AloMinLevel.default;
      }
      this.apiService.setAloDefaultValue(aloDefaultValues);
      this.quickAloPercentage = field["properties"].AloPercentage.default;
      this.AloPercentage = this.quickAloPercentage;
      if (field["properties"] && field["properties"].AloMinLevel) {
        this.quickAlomin = field["properties"].AloMinLevel.default;
        this.AloMinLevel = this.quickAlomin;
      }
      if (field["properties"] && field["properties"].AloMinLevel) {
        aloData["resp"]["ALO"] = {
          AloPercentage: field["properties"].AloPercentage.default,
          AloMinLevel: field["properties"].AloMinLevel.default,
          AloEnabled: field["properties"].AloEnabled.default,
        };
      } else {
        aloData["resp"]["ALO"] = {
          AloPercentage: field["properties"].AloPercentage.default,
          AloEnabled: field["properties"].AloEnabled.default,
        };
      }
    });
    localStorage.setItem("saveSetData1", JSON.stringify(aloData));
  }

  restoreCLODefault() {
    this.cloDefaultColor = true;
    this.apiService.setCloDefaultValue("valid");
    this.apiService.setCloDefaultColor(true);
  }

  restoreCLOLiteDefault() {
    this.cloLiteDefaultColor = true;
    this.apiService.setCloLiteDefaultValue("valid");
    this.apiService.setCloLiteDefaultColor(true);
  }
  restoreDynaDimmerDefault() {
    this.dynaDefaultColor = true;
    this.restoreDynaDimmer = true;
    this.apiService.setDynaDefaultValue("valid");
    this.apiService.setDynaDefaultColor(true);
  }
  restoreAmpDimDefault() {
    this.ampdimDefaultColor = true;
    this.apiService.setAmpDimDefaultValue("valid");
    this.apiService.setAmpDimDefaultColor(true);
  }
  restoreDtlDefault() {
    this.dtlDefaultColor = true;
    this.restoreDtl = true;
    this.apiService.setDtlDefaultColor(true);
  }
  restoreFlextuneDefault() {
    this.flextuneDefaultColor = true;
    this.apiService.setFlextuneDefaultColor(true);
    this.apiService.restoreFlextuneDefault.next(true);
  }
  restoreMinDimLevelDefault() {
    this.minDimLevelDefaultColor = true;
    this.apiService.setMinDimLevelDefaultColor(this.minDimLevelDefaultColor);
  }
  restoreCorridorDefault() {
    this.corrdiorDefaultColor = true;
    this.apiService.setCorridorDefaultColor(this.corrdiorDefaultColor);
  }
  restoreMtpDefault() {
    this.mtpDefaultColor = true;
    this.apiService.setMtpDefaultValue("valid");
    this.apiService.setMtpDefaultColor(this.mtpDefaultColor);
  }
  restoreLineSwitchDefault() {
    this.lineSwitchDefaultColor = true;
    this.apiService.setLineSwitchDefaultValue("valid");
    this.apiService.setLineSwitchDefaultColor(this.lineSwitchDefaultColor);
  }
  restorecodedmainsDefault() {
    this.codedmainsDefaultColor = true;
    this.apiService.setcodedmainsDefaultValue("valid");
    this.apiService.setcodedmainsDefaultColor(this.codedmainsDefaultColor);
  }
  restoreOwpDefault() {
    this.owpDefaultColor = true;
    this.apiService.setowpDefaultValue("valid");
    this.apiService.setowpDefaultColor(true);
    this.apiService.setOwpRestoreDefaultValue(true);
  }
  restoreLuminDefault() {
    this.luminDefaultColor = true;
    this.removeItemFromLocalStorage("LumInfoFormat");
    this.lmuSchema.forEach((field, index) => {
      this.apiService.setLuminDefaultValue(field["properties"]);
    });
  }

  removeItemFromLocalStorage(item: string) {
    let updatedConfigurationData = {};
    updatedConfigurationData = JSON.parse(
      localStorage.getItem("configurationData")
    );
    delete updatedConfigurationData["resp"][item];
    localStorage.setItem(
      "configurationData",
      JSON.stringify(updatedConfigurationData)
    );
  }

  restoreDimmingDefault() {
    this.apiService.restoingDefault(true);
  }

  restoreMulticonfigDefault(){
    this.apiService.restoreMulticonfigDefault(true);
  }

  ngOnDestroy() {
    this.isZTVFeatureAvailable = false;
    this.configureDeviceClicked = false;
    this.saveFeatureFileClicked = false;
    this.restoreDtl = false;
    if (this.subscriptionService) {
      this.subscriptionService.unsubscribe();
    }
    if (this.getDynaDefaultColorSub) {
      this.getDynaDefaultColorSub.unsubscribe();
    }
    if (this.getDimmingInterfaceRestoredSub) {
      this.getDimmingInterfaceRestoredSub.unsubscribe();
    }
    if (this.getLineSwitchEdtedDataSub) {
      this.getLineSwitchEdtedDataSub.unsubscribe();
    }
    if (this.getDynaDimmerEnabledModeSub) {
      this.getDynaDimmerEnabledModeSub.unsubscribe();
    }
    if (this.getdiia253maintenanceFeatApiSub) {
      this.getdiia253maintenanceFeatApiSub.unsubscribe();
    }
    this.apiService.dontReload("");
    localStorage.removeItem("setmode");
    this.checkEnableGraphUnSub.next();
    this.checkCloScehma.next();
    this.apiService.sendAOCValidData("");
    this.getGraphDataUnSub.next();
    this.unsubscribe.next();
    this.checkEnableRsetUnSub.next();
    this.apiService.setGraphRepresentation("");
    this.apiService.setLuminFormInputValidation("");
    this.apiService.sendCloLiteText("");

    this.apiService.sendStartupValidData("");
    this.apiService.aloSetErrorButton("");
    this.apiService.setFormInputValidation("");
    this.apiService.setFormInputValidation("");
    this.apiService.ztvInputError("");
    this.apiService.aocValueChange("");
    this.apiService.setTouchDimEditedData("");
    this.apiService.sendQuickConfig("");
    this.apiService.setAloData("");
    this.apiService.quickAloPercentConfigurationData("");
    this.litepower = 0;
    this.liteHour = 0;
    this.apiService.sendliteHour("");
    this.apiService.sendLitePower("");
    this.apiService.setEnableGraph("");

    //DYNA
    this.apiService.setEnableDynaGraph("");
    this.apiService.setDynaDefaultValue("invalid");

    this.apiService.sendDynaDimmerEnabledMode(false);
    this.apiService.sendDynaFadeTimess("");
    this.apiService.sendDynaTimeHours("");
    this.apiService.sendDynaOutputLevel("");
    this.apiService.sendDynaMidPointShiftMode("");
    this.apiService.sendDynaTimeReferenceMode("");
    this.apiService.sendDynaOveerideMode("");
    this.apiService.sendDynaScene1("");
    this.apiService.sendDynaScene2("");
    this.apiService.sendDynaScene3("");
    this.apiService.sendDynaScene4("");
    this.apiService.sendDynaScene5("");
    //DYNA Default END
    this.apiService.setLuminValue("");
    this.apiService.setSrpsuDefaultValue("");
    this.apiService.setSrpsuDefaultColor("");
    this.apiService.sentPSUEnable("");
    this.apiService.aloPercentConfigurationData("");
    this.apiService.setDcemergencyDefaultColor(true);
    this.apiService.setAloDefaultValue("");
    this.apiService.sendDimmingRestoreDefault("");
    this.apiService.sendDimmingDefault("");
    this.apiService.sendDimmingInterfacedata("");
    this.apiService.sendDimmingCheckValidation("");
    this.apiService.sentdirectlyFeature("");
    this.apiService.setCloDefaultValue("invalid");
    this.apiService.setLineSwitchDefaultValue("invalid");
    this.apiService.setMtpDefaultValue("invalid");
    this.apiService.setCloLiteDefaultValue("");
    if (this.enableSub) {
      this.enableSub.unsubscribe();
    }
    if (this.enableGraphSub) {
      this.enableGraphSub.unsubscribe();
    }
    if (this.cloGraphSub) {
      this.cloGraphSub.unsubscribe();
    }
    if (this.statusObs$) {
      this.statusObs$.unsubscribe();
    }
  }

  // Change device configure modal popup
  newDevice() {
    if (
      this.saveFeatureFileClicked === true ||
      this.configureDeviceClicked === true
    ) {
      return;
    }

    let oldConfig = JSON.parse(localStorage.getItem("configurationData"));
    localStorage.setItem("oldConfigurationData", JSON.stringify(oldConfig));
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    this.apiService.modalConfigurationData("searchNewDevice");
    const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
  }

  configOrFileWithOwpPassword(configOrFile, multideviceState?) {
    if (this.OEMSelected) {
      const modalRef = this.modalService.open(OwpPasswordComponent, {
        keyboard: false,
        centered: true,
        windowClass: "owp-popup-style",
        size: "xs",
      });

      modalRef.componentInstance.sendOwpPwd.subscribe((pwd) => {
        var values = Object.values(pwd);
        this.apiService.sendOwpAccessPwd(values);
        if (configOrFile === "file") {
          this.generateFile();
        } else if (configOrFile === "device") {
          this.writingDevice(multideviceState);
        }
      });
    } else {
      this.apiService.sendOwpAccessPwd("");
      if (configOrFile === "file") {
        this.generateFile();
      } else if (configOrFile === "device") {
        this.writingDevice(multideviceState);
      }
    }
  }

  loader = 0;
  increamentLoader(){
    this.loader+=1;
  }

  decreamentLoader(){
    this.loader-=1;
  }

  showloader(){
    return this.loader>0;
  }

  writingDevice(multideviceState) {
    this.increamentLoader();
    this.updateConfigurationDataByFeatureSelected();
    if (multideviceState === "multidevice") {
      let configData = JSON.parse(localStorage.getItem("configurationData"));
      localStorage.setItem('multideviceSelectedFeatures', configData.selectedFeaturesInfo ? JSON.stringify(configData.selectedFeaturesInfo) : JSON.stringify([]));
      localStorage.setItem("multidevice", JSON.stringify(true));
    } else {
      localStorage.setItem("multidevice", JSON.stringify(false));
    }
    if (
      this.configureDeviceClicked === false &&
      this.saveFeatureFileClicked === false
    ) {
      this.configureDeviceClicked = true;
      localStorage.setItem("normalFlow", JSON.stringify(true));
      localStorage.setItem("isWriting", JSON.stringify(true));

      // Removing dimming features and dali253m for release 1.2.3 start
      let initialConfigData = JSON.parse(
        localStorage.getItem("configurationData")
      );
      let selectedFeaturesList = initialConfigData["selectedFeaturesInfo"];
      let removeFeatures = [
        // "dynadimmer-",
        // "lineswitch-",
        // "diia253maintenance-",
        // "codedmains-",
        // "ampdim-",
        // "dimminginterface-",
      ];
      let featureListResult = selectedFeaturesList.filter(
        (x) => !removeFeatures.includes(x)
      );
      initialConfigData["selectedFeaturesInfo"] = featureListResult;
      localStorage.setItem(
        "configurationData",
        JSON.stringify(initialConfigData)
      );
      // End

      let configuration = JSON.parse(localStorage.getItem("configurationData"));

      let dataSet = [];
      for (var i = 0; i < configuration["selectedFeaturesInfo"].length; i++) {
        dataSet.push({
          name: configuration["selectedFeaturesInfo"][i].slice(0, -1),
          action: "included",
        });
      }
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
      this._dataLayerService.logUserDetails({
        UserId: localStorage.getItem("UserId"),
        companyId: decodedObj.company["companyId"],
        companyName: decodedObj.company["companyName"],
        userRole: decodedObj.role,
      });
      this._dataLayerService.setSelectedDeviceInfo({
        event: "saving",
        savingType: "Configure Device",
        "12NC": configuration.device12nc,
        features: dataSet,
      });
      this.newConfigurationIDfWriting();
      setTimeout(() => {
        this.configureDeviceClicked = false;
      }, 10000);
    }
  }

  newConfigurationIDfWriting() {
    this.loading = true;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.startConfigURL = url;
          this.softwareVersion = JSON.parse(
            localStorage.getItem("comparefirmwarenoLatest")
          );
          if (this.softwareVersion.length > 3) {
            this.softwareVersion = this.softwareVersion.slice(0, -2);
          }
          // till demo purpose
          let mockDevice = {};
          // if(this.receivedFeature.deviceName === 'XH075C120V075KPF1') {
          //   mockDevice = {
          //     "device12nc": this.receivedFeature.device12nc,
          //     "softwareVersion": this.softwareVersion
          //   }
          // } else {
          mockDevice = {
            device12nc: this.receivedFeature.device12nc,
            softwareVersion: this.softwareVersion,
          };
          //}

          /////////// New config ID chnages
          if (localStorage.getItem("configurationData")) {
            var dataFromConfig1 = JSON.parse(
              localStorage.getItem("configurationData")
            );
          }
          let selectedFeateForDCConfig = dataFromConfig1.selectedFeaturesInfo;
          this.newConfigIddata = [];
          if (selectedFeateForDCConfig.length >= 1) {
            selectedFeateForDCConfig.forEach(() => {
              this.apiService
                .getStartConfiguration(mockDevice, this.startConfigURL)
                .subscribe((newConfigID) => {
                  this.newConfigIddata.push(newConfigID["configurationID"]);
                  this.startConfigurationData["configurationIDUniqueDC"] =
                    this.newConfigIddata;
                });
            });
            /*  for (
              let index = 0;
              index <= selectedFeateForDCConfig.length + 1;
              index++
            ) {
              const element = selectedFeateForDCConfig[index];
              this.apiService
                .getStartConfiguration(mockDevice, this.startConfigURL)
                .subscribe((newConfigID) => {
                  this.newConfigIddata.push(newConfigID["configurationID"]);
                });
            } */

            /* this.startConfigurationData["configurationIDUniqueDC"] =
              this.newConfigIddata; */
          }

          ///////////// New config ID chnages
          setTimeout(() => {
            this.apiService
              .getStartConfiguration(mockDevice, this.startConfigURL)
              .subscribe(
                (data) => {
                  localStorage.removeItem("deviceVersion");
                  var configurationID;
                  configurationID = data["configurationID"];
                  if (localStorage.getItem("configurationData")) {
                    var dataFromConfig = JSON.parse(
                      localStorage.getItem("configurationData")
                    );

                    this.receivedFeature = {
                      deviceName: dataFromConfig.deviceName,
                      device12nc: dataFromConfig.device12nc,
                      softwareVerSchema: dataFromConfig.softwareVerSchema,
                      picture: dataFromConfig.picture,
                      luminSoftVer: dataFromConfig.luminSoftVer,
                      dtlSoftVer: dataFromConfig.dtlSoftVer,
                      flextuneSoftVer: dataFromConfig.flextuneSoftVer,
                      multiconfigSoftVer: dataFromConfig.multiconfigSoftVer,
                      mindimlevelSoftVer: dataFromConfig.mindimlevelSoftVer,
                      dalipsuSoftVer: dataFromConfig.dalipsuSoftVer,
                      selectedFeaturesInfo: dataFromConfig.selectedFeaturesInfo,
                      feature12Nc: dataFromConfig.feature12Nc,
                      ztvSoftVer: dataFromConfig.ztvSoftVer,
                      ztvoutputSoftVer: dataFromConfig.ztvoutputSoftVer,
                      lipSoftVer: dataFromConfig.lipSoftVer,
                      rsoSoftVer: dataFromConfig.rsoSoftVer,
                      aocSoftVer: dataFromConfig.aocSoftVer,
                      touchdimSoftVer: dataFromConfig.touchdimSoftVer,
                      diia253maintenanceSoftVer:
                        dataFromConfig.diia253maintenanceSoftVer,
                      corridorSoftVer: dataFromConfig.corridorSoftVer,
                      astSoftVer: dataFromConfig.astSoftVer,
                      cloliteSoftVer: dataFromConfig.cloliteSoftVer,
                      cloSoftVer: dataFromConfig.cloSoftVer,
                      dynadimmerSoftVer: dataFromConfig.dynadimmerSoftVer,
                      dcemergencySoftVer: dataFromConfig.dcemergencySoftVer,
                      lineswitchSoftVer: dataFromConfig.lineswitchSoftVer,
                      owpSoftVer: dataFromConfig.owpSoftVer,
                      codedmainsSoftVer: dataFromConfig.codedmainsSoftVer,
                      mtpSoftVer: dataFromConfig.mtpSoftVer,
                      dimminginterfaceSoftVer:
                        dataFromConfig.dimminginterfaceSoftVer,
                      ampdimSoftVer: dataFromConfig.ampdimSoftVer,
                    };
                    this.softwareVersion = dataFromConfig.softwareVersion;
                  }
                  this.startConfigurationData["configurationID"] =
                    configurationID;
                  this.receivedFeature.configurationID = configurationID;
                  this.startConfigurationData["deviceName"] =
                    this.receivedFeature.deviceName;
                  this.startConfigurationData["picture"] =
                    this.receivedFeature.picture;
                  this.startConfigurationData["device12nc"] =
                    this.receivedFeature.device12nc;
                  this.startConfigurationData["softwareVersion"] =
                    this.softwareVersion;
                  this.startConfigurationData["softwareVerSchema"] =
                    this.receivedFeature.softwareVerSchema;
                  this.startConfigurationData["selectedFeaturesInfo"] =
                    this.receivedFeature.selectedFeaturesInfo;
                  this.startConfigurationData["feature12Nc"] =
                    this.receivedFeature.feature12Nc;
                  this.startConfigurationData["luminSoftVer"] =
                    this.receivedFeature.luminSoftVer;
                  this.startConfigurationData["mtpSoftVer"] =
                    this.receivedFeature.mtpSoftVer;
                  this.startConfigurationData["dimminginterfaceSoftVer"] =
                    this.receivedFeature.dimminginterfaceSoftVer;
                  this.startConfigurationData["ampdimSoftVer"] =
                    this.receivedFeature.ampdimSoftVer;
                  this.startConfigurationData["dtlSoftVer"] =
                    this.receivedFeature.dtlSoftVer;
                  this.startConfigurationData["flextuneSoftVer"] =
                    this.receivedFeature.flextuneSoftVer;
                  this.startConfigurationData["multiconfigSoftVer"] = this.receivedFeature.multiconfigSoftVer;
                  this.startConfigurationData["mindimlevelSoftVer"] =
                    this.receivedFeature.mindimlevelSoftVer;
                  this.startConfigurationData["dalipsuSoftVer"] =
                    this.receivedFeature.dalipsuSoftVer;
                  this.startConfigurationData["ztvSoftVer"] =
                    this.receivedFeature.ztvSoftVer;
                  this.startConfigurationData["ztvoutputSoftVer"] =
                    this.receivedFeature.ztvoutputSoftVer;
                  this.startConfigurationData["lipSoftVer"] =
                    this.receivedFeature.lipSoftVer;
                  this.startConfigurationData["rsoSoftVer"] =
                    this.receivedFeature.rsoSoftVer;
                  this.startConfigurationData["aocSoftVer"] =
                    this.receivedFeature.aocSoftVer;
                  this.startConfigurationData["touchdimSoftVer"] =
                    this.receivedFeature.touchdimSoftVer;
                  this.startConfigurationData["diia253maintenanceSoftVer"] =
                    this.receivedFeature.diia253maintenanceSoftVer;
                  this.startConfigurationData["corridorSoftVer"] =
                    this.receivedFeature.corridorSoftVer;
                  this.startConfigurationData["astSoftVer"] =
                    this.receivedFeature.astSoftVer;
                  this.startConfigurationData["cloliteSoftVer"] =
                    this.receivedFeature.cloliteSoftVer;
                  this.startConfigurationData["cloSoftVer"] =
                    this.receivedFeature.cloSoftVer;
                  this.startConfigurationData["dynadimmerSoftVer"] =
                    this.receivedFeature.dynadimmerSoftVer;
                  this.startConfigurationData["dcemergencySoftVer"] =
                    this.receivedFeature.dcemergencySoftVer;
                  this.startConfigurationData["lineswitchSoftVer"] =
                    this.receivedFeature.lineswitchSoftVer;
                  this.startConfigurationData["owpSoftVer"] =
                    this.receivedFeature.owpSoftVer;
                  this.startConfigurationData["codedmainsSoftVer"] =
                    this.receivedFeature.codedmainsSoftVer;
                  this.receivedFeature.device12nc =
                    this.receivedFeature.device12nc;
                  this.startConfigurationData["configurationIDUniqueDC"] =
                    this.newConfigIddata;
                  localStorage.setItem(
                    "configurationData",
                    JSON.stringify(this.startConfigurationData)
                  );
                  localStorage.setItem(
                    "readerConfigurationData",
                    JSON.stringify(this.startConfigurationData)
                  );
                  this.apiService.setConfigurationData(
                    this.startConfigurationData
                  );
                  this.setFeatureConfiguration(this.startConfigurationData);
                },
                (err) => {
                  this.decreamentLoader();
                  this.loading = false;
                  const modalRef = this.modalService.open(ErrorComponent, {
                    keyboard: false,
                    centered: true,
                    windowClass: "error-style, error-style1",
                  });
                  modalRef.componentInstance.errorName = err["message"];
                  modalRef.componentInstance.errorDescription =
                    err["description"];
                }
              );
            
          }, 1000);
        }
      });
  }

  setFeatureConfiguration(configuration) {
    let tempFeatureListSelected: string[] = [];
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          let count = 0;
          let settingObj = [];
          let unsettingObj = [];
          this.unsubscribe.next();
          this.envSetConfigUrl = url + this.setConfigUrl;
          configuration["selectedFeaturesInfo"].forEach((element) => {
            if (
              element === "ztv-" ||
              element === "srpsu-" ||
              element === "luminfoformat0-" ||
              element === "luminfoformat3-" ||
              element === "alo-" ||
              element === "aoc-" ||
              element === "clo-" ||
              element === "clolite-" ||
              element === "ast-" ||
              element === "dynadimmer-" ||
              element === "lineswitch-" ||
              element === "diia253maintenance-" ||
              element === "owp-" ||
              element === "codedmains-" ||
              element === "ampdim-" ||
              element === "dimminginterface-" ||
              element === "dcemergency-" ||
              element === "dtl-" ||
              element === "Min Dim Level" ||
              element === "mindimlevel-" ||
              element === "touchdim-" ||
              element === "corridor-" ||
              element === "Touch and Dim" ||
              element === "Min dim level" ||
              element === "mtp-" ||
              element === "ztvoutput-" ||
              element === "lip-" ||
              element === "rso-" ||
              element === "flextune-" ||
              element === "multiconfigmode-"
            ) {
              if (this.verifySelectedFeatureCount(element.toString())) {
                count++;
              } else tempFeatureListSelected.push(element);
            }
          });

          if (tempFeatureListSelected.length > 0) {
            tempFeatureListSelected.forEach((feat) => {
              let index = configuration["selectedFeaturesInfo"].indexOf(feat);
              configuration["selectedFeaturesInfo"].splice(index, 1);
            });
          }
          localStorage.setItem(
            "configurationData",
            JSON.stringify(configuration)
          );
          localStorage.setItem(
            "readerConfigurationData",
            JSON.stringify(configuration)
          );
          configuration["selectedFeaturesInfo"].forEach((element) => {
            settingObj = this.createObjParamsForFeatures(
              element,
              settingObj,
              true
            );
          });
          // let settingObjPreserve = [];
          // configuration["feature12Nc"].forEach((element) => {
          //   settingObjPreserve = this.createObjParamsForFeatures(
          //     element,
          //     settingObjPreserve,
          //     false
          //   );
          // });
          let unslectedEditedData: any[] = [];
          let unselectedFeatureList: any[] =
            this.getUnselectedFeatureList(configuration);

          if (
            configuration["selectedFeaturesInfo"].includes("srpsu-") &&
            unselectedFeatureList.includes("dalipsu-")
          ) {
            unselectedFeatureList.splice(
              unselectedFeatureList.indexOf("dalipsu-"),
              1
            );
          }
          unselectedFeatureList.forEach((unselectedFeature) => {
            unslectedEditedData = this.createObjParamsForFeatures(
              unselectedFeature,
              unsettingObj,
              false
            );
          });

          let featureListUnSelect = {
            FeatureListExcluded: unselectedFeatureList,
          };
          localStorage.setItem(
            "ExcludedDCFeat",
            JSON.stringify(featureListUnSelect)
          );
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unslectedEditedData)
          );
          if (JSON.parse(localStorage.getItem("multidevice")) === true) {
            settingObj.forEach((selectedFeatures) => {
              unslectedEditedData.push(selectedFeatures);
            });
            localStorage.removeItem("UnSelectedFeatConfig");
            localStorage.setItem(
              "UnSelectedFeatConfig",
              JSON.stringify(unslectedEditedData)
            );
          }

          if (settingObj.length > 0) {
            this.apiService
              .setAllfeatureAPI(url, configuration.configurationID, settingObj)
              .subscribe((data) => {
                this.decreamentLoader();
                this.callWritingCheck(count);
              },
            (err)=>{
              this.decreamentLoader();
              this.configureDeviceClicked = false;
                        this.loading = false;
                        this.customLoader = false;
                        this.loading = false;
                        const modalRef = this.modalService.open(
                          ErrorComponent,
                          {
                            keyboard: false,
                            centered: true,
                            windowClass: "error-style, error-style1",
                          }
                        );
                        modalRef.componentInstance.errorName = err["message"];
                        modalRef.componentInstance.errorDescription =
                          err["description"];
                        modalRef.result.then((result)=>{
                          if(result===true){
                            window.location.reload();
                          }
                        },(reason)=>{
                          window.location.reload();
                      })
                        
            });
          }
        }
      
      });
  }
  createObjParamsForFeatures(
    element: any,
    settingObj: any[],
    selectedCheck: boolean
  ) {
    if (element === "dalipsu-") element = "srpsu-";
    //dimming interface start here
    if (
      (this.dimmingInterfaceSelected || selectedCheck === false) &&
      element === "dimminginterface-"
    ) {
      this.dimmingInterfaceFeature = {
        featureName: "DimmingInterface",
        featureParameters: [this.dimmingInterfaceObj],
      };
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.dimmingInterfaceFeature);
    }
    //dimming interface End here

    //FLEXTUNE start here 
    if (element === "flextune-") {
      if ((this.flextuneSelected && !this.isFlextuneQuickDisable) || selectedCheck === false) {
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push({
          featureName: 'FlexTune',
          featureParameters: [this.flextuneData]
        })
      }
    }
    //FLEXTUNE end here

    //multiconfig mode start here
    if (
      (this.multiconfigSelected || selectedCheck === false) &&
      element === "multiconfigmode-"
    ) {
      
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push({
        featureName: 'MultiConfigMode',
        featureParameters: [this.multiconfigData]
      });
    }
    //multiconfig mode ended here
    if (element === "dynadimmer-") {
      if (
        (this.dynaDimmerSelected && this.isdynaDimmerQuickDisable) ||
        selectedCheck === false
      ) {
        var dynaConfig = JSON.parse(localStorage.getItem("dynaSaveSetData"));
        if (
          (this.dynaDimmerSelected && this.isdynaDimmerQuickDisable) ||
          selectedCheck === false
        ) {
          this.dynaDimmerDSFeature = {
            featureName: "Dynadimmer",
            featureParameters: dynaConfig,
          };
        }
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.dynaDimmerDSFeature);
      }
    }
    if (element === "lineswitch-") {
      if (
        (this.lineSwitchSelected && this.isLineSwitchDisable) ||
        selectedCheck === false
      ) {
        if (this.LineSwitchSchema[0].featureVersion === 4) {
          this.lineswitchFeature = {
            featureName: "LineSwitch",
            featureParameters: [
              {
                HighVLevel: this.lineSwitchFeatureObj["HighVLevelValue"],
                LowVLevel:
                  this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                FadeUpTime:
                  this.lineSwitchFeatureObj["lineswitchFadeUpTimeValue"],
                FadeDownTime:
                  this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                SwitchOffDelayTime:
                  this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
                "2ndInactiveLevelEnabled":
                  this.lineSwitchFeatureObj["2ndInactiveLevelEnabled"],
                "2ndInactiveDimLevel":
                  this.lineSwitchFeatureObj[
                    "lineswitch2ndInactiveDimLevelValue"
                  ],
                "2ndInactiveLevelDelayTime":
                  this.lineSwitchFeatureObj[
                    "lineswitch2ndInactiveLevelDelayTimeValue"
                  ],
              },
            ],
          };
        } else {
          this.lineswitchFeature = {
            featureName: "LineSwitch",
            featureParameters: [
              {
                HighVLevel: this.lineSwitchFeatureObj["HighVLevelValue"],
                LineSwitchEnabled:
                  this.lineSwitchFeatureObj["isLineSwitchEnabled"],
                LowVLevel:
                  this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                FadeUpTime:
                  this.lineSwitchFeatureObj["lineswitchFadeUpTimeValue"],
                FadeDownTime:
                  this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                SwitchOffDelayTime:
                  this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
              },
            ],
          };
        }
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.lineswitchFeature);
      }
    }
    //diia253maintenance start here
    if (element === "diia253maintenance-") {
      if (
        (this.diia253maintenanceSelected &&
          this.isdiia253maintenanceQuickDisable) ||
        selectedCheck === false
      ) {
        let RatedLife =
          this.diia253maintenanceFeatureParametersObj["RatedLife"] === "" ||
          this.diia253maintenanceFeatureParametersObj["RatedLife"] === undefined
            ? 255
            : parseInt(
                this.diia253maintenanceFeatureParametersObj["RatedLife"]
              );
        let ReferenceTemperature =
          this.diia253maintenanceFeatureParametersObj[
            "ReferenceTemperature"
          ] === "" ||
          this.diia253maintenanceFeatureParametersObj[
            "ReferenceTemperature"
          ] === undefined
            ? 195
            : parseInt(
                this.diia253maintenanceFeatureParametersObj[
                  "ReferenceTemperature"
                ]
              );
        let RatedStarts =
          this.diia253maintenanceFeatureParametersObj["RatedStarts"] === "" ||
          this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
            undefined
            ? 65535
            : parseInt(
                this.diia253maintenanceFeatureParametersObj["RatedStarts"]
              );
        this.diia253maintenanceFeature = {
          featureName: "Diia253Maintenance",
          featureParameters: [
            {
              RatedLife: RatedLife,
              ReferenceTemperature: ReferenceTemperature,
              RatedStarts: RatedStarts,
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.diia253maintenanceFeature);
      }
    }
    //diia253maintenance End here
    //owp start here
    if (element === "owp-") {
      if ((this.owpSelected && this.isOwpDisable) || selectedCheck === false) {
        var res = this.owpFeatureParametersObj["OwpEnabled"];
        var result = res.map((item) => {
          if(item.toString().toUpperCase()==="STEPDIMMING")
            return "StepDimming"
          else
          return item.replace(/\s+/g, "").toUpperCase();
        });
        var exist = this.owpFeatureParametersObj.hasOwnProperty("OwpPassword");
        this.owpFeature = {
          featureName: "owp",
          featureParameters: [
            {
              OwpPassword: exist
                ? this.owpFeatureParametersObj["OwpPassword"]
                : [],
              OwpEnabled: result,
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.owpFeature);
      }
    }
    //owp end here
    
    

    //TouchDim start here
    if (element === "touchdim-") {
      if (
        (this.touchDimSelected && this.isTouchDimQuickDisable) ||
        selectedCheck === false
      ) {
        let PowerOnLevel;
        if (this.touchDimFeatureParametersObj["LastKnownEnabled"]) {
          PowerOnLevel = 255;
        } else {
          PowerOnLevel = parseInt(
            this.touchDimFeatureParametersObj["PowerOnLevel"]
          );
        }
        this.touchDimFeature = {
          featureName: "TouchDim",
          featureParameters: [
            {
              PowerOnLevel: PowerOnLevel,
              TouchDimEnabled:
                this.touchDimFeatureParametersObj["TouchDimEnabled"],
              IgnoreToggleLimits:
                this.touchDimFeatureParametersObj["IgnoreToggleLimits"],
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.touchDimFeature);
      }
    }
    //TouchDim End here
    //codedmains starts here
    if (element === "codedmains-") {
      if (
        (this.codedmainsSelected && this.iscodedmainsDisable) ||
        selectedCheck === false
      ) {
        let fallbackSceneVal;
        if (this.codedmainsFeatureObj["ischeckboxChecked"]) {
          fallbackSceneVal = 255;
        } else {
          fallbackSceneVal = this.codedmainsFeatureObj["FallbackScene"];
        }
        this.codedmainsFeature = {
          featureName: "CodedMains",
          featureParameters: [
            {
              FadeTime: this.codedmainsFeatureObj["FadeTime"],
              FastFadeTime: this.codedmainsFeatureObj["FastFadeTime"],
              FallbackDetectionTime:
                this.codedmainsFeatureObj["FallbackDetectionTime"],
              PowerOnLevel: this.codedmainsFeatureObj["PowerOnLevel"],
              FallbackScene: fallbackSceneVal,
              Scene1: this.codedmainsFeatureObj["Scene1"],
              Scene2: this.codedmainsFeatureObj["Scene2"],
              Scene3: this.codedmainsFeatureObj["Scene3"],
              Scene4: this.codedmainsFeatureObj["Scene4"],
              Scene5: this.codedmainsFeatureObj["Scene5"],
              Scene6: this.codedmainsFeatureObj["Scene6"],
              Scene7: this.codedmainsFeatureObj["Scene7"],
              Scene8: this.codedmainsFeatureObj["Scene8"],
              Scene9: this.codedmainsFeatureObj["Scene9"],
              Scene10: this.codedmainsFeatureObj["Scene10"],
              Scene11: this.codedmainsFeatureObj["Scene11"],
              Scene12: this.codedmainsFeatureObj["Scene12"],
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.codedmainsFeature);
      }
    }
    //codedmains ends here
    //mtp start here
    if (element === "mtp-") {
      if (
        (this.mtpSelected && this.isMtpQuickDisable) ||
        selectedCheck === false
      ) {
        this.mtpFeature = {
          featureName: "MTP",
          featureParameters: [
            {
              DimLevel: this.mtpFeatureObj["DimLevelValue"],
              DimStart: this.mtpFeatureObj["DimStartValue"],
              DimStop: this.mtpFeatureObj["DimStopValue"],
              Enabled: this.mtpFeatureObj["Enabled"],
              NtcBeta: this.mtpFeatureObj["NtcBeta"],
              NtcResistance: this.mtpFeatureObj["NtcResistance"],
              SelectedNtc: this.mtpFeatureObj["SelectedNtc"],
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.mtpFeature);
      }
    }
    if (element === "ztvoutput-") {
      if (
        (this.isztvoutputQuickDisable && this.ztvoutputSelected) ||
        selectedCheck === false
      ) {
        if (this.ztvoutputSchema) {
          const data = JSON.parse(localStorage.getItem("saveSetData"));
        }
        let ztvObj = Object.assign({}, this.ztvoutputObj);
        this.ztvoutputFeature = {
          featureName: "ZtvOutput",
          featureParameters:
            this.ztvoutputObj["CurveSelection"] === "User specified"
              ? [ztvObj]
              : [{ CurveSelection: this.ztvoutputObj["CurveSelection"] }],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.ztvoutputFeature);
      }
    }
    if (element === "rso-") {
      if (
        (this.isrsoQuickDisable && this.rsoSelected) ||
        selectedCheck === false
      ) {
        if (this.rsoSchema) {
          const data = JSON.parse(localStorage.getItem("saveSetData"));
        }
        this.rsoObj["Enabled"] = this.rsoObj["Enabled"] === "1" ? true : false;

        this.rsoObj = Object.assign({}, this.rsoObj);
        this.rsoFeature = {
          featureName: "rso",
          featureParameters: [this.rsoObj],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.rsoFeature);
      }
    }
    if (element === "lip-") {
      if (
        (this.islipQuickDisable && this.lipSelected) ||
        selectedCheck === false
      ) {
        if (
          this.lipObj &&
          !isNullOrUndefined(this.lipObj["Options"]) &&
          this.lipObj["Options"] === `Input power at 100% and dim`
        ) {
          if (this.lipObj && !isNullOrUndefined(this.lipObj["DimLevel"])) {
            this.lipObj["DimLevel"] = parseInt(this.lipObj["DimLevel"]);
          }
          if (this.lipObj && !isNullOrUndefined(this.lipObj["InputPowerDim"])) {
            this.lipObj["InputPowerDim"] = parseFloat(
              this.lipObj["InputPowerDim"]
            );
          }
          localStorage.setItem("InputPower100", this.lipObj["InputPower100"]);
          localStorage.setItem("InputPowerDim", this.lipObj["InputPowerDim"]);
          localStorage.setItem("DimLevel", this.lipObj["DimLevel"]);
          this.lipObj["InputPower100"] = parseFloat(
            this.lipObj["InputPower1001"]
          );
          delete this.lipObj["InputPower1001"];
        } else {
          localStorage.setItem("InputPower1001", this.lipObj["InputPower1001"]);
          this.lipObj["InputPower100"] = parseFloat(
            this.lipObj["InputPower100"]
          );
          localStorage.setItem("InputPower100", this.lipObj["InputPower100"]);
          localStorage.setItem("InputPowerDim", this.lipObj["InputPowerDim"]);
          localStorage.setItem("DimLevel", this.lipObj["DimLevel"]);
          delete this.lipObj["InputPower1001"];
          delete this.lipObj["DimLevel"];
          delete this.lipObj["InputPowerDim"];
        }
        this.lipObj = Object.assign({}, this.lipObj);
        this.lipFeature = {
          featureName: "lip",
          featureParameters: [this.lipObj],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.lipFeature);
      }
    }
    //mtp end here
    //amp dim start here
    if (
      ((this.ampDimSelected && this.isAmpDimQuickDisable) ||
        selectedCheck === false) &&
      element === "ampdim-"
    ) {
      this.ampDimFeature = {
        featureName: "AmpDim",
        featureParameters: [
          {
            VmainsStart: this.ampDimFeatureObj["VmainsStartValue"],
            VmainsStop: this.ampDimFeatureObj["VmainsStopValue"],
            StartDimLevel: this.ampDimFeatureObj["StartDimLevelValue"],
            StopDimLevel: this.ampDimFeatureObj["StopDimLevelValue"],
          },
        ],
      };
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.ampDimFeature);
    }
    //amp dim end here
    else if (
      ((this.dcemergencySelected && this.isDcemergencyQuickDisable) ||
        selectedCheck === false) &&
      element === "dcemergency-"
    ) {
      if (this.dcemergencyFeatureParametersObj !== undefined) {
        //this.dcemergencyFeatureParametersObj['LevelInArcPower'] = true;

        this.dcemergencyFeature = {
          featureName: "DCEmergency",
          featureParameters: [
            {
              DcEmergencyLevel: parseInt(
                this.dcemergencyFeatureParametersObj["DcEmergencyLevel"]
              ),
              DcEmergencyEnabled:
                this.dcemergencyFeatureParametersObj["DcEmergencyEnabled"],
              DcEmergencyDimmingEnabled:
                this.dcemergencyFeatureParametersObj[
                  "DcEmergencyDimmingEnabled"
                ],
              //LevelInArcPower: this.dcemergencyFeatureParametersObj['LevelInArcPower']
            },
          ],
        };
      }
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.dcemergencyFeature);
    }
    //DC Emergency End here
    //DTL Starts Here
    if (
      ((this.isDtlQuickDisable && this.dtlSelectedFeature) ||
        selectedCheck === false) &&
      (element === "dtl-" || element === "DTL")
    ) {
      if (this.dtlFeatureObj["DtlLampOffDisabled"]) {
        this.DtlLapmOffValue = 200;
      } else {
        if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
          this.DtlLapmOffValue = parseInt(this.dtlFeatureObj["DtlLampOffVal"]);
        }
      }
      this.dtlFeature = {
        featureName: "DTL",
        featureParameters: [
          {
            DtlEnabled: this.dtlFeatureObj["DtlEnabled"],
            DtlOutputPercentage: parseInt(
              this.dtlFeatureObj["DtlOutputPercentageVal"]
            ),
            // DtlDimStart: parseInt(this.dtlFeatureObj["DtlDimStartVal"]),
            // DtlLampOff: parseInt(this.DtlLapmOffValue),
            // DtlDimStop: parseInt(this.dtlFeatureObj["DtlDimStopVal"]),
          },
        ],
      };

      if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStartVal"])) {
        this.dtlFeature["featureParameters"][0]["DtlDimStart"] = parseInt(
          this.dtlFeatureObj["DtlDimStartVal"]
        );
      }
      if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
        this.dtlFeature["featureParameters"][0]["DtlLampOff"] = parseInt(
          this.DtlLapmOffValue
        );
      }
      if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStopVal"])) {
        this.dtlFeature["featureParameters"][0]["DtlDimStop"] = parseInt(
          this.dtlFeatureObj["DtlDimStopVal"]
        );
      }
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.dtlFeature);
    }
    if (element === "alo-") {
      let isaloMinLevelTrue = false;
      if (
        (this.aloSelected && this.isAloQuickDisable) ||
        selectedCheck === false
      ) {
        this.aloSchema[0]["requiredProperties"].forEach((element) => {
          if (element === "AloMinLevel") {
            isaloMinLevelTrue = true;
          }
        });
        if (isaloMinLevelTrue) {
          this.aloFeature = {
            featureName: "ALO",
            featureParameters: [
              {
                AloEnabled: this.aloData["AloEnabledVal"],
                AloPercentage: this.aloData["AloPercentageVal"],
                AloMinLevel: this.aloData["AloMinLevelVal"],
              },
            ],
          };
        } else {
          this.aloFeature = {
            featureName: "ALO",
            featureParameters: [
              {
                AloEnabled: this.aloData["AloEnabledVal"],
                AloPercentage: this.aloData["AloPercentageVal"],
              },
            ],
          };
        }
      }
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.aloFeature);
    } else if (element === "aoc-") {
      if (this.schema[0]["properties"].AocEnabled.aocEnabledLabel === 2) {
        this.aocFeature = {
          featureName: "AOC",
          featureParameters: [
            {
              AocCurrent: this.aocObj["AocCurrent"],
              // AocEnabled: this.schema[0]["properties"].AocEnabled.const,
            },
          ],
        };
      } else if (
        this.schema[0]["properties"].AocEnabled.aocEnabledLabel === 1
      ) {
        this.aocFeature = {
          featureName: "AOC",
          featureParameters: [
            {
              AocCurrent: this.aocObj["AocCurrent"],
              AocEnabled: this.aocObj["AocEnabled"],
            },
          ],
        };
      } else {
        this.aocFeature = {
          featureName: "AOC",
          featureParameters: [this.aocObj],
        };
      }
      if (selectedCheck === true) this.countWritingFeature++;
      settingObj.push(this.aocFeature);
    } else if (element === "clo-") {
      if (
        (this.cloSelected && this.isCloQuickDisable) ||
        selectedCheck === false
      ) {
        this.validateCLoPoints();
        this.cloFeature = {
          featureName: "CLO",
          featureParameters: [this.cloFeatureParametersObj],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.cloFeature);
      }
    } else if (element === "clolite-") {
      if (
        (this.cloLiteSelected && this.isCloQuickDisable) ||
        selectedCheck === false
      ) {
        this.cloFeature = {
          featureName: "CLOLite",
          featureParameters: [
            {
              CloLiteEnabled: this.cloLiteFeatureObj["CloLiteEnabled"],
              CloLiteInitialPowerLevel:
                this.cloLiteFeatureObj["CloLiteInitialPowerLevel"],
              CloLiteMaximumWorkingHours:
                this.cloLiteFeatureObj["CloLiteMaximumWorkingHours"],
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.cloFeature);
      }
    } else if (element === "luminfoformat3-" || element === "luminfoformat0-") {
      if (
        (this.luminSelected && !this.isLuminQuickDisable) ||
        selectedCheck === false
      ) {
        if (element === "luminfoformat3-") {
          let cctBoolValue;
          if (localStorage.getItem("CCTBoolen")) {
            cctBoolValue = JSON.parse(localStorage.getItem("CCTBoolen"));
          }
          if (element === "luminfoformat3-") {
            for (let i in this.newLuminObj) {
              if (
                (i === "DistributionType" ||
                  i === "LuminaireManufactureYear" ||
                  i === "LuminaireManufactureWeek" ||
                  i === "CRI") &&
                (this.newLuminObj[i] === "" || this.newLuminObj[i] === null)
              ) {
                this.newLuminObj[i] = 255;
              } else if (i === "CCT" && cctBoolValue) {
                this.newLuminObj[i] = 65534;
              } else if (
                (i === "PowerMinDim" ||
                  i === "NominalInputPower" ||
                  i === "NominalLightOutput" ||
                  i === "MinAcMainsVoltage" ||
                  i === "MaxAcMainsVoltage" ||
                  i === "CCT") &&
                (this.newLuminObj[i] === "" || this.newLuminObj[i] === null)
              ) {
                if (i === "NominalLightOutput") {
                  this.newLuminObj[i] = 16777215;
                } else {
                  this.newLuminObj[i] = 65535;
                }
              } else if (
                i === "UseDynamicDateIndicator" &&
                this.newLuminObj[i]
              ) {
                var currentDate: any = new Date();
                var startDate: any = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor(
                  (currentDate - startDate) / (24 * 60 * 60 * 1000)
                );
                var weekNumber = Math.ceil(days / 7);
                var year = new Date().getFullYear().toString().substr(-2);
                this.newLuminObj["LuminaireManufactureWeek"] = weekNumber;
                this.newLuminObj["LuminaireManufactureYear"] = year;
                this.newLuminObj[i] = false;
                // this.newLuminObj["LuminaireManufactureWeek"] = 255;
                // this.newLuminObj["LuminaireManufactureYear"] = 255;
              } else if (
                (i === "OemIdentification" && this.newLuminObj[i] === "") ||
                this.newLuminObj[i] === null
              ) {
                this.newLuminObj[i] = "18446744073709551615";
              } else if (
                (i === "OemGtin" && this.newLuminObj[i] === "") ||
                this.newLuminObj[i] === null
              ) {
                this.newLuminObj[i] = "281474976710655";
              }
            }
          }
          for (let i in this.newLuminObj) {
            if (this.lmuSchema[0]["properties"][i]["type"] == "integer") {
              this.newLuminObj[i] = Number(this.newLuminObj[i]);
            } else if (
              i === "LuminaireManufactureDate" &&
              this.newLuminObj[i] === ""
            ) {
              this.newLuminObj[i] = "1234";
            }
          }
          for (let element in this.lmuSchema[0]["properties"]) {
            if (element === "ContentIdentifier") {
              this.newLuminObj["ContentIdentifier"] = this.lmuSchema[0][
                "properties"
              ]["ContentIdentifier"].const
                ? this.lmuSchema[0]["properties"]["ContentIdentifier"].const
                : this.lmuSchema[0]["properties"]["ContentIdentifier"].default;
            }
          }
          this.luminFeature = {
            featureName: "LumInfoFormat",
            featureParameters: [this.newLuminObj],
          };
          if (selectedCheck === true) this.countWritingFeature++;
          settingObj.push(this.luminFeature);
        }
        if (element === "luminfoformat0-") {
          for (let i in this.newLuminObj) {
            if (
              (i === "OemGtin" && this.newLuminObj[i] === "") ||
              this.newLuminObj[i] === null
            ) {
              this.newLuminObj[i] = "281474976710655";
            } else if (
              (i === "OemIdentification" && this.newLuminObj[i] === "") ||
              this.newLuminObj[i] === null
            ) {
              this.newLuminObj[i] = "18446744073709551615";
            }
          }
          this.luminFeature = {
            featureName: "LumInfoFormat",
            featureParameters: [this.newLuminObj],
          };

          if (selectedCheck === true) this.countWritingFeature++;
          settingObj.push(this.luminFeature);
        }
      }
    } else if (element === "ast-") {
      if (
        (this.startupSelected && this.isStartupQuickDisable) ||
        selectedCheck === false
      ) {
        this.startupFeature["featureName"] = "AST";
        this.startupFeature["featureParameters"] =
          this.startupFeatureParametersObj;
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.startupFeature);
      }
    } else if (element === "Psu" || element === "srpsu-") {
      if (
        (this.psuSelected && this.isPsuQuickDisable) ||
        selectedCheck === false
      ) {
        if (
          this.countPSUEnable > 0 ||
          this.countPSUHeart > 0 ||
          this.countPSULast > 0
        ) {
          this.countPSU = 1;
        } else {
          this.countPSU = 0;
        }
        this.psuFeature = {
          featureName: "DALIPSU",
          featureParameters: [this.psuFeatureParamtersObj],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.psuFeature);
      }
    } else if (
      element === "Min Dim Level" ||
      element === "mindimlevel-" ||
      element === "Min dim level"
    ) {
      // Min DIm Level start here
      if (
        (this.isMinDimLevelQuickDisable && this.minDimLevelSelectedFeature) ||
        selectedCheck === false
      ) {
        this.minDimFeature = {
          featureName: "MinDimLevel",
          featureParameters: [
            {
              Enabled: this.minDimLevelFeatureObj["Enabled"],
              MinDimLevel: this.minDimLevelFeatureObj["MinDimLevelVal"],
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.minDimFeature);
      }
      //Min DIm Level End Here
    } else if (element === "ztv-") {
      if (
        (this.isZtvQuickDisable && this.ztvSelectedFeature) ||
        selectedCheck === false
      ) {
        if (this.ztvSchema[0]["featureVersion"] === 5) {
          const data = JSON.parse(localStorage.getItem("saveSetData"));
          if (
            this.ztvSchema[0]["requiredProperties"].includes(
              "ZtvDimToOffEnabled"
            )
          ) {
            const ZtvDimToOffEnabled = data.resp.Ztv.ZtvDimToOffEnabled;
            this.ztvValues["ZtvDimToOffEnabled"] = ZtvDimToOffEnabled;
          }
          if (this.ztvSchema[0]["requiredProperties"].includes("FadeOnTime")) {
            if (data.resp.Ztv.FadeOnTime === 0) {
              const FadeOnTime = 0;
              this.ztvValues["FadeOnTime"] = FadeOnTime;
            } else if (data.resp.Ztv.FadeOnTime !== 0) {
              const FadeOnTime = data.resp.Ztv.FadeOnTime;
              this.ztvValues["FadeOnTime"] = FadeOnTime;
            }
          }
          if (this.ztvSchema[0]["requiredProperties"].includes("FadeOffTime")) {
            if (data.resp.Ztv.FadeOffTime === 0) {
              const FadeOffTime = 0;
              this.ztvValues["FadeOffTime"] = FadeOffTime;
            } else if (data.resp.Ztv.FadeOffTime !== 0) {
              const FadeOffTime = data.resp.Ztv.FadeOffTime;
              this.ztvValues["FadeOffTime"] = FadeOffTime;
            }
          }
        }
        this.ztvFeature = {
          featureName: "ZTV",
          featureParameters: [this.ztvValues],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.ztvFeature);
      }
    } else if (element === "corridor-") {
      if (
        (this.corridorSelected && this.isCorridorQuickDisable) ||
        selectedCheck === false
      ) {
        if (this.corridorFeatureObj["infiniteDisabled"]) {
          this.ProlongTimeValue = 2550;
        } else {
          this.ProlongTimeValue = this.corridorFeatureObj["ProlongTimeValue"];
        }

        this.corridorFeature = {
          featureName: "Corridor",
          featureParameters: [
            {
              NormalLevel: this.corridorFeatureObj["NormalLevelValue"],
              BackgroundLevel: this.corridorFeatureObj["BackgroundLevelValue"],
              DelayTime: this.corridorFeatureObj["DelayTimeValue"],
              ActivationTime: this.corridorFeatureObj["ActivationTimeValue"],
              ProlongTime: this.ProlongTimeValue,
              FadeTimeIndex: this.corridorFeatureObj["FadeTimeIndexValue"],
              CorridorEnabled: !isNullOrUndefined(
                this.corridorFeatureObj["CorridorEnabled"]
              )
                ? this.corridorFeatureObj["CorridorEnabled"]
                : false,
            },
          ],
        };
        if (selectedCheck === true) this.countWritingFeature++;
        settingObj.push(this.corridorFeature);
      }
    }
    return settingObj;
  }
  getUnselectedFeatureList(configuration: any) {
    let supportedWebFeat: any[] = [
      "dynadimmer-",
      "lineswitch-",
      "codedmains-",
      "ast-",
      "dalipsu-",
      "luminfoformat0-",
      "luminfoformat3-",
      "alo-",
      "aoc-",
      "clo-",
      "clolite-",
      "ztv-",
      "dimminginterface-",
      "ampdim-",
      "dcemergency-",
      "dtl-",
      "mtp-",
      "mindimlevel-",
      "touchdim-",
      "corridor-",
      "diia253maintenance-",
      "owp-",
      "ztvoutput-",
      "rso-",
      "lip-",
      "flextune-",
      "multiconfigmode-"
    ];
    let selectedFetaureList: any[] = configuration["selectedFeaturesInfo"];

    let allFeatureList: any[] = configuration["feature12Nc"];
    allFeatureList.forEach((allFeat) => {
      if (!supportedWebFeat.includes(allFeat)) {
        allFeatureList.splice(
          allFeatureList.indexOf(allFeat),
          1,
          "zzRemovedFeature"
        );
      }
    });

    allFeatureList.forEach((allFeatUnslected) => {
      if (selectedFetaureList.includes(allFeatUnslected)) {
        allFeatureList.splice(
          allFeatureList.indexOf(allFeatUnslected),
          1,
          "zzRemovedFeature"
        );
      }
    });
    allFeatureList.sort();
    allFeatureList.forEach((element) => {
      if (element === "zzRemovedFeature") {
        allFeatureList.splice(allFeatureList.indexOf("zzRemovedFeature"));
      }
    });
    return allFeatureList;
  }
  validateCLoPoints() {
    let clopointCnt = Number(localStorage.getItem("cloPoints"));

    for (const key in this.cloFeatureParametersObj) {
      if (this.cloFeatureParametersObj.hasOwnProperty(key)) {
        if (key === "CloPoint" + (clopointCnt + 1))
          delete this.cloFeatureParametersObj[key];
      }
    }
  }
  generatePluginOutput() {
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          let configuration = JSON.parse(
            localStorage.getItem("configurationData")
          );
          let writngUrl;
          writngUrl = url + this.writingURLViaPlugin;
          this.apiService
            .apiCall(
              "get",
              writngUrl.replace(
                "{configurationId}",
                configuration["configurationID"]
              )
            )
            .subscribe(
              (data) => {
                if (data) {
                  this.mem = data;
                  /* this.writingDeviceWithPC(data); */ //MOWEB 1.1
                  localStorage.setItem("pluginOut", JSON.stringify(this.mem));
                  this.successMessage = "success";
                }
              },
              (err) => {
                this.customLoader = false;
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];

                this.successMessage = "error";
              }
            );
        }
      });
  }
  callWritingCheck(count) {
    if (count === this.countWritingFeature) {
      this.countWritingFeature = 0;
      /*   
      this.generatePluginOutput(); */

      /*  
     setTimeout(() => {
      if(this.successMessage==="success"){ */

      localStorage.removeItem("saveSetData");
      localStorage.removeItem("saveSetData1");
      this.router.navigate(["/home"]);
      this.apiService.modalConfigurationData("readDevice");
      this.loading = false;
      if (JSON.parse(localStorage.getItem("multidevice")) === true) {
        const modalRef = this.modalService.open(
          ConfigureDeviceModalInputComponent,
          {
            windowClass: "select-feature-modal",
            backdropClass: "customBackdrop",
          }
        );
      } else {
        const modalRef = this.modalService.open(ReadDeviceComponent, {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        });
      }
      /*  }
     }, 10000); */
    }
  }

  /*  sortAllFeature() {
     var configArray = JSON.parse(localStorage.getItem("configurationData"));
     this.receivedFeature.feature12Nc.forEach((element) => {
       if (element === "ast-" || element === "ztv-") {
         this.receivedFeature.feature12Nc.splice(
           this.receivedFeature.feature12Nc.indexOf(element),
           1
         );
         if (element === "ast-") {
           this.receivedFeature.feature12Nc.push("ast-");
         } else if (element === "ztv-") {
           this.receivedFeature.feature12Nc.unshift("ztv-");
         }
       }
     });
     configArray["feature12Nc"] = this.receivedFeature.feature12Nc;
     localStorage.setItem("configurationData", JSON.stringify(configArray));
   } */
  sortAllFeature(initialdeviceFeaturesList = false) {
    var configArray = JSON.parse(localStorage.getItem("configurationData"));
    let finalList = initialdeviceFeaturesList ? this.deviceFeatures : this.receivedFeature.feature12Nc;
    var arr = [];
    finalList.forEach((element) => {
      if (element === "removedlogicztv-") {
        this.ztvFound = true;
      } else {
        arr.push(element);
      }
    });
    this.receivedFeature.feature12Nc = [];
    if (this.ztvFound === true) {
      this.receivedFeature.feature12Nc.push("ztv-");
    }
    arr.forEach((ele) => {
      this.receivedFeature.feature12Nc.push(ele);
    });
    if(initialdeviceFeaturesList) this.deviceFeatures = [...this.receivedFeature.feature12Nc]
    //if (this.astFound === true) { this.receivedFeature.feature12Nc.push("ast-"); }

    configArray["feature12Nc"] = this.receivedFeature.feature12Nc;
    localStorage.setItem("configurationData", JSON.stringify(configArray));
  }

  countConfigure(value) {
    if (value) {
      this.countConfigureFeature = this.countConfigureFeature + 1;
    } else {
      this.countConfigureFeature = this.countConfigureFeature - 1;
    }

    if (this.countConfigureFeature < 0) {
      this.countConfigureFeature = 0;
    }
  }

  verifySelectedFeatureCount(featureName) {
    let featCountFlag: boolean = false;
    switch (featureName.toLowerCase()) {
      case "flextune-":
        if (this.flextuneSelected && !this.isFlextuneQuickDisable) {
          featCountFlag = true;
        }
        break;
      case "clo-":
        if (this.cloSelected && this.isCloQuickDisable) {
          featCountFlag = true;
        }
        break;
      case "corridor-":
        if (this.corridorSelected && this.isCorridorQuickDisable)
          featCountFlag = true;

        break;
      case "clolite-":
        if (this.cloLiteSelected && this.isCloQuickDisable)
          featCountFlag = true;

        break;
      case "diia253maintenance-":
        if (
          this.diia253maintenanceSelected &&
          this.isdiia253maintenanceQuickDisable
        )
          featCountFlag = true;

        break;
      case "touchdim-":
        if (this.touchDimSelected && this.isTouchDimQuickDisable)
          featCountFlag = true;

        break;
      case "codedmains-":
        if (this.codedmainsSelected && this.iscodedmainsDisable)
          featCountFlag = true;
        break;
      case "owp-":
        if (this.owpSelected && this.isOwpDisable) featCountFlag = true;
        break;
      case "mtp-":
        if (this.mtpSelected && this.isMtpQuickDisable) featCountFlag = true;

        break;
      case "dcemergency-":
        if (this.dcemergencySelected && this.isDcemergencyQuickDisable)
          featCountFlag = true;

        break;
      case "dtl-":
        if (this.isDtlQuickDisable && this.dtlSelectedFeature)
          featCountFlag = true;

        break;
      case "alo-":
        if (this.aloSelected && this.isAloQuickDisable) featCountFlag = true;

        break;
      case "aoc-":
        if (this.aocSelected && this.isAocQuickDisable) featCountFlag = true;

        break;
      case "luminfoformat3-":
        if (this.luminSelected && !this.isLuminQuickDisable)
          featCountFlag = true;

        break;
      case "luminfoformat0-":
        if (this.luminSelected && !this.isLuminQuickDisable)
          featCountFlag = true;

        break;
      case "ast-":
        if (this.startupSelected && this.isStartupQuickDisable)
          featCountFlag = true;

        break;
      case "srpsu-":
        if (this.psuSelected && this.isPsuQuickDisable) featCountFlag = true;

        break;
      case "dalipsu-":
        if (this.psuSelected && this.isPsuQuickDisable) featCountFlag = true;

        break;
      case "mindimlevel-":
        if (this.isMinDimLevelQuickDisable && this.minDimLevelSelectedFeature)
          featCountFlag = true;

        break;
      case "ztv-":
        if (this.isZtvQuickDisable && this.ztvSelectedFeature)
          featCountFlag = true;

        break;
      case "rso-":
        if (this.isrsoQuickDisable && this.rsoSelected) featCountFlag = true;

        break;
      case "lip-":
        if (this.islipQuickDisable && this.lipSelected) featCountFlag = true;

        break;
      case "ztvoutput-":
        if (this.isztvoutputQuickDisable && this.ztvoutputSelected)
          featCountFlag = true;

        break;
      case "multiconfigmode-":
        if(this.multiconfigSelected) featCountFlag = true;
        break;
      case "dimminginterface-":
        if (this.dimmingInterfaceSelected) featCountFlag = true;
        break;
      case "lineswitch-":
        if (this.isLineSwitchDisable && this.lineSwitchSelected)
          featCountFlag = true;
        break;
      case "dynadimmer-":
        if (this.isdynaDimmerQuickDisable && this.dynaDimmerSelected)
          featCountFlag = true;
        break;
      case "dynadim-":
        if (this.isdynaDimmerQuickDisable && this.dynaDimmerSelected)
          featCountFlag = true;
        break;
      case "ampdim-":
        if (this.isAmpDimQuickDisable && this.ampDimSelected)
          featCountFlag = true;
        break;
      case "codedmains-":
        if (this.iscodedmainsQuickDisable && this.codedmainsSelected)
          featCountFlag = true;
        break;
      case "owp-":
        if (this.isowpQuickDisable && this.owpSelected) featCountFlag = true;
        break;
      default:
        featCountFlag = false;
        break;
    }

    return featCountFlag;
  }
  /////////////////////

  newConfigureDevice() {
    if (
      this.saveFeatureFileClicked === false &&
      this.configureDeviceClicked === false
    ) {
      this.configureDeviceClicked = true;

      this.isDisabledButton = true;
      this.isOpenModal = false;
      this.isError = false;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let token = currentUser["token"];
      let configurationID = this.receivedFeature.configurationID;
      let headers = new HttpHeaders({
        Authorization: "Bearer " + token,
      });
      let settingObj = [];

      var newConfigurationID = JSON.parse(
        localStorage.getItem("configurationData")
      );

      this.count = 0;
      this.selectedFEaturesLength =
        newConfigurationID["selectedFeaturesInfo"].length;
      let avaiableZTVVerified = false;
      newConfigurationID["selectedFeaturesInfo"].forEach((element) => {
        //|| element === 'dynadimmer-' || element === 'luminfoformat1-' || element === 'luminfoformat0-' || element === 'dcemergency-'
        if (
          element === "ztv-" ||
          //element === "owp-" ||
          element === "luminfoformat1-" ||
          //element === "corridor-" ||
          //element === "touchdim-" ||
          element === "codedmains"
        ) {
          this.selectedFEaturesLength = this.selectedFEaturesLength - 1;

          if (this.isZtvDependDimming) {
            const index: number =
              newConfigurationID["selectedFeaturesInfo"].indexOf("ztv-");
            if (index !== -1) {
              newConfigurationID["selectedFeaturesInfo"].splice(index, 1);
            }
          } else if (
            this.isZTVFeatureAvailable &&
            !avaiableZTVVerified &&
            element === "ztv-"
          ) {
            avaiableZTVVerified = true;
            this.selectedFEaturesLength = this.selectedFEaturesLength + 1;
          }
        }
      });

      this.apiService.getEnvValue();
      this.apiService.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            this.loading = true;
            this.envSetConfigUrl = url + this.setConfigUrl;
            this.unsubscribe.next();
            newConfigurationID.selectedFeaturesInfo.forEach((element) => {
              if (element === "alo-") {
                let isaloMinLevelTrue = false;
                if (this.aloSelected && this.isAloQuickDisable) {
                  this.aloSchema[0]["requiredProperties"].forEach((element) => {
                    if (element === "AloMinLevel") {
                      isaloMinLevelTrue = true;
                    }
                  });
                  if (isaloMinLevelTrue) {
                    this.aloFeature = {
                      featureName: "ALO",
                      featureParameters: [
                        {
                          AloEnabled: this.aloData["AloEnabledVal"],
                          AloPercentage: this.aloData["AloPercentageVal"],
                          AloMinLevel: this.aloData["AloMinLevelVal"],
                        },
                      ],
                    };
                  } else {
                    this.aloFeature = {
                      featureName: "ALO",
                      featureParameters: [
                        {
                          AloEnabled: this.aloData["AloEnabledVal"],
                          AloPercentage: this.aloData["AloPercentageVal"],
                        },
                      ],
                    };
                  }
                }
                this.countWritingFeature++;
                settingObj.push(this.aloFeature);
              }

              if (element === "clo-") {
                if (this.cloSelected && this.isCloQuickDisable) {
                  this.validateCLoPoints();
                  this.cloFeature = {
                    featureName: "CLO",
                    featureParameters: [this.cloFeatureParametersObj],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.cloFeature);
              }
              if (element === "dynadimmer-") {
                var dynaConfig = JSON.parse(
                  localStorage.getItem("dynaSaveSetData")
                );
                if (this.dynaDimmerSelected && this.isdynaDimmerQuickDisable) {
                  this.dynaFeature = {
                    featureName: "Dynadimmer",
                    featureParameters: dynaConfig,
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.dynaFeature);
              }
              if (element === "aoc-") {
                if (this.aocSelected && this.isAocQuickDisable) {
                  if (
                    this.schema[0]["properties"].AocEnabled.aocEnabledLabel ===
                    2
                  ) {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [
                        {
                          AocCurrent: this.aocObj["AocCurrent"],
                          // AocEnabled:
                          //   this.schema[0]["properties"].AocEnabled.const,
                        },
                      ],
                    };
                  } else if (
                    this.schema[0]["properties"].AocEnabled.aocEnabledLabel ===
                    1
                  ) {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [
                        {
                          AocCurrent: this.aocObj["AocCurrent"],
                          AocEnabled: this.aocObj["AocEnabled"],
                        },
                      ],
                    };
                  } else {
                    this.aocFeature = {
                      featureName: "AOC",
                      featureParameters: [this.aocObj],
                    };
                  }
                }
                this.countWritingFeature++;
                settingObj.push(this.aocFeature);
              }
              //TouchDim start here
              if (
                this.touchDimSelected &&
                this.isTouchDimQuickDisable &&
                element === "touchdim-"
              ) {
                this.touchDimValues["LastKnownEnabled"] =
                  this.touchDimObj["LastKnownEnabled"];
                this.touchDimValues["PowerOnLevel"] = this.touchDimValues[
                  "LastKnownEnabled"
                ]
                  ? 255
                  : parseInt(this.touchDimObj["BrownOutLevel"]);
                if (
                  this.touchDimSchema[0]["requiredProperties"].includes(
                    "TouchDimEnabled"
                  )
                ) {
                  this.touchDimValues["TouchDimEnabled"] =
                    this.touchDimObj["LastKnownEnabled"];
                }
                if (
                  this.touchDimSchema[0]["requiredProperties"].includes(
                    "IgnoreToggleLimits"
                  )
                ) {
                  this.touchDimValues["IgnoreToggleLimits"] =
                    this.touchDimObj["IgnoreToggleLimits"];
                }
                this.touchDimFeature = {
                  featureName: "TouchDim",
                  featureParameters: [this.touchDimValues],
                };
                this.countWritingFeature++;
                settingObj.push(this.touchDimFeature);
              }
              //TouchDim End here
              //diia253maintenance start here
              if (
                this.diia253maintenanceSelected &&
                this.isdiia253maintenanceQuickDisable &&
                element === "diia253maintenance-"
              ) {
                let RatedLife =
                  this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
                    "" ||
                  this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
                    undefined
                    ? 255
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj["RatedLife"]
                      );
                let ReferenceTemperature =
                  this.diia253maintenanceFeatureParametersObj[
                    "ReferenceTemperature"
                  ] === "" ||
                  this.diia253maintenanceFeatureParametersObj[
                    "ReferenceTemperature"
                  ] === undefined
                    ? 195
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj[
                          "ReferenceTemperature"
                        ]
                      );
                let RatedStarts =
                  this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
                    "" ||
                  this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
                    undefined
                    ? 65535
                    : parseInt(
                        this.diia253maintenanceFeatureParametersObj[
                          "RatedStarts"
                        ]
                      );
                this.diia253maintenanceFeature = {
                  featureName: "Diia253Maintenance",
                  featureParameters: [
                    {
                      RatedLife: RatedLife,
                      ReferenceTemperature: ReferenceTemperature,
                      RatedStarts: RatedStarts,
                    },
                  ],
                };
                this.countWritingFeature++;
                settingObj.push(this.touchDimFeature);
              }
              //diia253maintenance End here
              // if (this.owpSelected && this.isOwpDisable && element === "owp-") {
              //   let RatedLife =
              //     this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
              //       "" ||
              //     this.diia253maintenanceFeatureParametersObj["RatedLife"] ===
              //       undefined
              //       ? 255
              //       : parseInt(
              //           this.diia253maintenanceFeatureParametersObj["RatedLife"]
              //         );
              //   let ReferenceTemperature =
              //     this.diia253maintenanceFeatureParametersObj[
              //       "ReferenceTemperature"
              //     ] === "" ||
              //     this.diia253maintenanceFeatureParametersObj[
              //       "ReferenceTemperature"
              //     ] === undefined
              //       ? 195
              //       : parseInt(
              //           this.diia253maintenanceFeatureParametersObj[
              //             "ReferenceTemperature"
              //           ]
              //         );
              //   let RatedStarts =
              //     this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
              //       "" ||
              //     this.diia253maintenanceFeatureParametersObj["RatedStarts"] ===
              //       undefined
              //       ? 65535
              //       : parseInt(
              //           this.diia253maintenanceFeatureParametersObj[
              //             "RatedStarts"
              //           ]
              //         );
              //   this.diia253maintenanceFeature = {
              //     featureName: "Diia253Maintenance",
              //     featureParameters: [
              //       {
              //         RatedLife: RatedLife,
              //         ReferenceTemperature: ReferenceTemperature,
              //         RatedStarts: RatedStarts,
              //       },
              //     ],
              //   };
              //   this.countWritingFeature++;
              //   settingObj.push(this.touchDimFeature);
              // }

              if (element === "ast-") {
                if (this.startupSelected && this.isStartupQuickDisable) {
                  this.startupFeature["featureName"] = "AST";
                  this.startupFeature["featureParameters"] =
                    this.startupFeatureParametersObj;
                }
                this.countWritingFeature++;
                settingObj.push(this.startupFeature);
              }
              if (element === "ztv-") {
                if (this.isZtvQuickDisable && this.ztvSelectedFeature) {
                  if (this.ztvSchema[0]["featureVersion"] === 5) {
                    const data = JSON.parse(
                      localStorage.getItem("saveSetData")
                    );
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "ZtvDimToOffEnabled"
                      )
                    ) {
                      const ZtvDimToOffEnabled =
                        data.resp.Ztv.ZtvDimToOffEnabled;
                      this.ztvValues["ZtvDimToOffEnabled"] = ZtvDimToOffEnabled;
                    }
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "FadeOnTime"
                      )
                    ) {
                      if (data.resp.Ztv.FadeOnTime === 0) {
                        const FadeOnTime = 0;
                        this.ztvValues["FadeOnTime"] = FadeOnTime;
                      } else if (data.resp.Ztv.FadeOnTime !== 0) {
                        const FadeOnTime = data.resp.Ztv.FadeOnTime;
                        this.ztvValues["FadeOnTime"] = FadeOnTime;
                      }
                    }
                    if (
                      this.ztvSchema[0]["requiredProperties"].includes(
                        "FadeOffTime"
                      )
                    ) {
                      if (data.resp.Ztv.FadeOffTime === 0) {
                        const FadeOffTime = 0;
                        this.ztvValues["FadeOffTime"] = FadeOffTime;
                      } else if (data.resp.Ztv.FadeOffTime !== 0) {
                        const FadeOffTime = data.resp.Ztv.FadeOffTime;
                        this.ztvValues["FadeOffTime"] = FadeOffTime;
                      }
                    }
                    // this.ztvValues['ZtvDimToOffEnabled'] = this.ztvSchema[0]['properties'].ZtvDimToOffEnabled.const
                    // this.ztvValues['FadeOnTime'] = this.ztvSchema[0]['properties'].FadeOnTime.const
                    // this.ztvValues['FadeOffTime'] = this.ztvSchema[0]['properties'].FadeOffTime.const
                  }
                  this.ztvFeature = {
                    featureName: "ZTV",
                    featureParameters: [this.ztvValues],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.ztvFeature);
              }

              if (element === "Psu" || element === "srpsu-") {
                if (this.psuSelected && this.isPsuQuickDisable) {
                  if (
                    this.countPSUEnable > 0 ||
                    this.countPSUHeart > 0 ||
                    this.countPSULast > 0
                  ) {
                    this.countPSU = 1;
                  } else {
                    this.countPSU = 0;
                  }
                  this.psuFeature = {
                    featureName: "DALIPSU",
                    featureParameters: [this.psuFeatureParamtersObj],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.psuFeature);
              }
              if (element === "luminfoformat3-") {
                let cctBoolValue;
                if (localStorage.getItem("CCTBoolen")) {
                  cctBoolValue = JSON.parse(localStorage.getItem("CCTBoolen"));
                }
                if (this.luminSelected && !this.isLuminQuickDisable) {
                  if (element === "luminfoformat3-") {
                    for (let i in this.newLuminObj) {
                      if (
                        (i === "DistributionType" ||
                          i === "LuminaireManufactureYear" ||
                          i === "LuminaireManufactureWeek" ||
                          i === "CRI") &&
                        (this.newLuminObj[i] === "" ||
                          this.newLuminObj[i] === null)
                      ) {
                        this.newLuminObj[i] = 255;
                      } else if (i === "CCT" && cctBoolValue) {
                        this.newLuminObj[i] = 65534;
                      } else if (
                        (i === "PowerMinDim" ||
                          i === "NominalInputPower" ||
                          i === "NominalLightOutput" ||
                          i === "MinAcMainsVoltage" ||
                          i === "MaxAcMainsVoltage" ||
                          i === "CCT") &&
                        (this.newLuminObj[i] === "" ||
                          this.newLuminObj[i] === null)
                      ) {
                        if (i === "NominalLightOutput") {
                          this.newLuminObj[i] = 16777215;
                        } else {
                          this.newLuminObj[i] = 65535;
                        }
                      } else if (
                        i === "UseDynamicDateIndicator" &&
                        this.newLuminObj[i]
                      ) {
                        this.newLuminObj["LuminaireManufactureWeek"] = 255;
                        this.newLuminObj["LuminaireManufactureYear"] = 255;
                      } else if (
                        (i === "OemIdentification" &&
                          this.newLuminObj[i] === "") ||
                        this.newLuminObj[i] === null
                      ) {
                        this.newLuminObj[i] = "18446744073709551615";
                      } else if (
                        (i === "OemGtin" && this.newLuminObj[i] === "") ||
                        this.newLuminObj[i] === null
                      ) {
                        this.newLuminObj[i] = "281474976710655";
                      }
                    }
                  }
                  for (let i in this.newLuminObj) {
                    if (
                      this.lmuSchema[0]["properties"][i]["type"] == "integer"
                    ) {
                      this.newLuminObj[i] = Number(this.newLuminObj[i]);
                    } else if (
                      i === "LuminaireManufactureDate" &&
                      this.newLuminObj[i] === ""
                    ) {
                      this.newLuminObj[i] = "1234";
                    }
                  }
                  for (let element in this.lmuSchema[0]["properties"]) {
                    if (element === "ContentIdentifier") {
                      this.newLuminObj["ContentIdentifier"] = this.lmuSchema[0][
                        "properties"
                      ]["ContentIdentifier"].const
                        ? this.lmuSchema[0]["properties"]["ContentIdentifier"]
                            .const
                        : this.lmuSchema[0]["properties"]["ContentIdentifier"]
                            .default;
                    }
                  }
                  this.luminFeature = {
                    featureName: "LumInfoFormat",
                    featureParameters: [this.newLuminObj],
                  };
                  this.countWritingFeature++;
                  settingObj.push(this.luminFeature);
                }
              }
              if (element === "luminfoformat0-") {
                for (let i in this.newLuminObj) {
                  if (
                    (i === "OemGtin" && this.newLuminObj[i] === "") ||
                    this.newLuminObj[i] === null
                  ) {
                    this.newLuminObj[i] = "281474976710655";
                  } else if (
                    (i === "OemIdentification" && this.newLuminObj[i] === "") ||
                    this.newLuminObj[i] === null
                  ) {
                    this.newLuminObj[i] = "18446744073709551615";
                  }
                }
                this.luminFeature = {
                  featureName: "LumInfoFormat",
                  featureParameters: [this.newLuminObj],
                };

                this.countWritingFeature++;
                settingObj.push(this.luminFeature);
              }
              if (
                element === "CloLite" ||
                element === "Clolite" ||
                element === "clolite-"
              ) {
                if (this.cloLiteSelected && this.isCloQuickDisable) {
                  this.cloFeature = {
                    featureName: "CLOLite",
                    featureParameters: [
                      {
                        CloLiteEnabled:
                          this.cloFeatureParametersObj["CloEnabled"],
                        CloLiteInitialPowerLevel: this.litepower,
                        CloLiteMaximumWorkingHours: this.liteHour,
                      },
                    ],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.cloFeature);
              }

              // dtl start here
              if (
                this.isDtlQuickDisable &&
                this.dtlSelectedFeature &&
                (element === "dtl-" || element === "DTL")
              ) {
                if (this.dtlFeatureObj["DtlLampOffDisabled"]) {
                  this.DtlLapmOffValue = 200;
                } else {
                  if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
                    this.DtlLapmOffValue = parseInt(
                      this.dtlFeatureObj["DtlLampOffVal"]
                    );
                  }
                }
                this.dtlFeature = {
                  featureName: "DTL",
                  featureParameters: [
                    {
                      DtlEnabled: this.dtlFeatureObj["DtlEnabled"],
                      DtlOutputPercentage: parseInt(
                        this.dtlFeatureObj["DtlOutputPercentageVal"]
                      ),
                      // DtlDimStart: parseInt(
                      //   this.dtlFeatureObj["DtlDimStartVal"]
                      // ),
                      // DtlLampOff: parseInt(this.DtlLapmOffValue),
                      // DtlDimStop: parseInt(this.dtlFeatureObj["DtlDimStopVal"]),
                    },
                  ],
                };

                if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStartVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlDimStart"] =
                    parseInt(this.dtlFeatureObj["DtlDimStartVal"]);
                }
                if (!isNullOrUndefined(this.dtlFeatureObj["DtlLampOffVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlLampOff"] =
                    parseInt(this.DtlLapmOffValue);
                }
                if (!isNullOrUndefined(this.dtlFeatureObj["DtlDimStopVal"])) {
                  this.dtlFeature["featureParameters"][0]["DtlDimStop"] =
                    parseInt(this.dtlFeatureObj["DtlDimStopVal"]);
                }
                this.countWritingFeature++;
                settingObj.push(this.dtlFeature);
              }
              // Min DIm Level start here
              if (
                this.isMinDimLevelQuickDisable &&
                this.minDimLevelSelectedFeature &&
                (element === "Min Dim Level" ||
                  element === "mindimlevel-" ||
                  element === "Min dim level")
              ) {
                this.minDimFeature = {
                  featureName: "MinDimLevel",
                  featureParameters: [
                    {
                      Enabled: this.minDimLevelFeatureObj["Enabled"],
                      MinDimLevel: this.minDimLevelFeatureObj["MinDimLevelVal"],
                    },
                  ],
                };
                this.countWritingFeature++;
                settingObj.push(this.minDimFeature);
              }
              //Min DIm Level End Here

              //amp dim start here
              if (
                this.ampDimSelected &&
                this.isAmpDimQuickDisable &&
                element === "ampdim-"
              ) {
                this.ampDimFeature = {
                  featureName: "AmpDim",
                  featureParameters: [
                    {
                      VmainsStart: this.ampDimFeatureObj["VmainsStartValue"],
                      VmainsStop: this.ampDimFeatureObj["VmainsStopValue"],
                      StartDimLevel:
                        this.ampDimFeatureObj["StartDimLevelValue"],
                      StopDimLevel: this.ampDimFeatureObj["StopDimLevelValue"],
                    },
                  ],
                };
                this.countWritingFeature++;
                settingObj.push(this.ampDimFeature);
              }
              //amp dim end here
              //codedmains starts here
              if (
                this.codedmainsSelected &&
                this.iscodedmainsDisable &&
                element === "codedmains-"
              ) {
                this.codedmainsFeature = {
                  featureName: "CodedMains",
                  featureParameters: [
                    {
                      FadeTime: this.codedmainsFeatureObj["FadeTime"],
                      FastFadeTime: this.codedmainsFeatureObj["FastFadeTime"],
                      FallbackDetectionTime:
                        this.codedmainsFeatureObj["FallbackDetectionTime"],
                      PowerOnLevel: this.codedmainsFeatureObj["PowerOnLevel"],
                      FallbackScene: this.codedmainsFeatureObj["FallbackScene"],
                      Scene1: this.codedmainsFeatureObj["Scene1"],
                      Scene2: this.codedmainsFeatureObj["Scene2"],
                      Scene3: this.codedmainsFeatureObj["Scene3"],
                      Scene4: this.codedmainsFeatureObj["Scene4"],
                      Scene5: this.codedmainsFeatureObj["Scene5"],
                      Scene6: this.codedmainsFeatureObj["Scene6"],
                      Scene7: this.codedmainsFeatureObj["Scene7"],
                      Scene8: this.codedmainsFeatureObj["Scene8"],
                      Scene9: this.codedmainsFeatureObj["Scene9"],
                      Scene10: this.codedmainsFeatureObj["Scene10"],
                      Scene11: this.codedmainsFeatureObj["Scene11"],
                      Scene12: this.codedmainsFeatureObj["Scene12"],
                    },
                  ],
                };
                this.count++;
                settingObj.push(this.codedmainsFeature);
              }
              //codedmains ends here
              //mtp start here
              if (
                this.mtpSelected &&
                this.isMtpQuickDisable &&
                element === "mtp-"
              ) {
                this.mtpFeature = {
                  featureName: "MTP",
                  featureParameters: [
                    {
                      DimLevel: this.mtpFeatureObj["DimLevelValue"],
                      DimStart: this.mtpFeatureObj["DimStartValue"],
                      DimStop: this.mtpFeatureObj["DimStopValue"],
                      Enabled: this.mtpFeatureObj["Enabled"],
                      NtcBeta: this.mtpFeatureObj["NtcBeta"],
                      NtcResistance: this.mtpFeatureObj["NtcResistance"],
                      SelectedNtc: this.mtpFeatureObj["SelectedNtc"],
                    },
                  ],
                };
                this.countWritingFeature++;
                settingObj.push(this.mtpFeature);
              }
              if (
                this.isztvoutputQuickDisable &&
                this.ztvoutputSelected &&
                element === "ztvoutput-"
              ) {
                if (this.ztvoutputSchema) {
                  const data = JSON.parse(localStorage.getItem("saveSetData"));
                }
                this.ztvoutputFeature = {
                  featureName: "ztvoutput",
                  featureParameters: [this.ztvoutputObj],
                };
              }
              this.countWritingFeature++;
              settingObj.push(this.ztvoutputFeature);
              if (
                this.isrsoQuickDisable &&
                this.rsoSelected &&
                element === "rso-"
              ) {
                if (this.rsoSchema) {
                  const data = JSON.parse(localStorage.getItem("saveSetData"));
                }
                this.rsoFeature = {
                  featureName: "rso",
                  featureParameters: [this.rsoObj],
                };
              }
              this.countWritingFeature++;
              settingObj.push(this.ztvoutputFeature);
              if (
                this.islipQuickDisable &&
                this.lipSelected &&
                element === "lip-"
              ) {
                if (this.lipSchema) {
                  const data = JSON.parse(localStorage.getItem("saveSetData"));
                }
                this.lipFeature = {
                  featureName: "lip",
                  featureParameters: [this.lipObj],
                };
              }
              this.countWritingFeature++;
              settingObj.push(this.ztvoutputFeature);
              //mtp end here
              // line-switch start here
              if (
                this.lineSwitchSelected &&
                this.isLineSwitchDisable &&
                element === "lineswitch-"
              ) {
                if (this.LineSwitchSchema[0].featureVersion === 4) {
                  this.lineswitchFeature = {
                    featureName: "LineSwitch",
                    featureParameters: [
                      {
                        HighVLevel:
                          this.lineSwitchFeatureObj["HighVLevelValue"],
                        LowVLevel:
                          this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                        FadeUpTime:
                          this.lineSwitchFeatureObj[
                            "lineswitchFadeUpTimeValue"
                          ],
                        FadeDownTime:
                          this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                        SwitchOffDelayTime:
                          this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
                        "2ndInactiveLevelEnabled":
                          this.lineSwitchFeatureObj["2ndInactiveLevelEnabled"],
                        "2ndInactiveDimLevel":
                          this.lineSwitchFeatureObj[
                            "lineswitch2ndInactiveDimLevelValue"
                          ],
                        "2ndInactiveLevelDelayTime":
                          this.lineSwitchFeatureObj[
                            "lineswitch2ndInactiveLevelDelayTimeValue"
                          ],
                      },
                    ],
                  };
                } else {
                  this.lineswitchFeature = {
                    featureName: "LineSwitch",
                    featureParameters: [
                      {
                        HighVLevel:
                          this.lineSwitchFeatureObj["HighVLevelValue"],
                        LineSwitchEnabled:
                          this.lineSwitchFeatureObj["isLineSwitchEnabled"],
                        LowVLevel:
                          this.lineSwitchFeatureObj["lineswitchLowVLevelValue"],
                        FadeUpTime:
                          this.lineSwitchFeatureObj[
                            "lineswitchFadeUpTimeValue"
                          ],
                        FadeDownTime:
                          this.lineSwitchFeatureObj["lineswitchFadeDownValue"],
                        SwitchOffDelayTime:
                          this.lineSwitchFeatureObj["lineswitchOffDelayValue"],
                      },
                    ],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.lineswitchFeature);
              }
              // line-switch end here
              //DC Emergency start here
              if (
                this.dcemergencySelected &&
                this.isDcemergencyQuickDisable &&
                element === "dcemergency-"
              ) {
                if (this.dcemergencyFeatureParametersObj !== undefined) {
                  this.dcemergencyFeatureParametersObj["LevelInArcPower"] =
                    true;

                  this.dcemergencyFeature = {
                    featureName: "DCEmergency",
                    featureParameters: [
                      {
                        DcEmergencyLevel: parseInt(
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyLevel"
                          ]
                        ),
                        DcEmergencyEnabled:
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyEnabled"
                          ],
                        DcEmergencyDimmingEnabled:
                          this.dcemergencyFeatureParametersObj[
                            "DcEmergencyDimmingEnabled"
                          ],
                        LevelInArcPower:
                          this.dcemergencyFeatureParametersObj[
                            "LevelInArcPower"
                          ],
                      },
                    ],
                  };
                }
                this.countWritingFeature++;
                settingObj.push(this.dcemergencyFeature);
              }
              //DC Emergency End here

              //FLEXTUNE start here 
              if (element === "flextune-") {
                if (this.flextuneSelected && !this.isFlextuneQuickDisable) {
                  this.countWritingFeature++;
                  settingObj.push({
                    featureName: 'FlexTune',
                    featureParameters: [this.flextuneData]
                  })
                }
              }
              //FLEXTUNE end here
              
              //multiconfig mode start here
              if (this.multiconfigSelected && element === "multiconfigmode-") {
                this.countWritingFeature++;
                settingObj.push({
                  featureName: 'MultiConfigMode',
                  featureParameters: [this.multiconfigData]
                });
              }
              //multiconfig mode ended here

              //dimming interface start here
              if (
                this.dimmingInterfaceSelected &&
                element === "dimminginterface-"
              ) {
                this.dimmingInterfaceFeature = {
                  featureName: "DimmingInterface",
                  featureParameters: [this.dimmingInterfaceObj],
                };
                this.countWritingFeature++;
                settingObj.push(this.dimmingInterfaceFeature);
              }
              //dimming interface End here
            });
          }
          if (settingObj.length > 0) {
            var readerConfigurationData1 = JSON.parse(
              localStorage.getItem("readerConfigurationData")
            );

            if (
              readerConfigurationData1 !== null &&
              newConfigurationID["configurationID"] ===
                readerConfigurationData1["configurationID"]
            ) {
              var deviceReaderData = JSON.parse(
                localStorage.getItem("readerConfigurationData")
              )["device12nc"];
              var deviceReaderVersionData = JSON.parse(
                localStorage.getItem("readerConfigurationData")
              )["softwareVersion"];

              let deviceObj = {
                device12nc: deviceReaderData,
                softwareVersion: deviceReaderVersionData,
              };

              this.apiService
                .getStartConfiguration(deviceObj, this.url)
                .subscribe((data1) => {
                  this.newConfigurationIDExtra = data1["configurationID"];
                });
              setTimeout(() => {
                this.apiService
                  .setAllfeatureAPI(
                    url,
                    this.newConfigurationIDExtra,
                    settingObj
                  )
                  .subscribe(
                    (data) => {
                      this.countAllFeaturesUnique_confg(
                        this.newConfigurationIDExtra
                      );
                      setTimeout(() => {
                        this.saveFeatureFileClicked = false;
                      }, 5000);
                    },
                    (err) => {
                      this.saveFeatureFileClicked = false;
                      this.loading = false;
                      this.customLoader = false;
                      this.loading = false;
                      const modalRef = this.modalService.open(ErrorComponent, {
                        keyboard: false,
                        centered: true,
                        windowClass: "error-style, error-style1",
                      });
                      modalRef.componentInstance.errorName = err["message"];
                      modalRef.componentInstance.errorDescription =
                        err["description"];
                    }
                  );
              }, 2000);
            } else {
              this.apiService
                .setAllfeatureAPI(
                  url,
                  newConfigurationID.configurationID,
                  settingObj
                )
                .subscribe(
                  (data) => {
                    this.countAllFeatures_confg();
                    setTimeout(() => {
                      this.configureDeviceClicked = false;
                    }, 5000);
                  },
                  (err) => {
                    this.configureDeviceClicked = false;
                    this.loading = false;
                    this.customLoader = false;

                    const modalRef = this.modalService.open(ErrorComponent, {
                      keyboard: false,
                      centered: true,
                      windowClass: "error-style, error-style1",
                    });
                    modalRef.componentInstance.errorName = err["message"];
                    modalRef.componentInstance.errorDescription =
                      err["description"];
                  }
                );
            }
            // this.apiService.setAllfeatureAPI(url, newConfigurationID.configurationID, settingObj).subscribe((data) => {
            //  this.countAllFeatures()
            // })
          }
        });
      //localStorage.setItem("exportedFeatureFile", JSON.stringify(false));
      this.configOmitOnRefresh();
      setTimeout(() => {
        this.configureDeviceClicked = false;
      }, 5000);
    }
  }

  countAllFeatures_confg() {
    this.count = 1;
    if (this.count === this.selectedFEaturesLength) {
      var newConfigurationID = JSON.parse(
        localStorage.getItem("configurationData")
      );
      var readerConfigurationData1 = JSON.parse(
        localStorage.getItem("readerConfigurationData")
      );

      if (this.restartConfiguration) {
        this.startConfiguration();
        this.restartConfiguration = false;
      } else {
        this.startConfiguration_confg(newConfigurationID.configurationID);
      }
    }
  }
  countAllFeaturesUnique_confg(uniqueID?) {
    if (this.count === this.selectedFEaturesLength) {
      this.startConfiguration_confg(uniqueID);
    }
  }

  startConfiguration_confg(id?) {
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.startConfigURL = url;
          this.softwareVersion = JSON.parse(
            localStorage.getItem("comparefirmwarenoLatest")
          );
          if (this.softwareVersion.length > 3) {
            this.softwareVersion = this.softwareVersion.slice(0, -2);
          }
          // till demo purpose
          let mockDevice = {};
          if (this.receivedFeature.deviceName === "XH075C120V075KPF1") {
            mockDevice = {
              device12nc: this.receivedFeature.device12nc,
              softwareVersion: this.softwareVersion,
            };
          } else {
            mockDevice = {
              device12nc: this.receivedFeature.device12nc,
              deviceName: this.receivedFeature.deviceName,
              softwareVersion: this.softwareVersion,
            };
          }
          this.apiService
            .getStartConfiguration(mockDevice, this.startConfigURL)
            .subscribe(
              (data) => {
                localStorage.removeItem("deviceVersion");
                var configurationID;
                configurationID = data["configurationID"];
                if (localStorage.getItem("configurationData")) {
                  var dataFromConfig = JSON.parse(
                    localStorage.getItem("configurationData")
                  );
                  this.receivedFeature = {
                    deviceName: dataFromConfig.deviceName,
                    device12nc: dataFromConfig.device12nc,
                    softwareVerSchema: dataFromConfig.softwareVerSchema,
                    picture: dataFromConfig.picture,
                    luminSoftVer: dataFromConfig.luminSoftVer,
                    dtlSoftVer: dataFromConfig.dtlSoftVer,
                    flextuneSoftVer: dataFromConfig.flextuneSoftVer,
                    multiconfigSoftVer: dataFromConfig.multiconfigSoftVer,
                    mindimlevelSoftVer: dataFromConfig.mindimlevelSoftVer,
                    dalipsuSoftVer: dataFromConfig.dalipsuSoftVer,
                    selectedFeaturesInfo: dataFromConfig.selectedFeaturesInfo,
                    feature12Nc: dataFromConfig.feature12Nc,
                    ztvSoftVer: dataFromConfig.ztvSoftVer,
                    ztvoutputSoftVer: dataFromConfig.ztvoutputSoftVer,
                    lipSoftVer: dataFromConfig.lipSoftVer,
                    rsoSoftVer: dataFromConfig.rsoSoftVer,
                    mtpSoftVer: dataFromConfig.mtpSoftVer,
                    dimminginterfaceSoftVer:
                      dataFromConfig.dimminginterfaceSoftVer,
                    ampdimSoftVer: dataFromConfig.ampdimSoftVer,
                    aocSoftVer: dataFromConfig.aocSoftVer,
                    touchdimSoftVer: dataFromConfig.touchdimSoftVer,
                    diia253maintenanceSoftVer:
                      dataFromConfig.diia253maintenanceSoftVer,
                    corridorSoftVer: dataFromConfig.corridorSoftVer,
                    astSoftVer: dataFromConfig.astSoftVer,
                    cloliteSoftVer: dataFromConfig.cloliteSoftVer,
                    cloSoftVer: dataFromConfig.cloSoftVer,
                    dynadimmerSoftVer: dataFromConfig.dynadimmerSoftVer,
                    dcemergencySoftVer: dataFromConfig.dcemergencySoftVer,
                    lineswitchSoftVer: dataFromConfig.lineswitchSoftVer,
                    owpSoftVer: dataFromConfig.owpSoftVer,
                    codedmainsSoftVer: dataFromConfig.codedmainsSoftVer,
                  };
                  this.softwareVersion = dataFromConfig.softwareVersion;
                }

                if (this.isZtvDependDimming) {
                  this.RemoveItem("ztv-");
                }

                this.receivedFeature.feature12Nc =
                  this.receivedFeature.feature12Nc.sort();
                this.sortAllFeature();
                this.startConfigurationData["configurationID"] =
                  configurationID;
                this.receivedFeature.configurationID = configurationID;
                this.startConfigurationData["deviceName"] =
                  this.receivedFeature.deviceName;
                this.startConfigurationData["picture"] =
                  this.receivedFeature.picture;
                this.startConfigurationData["device12nc"] =
                  this.receivedFeature.device12nc;
                this.startConfigurationData["softwareVersion"] =
                  this.softwareVersion;
                this.startConfigurationData["softwareVerSchema"] =
                  this.receivedFeature.softwareVerSchema;
                this.startConfigurationData["selectedFeaturesInfo"] =
                  this.receivedFeature.selectedFeaturesInfo;
                this.startConfigurationData["feature12Nc"] =
                  this.receivedFeature.feature12Nc;
                this.startConfigurationData["luminSoftVer"] =
                  this.receivedFeature.luminSoftVer;
                this.startConfigurationData["mtpSoftVer"] =
                  this.receivedFeature.mtpSoftVer;
                this.startConfigurationData["dimminginterfaceSoftVer"] =
                  this.receivedFeature.dimminginterfaceSoftVer;
                this.startConfigurationData["ampdimSoftVer"] =
                  this.receivedFeature.ampdimSoftVer;
                this.startConfigurationData["dtlSoftVer"] =
                  this.receivedFeature.dtlSoftVer;
                this.startConfigurationData["flextuneSoftVer"] =
                  this.receivedFeature.flextuneSoftVer;
                this.startConfigurationData["multiconfigSoftVer"] =  this.receivedFeature.multiconfigSoftVer;
                this.startConfigurationData["mindimlevelSoftVer"] =
                  this.receivedFeature.mindimlevelSoftVer;
                this.startConfigurationData["dalipsuSoftVer"] =
                  this.receivedFeature.dalipsuSoftVer;
                this.startConfigurationData["ztvSoftVer"] =
                  this.receivedFeature.ztvSoftVer;
                this.startConfigurationData["ztvoutputSoftVer"] =
                  this.receivedFeature.ztvoutputSoftVer;
                this.startConfigurationData["lipSoftVer"] =
                  this.receivedFeature.lipSoftVer;
                this.startConfigurationData["rsoSoftVer"] =
                  this.receivedFeature.rsoSoftVer;
                this.startConfigurationData["aocSoftVer"] =
                  this.receivedFeature.aocSoftVer;
                this.startConfigurationData["touchdimSoftVer"] =
                  this.receivedFeature.touchdimSoftVer;
                this.startConfigurationData["diia253maintenanceSoftVer"] =
                  this.receivedFeature.diia253maintenanceSoftVer;
                this.startConfigurationData["corridorSoftVer"] =
                  this.receivedFeature.corridorSoftVer;
                this.startConfigurationData["astSoftVer"] =
                  this.receivedFeature.astSoftVer;
                this.startConfigurationData["cloliteSoftVer"] =
                  this.receivedFeature.cloliteSoftVer;
                this.startConfigurationData["cloSoftVer"] =
                  this.receivedFeature.cloSoftVer;
                this.startConfigurationData["dynadimmerSoftVer"] =
                  this.receivedFeature.dynadimmerSoftVer;
                this.startConfigurationData["dcemergencySoftVer"] =
                  this.receivedFeature.dcemergencySoftVer;
                this.startConfigurationData["lineswitchSoftVer"] =
                  this.receivedFeature.lineswitchSoftVer;
                this.startConfigurationData["owpSoftVer"] =
                  this.receivedFeature.owpSoftVer;
                this.startConfigurationData["codedmainsSoftVer"] =
                  this.receivedFeature.codedmainsSoftVer;
                this.receivedFeature.device12nc =
                  this.receivedFeature.device12nc;
                localStorage.setItem(
                  "configurationData",
                  JSON.stringify(this.startConfigurationData)
                );
                this.apiService.setConfigurationData(
                  this.startConfigurationData
                );
                // this.loading=false;
                if (id) {
                  //this.exportFeatureFile(id);
                  this.callWritingCheck(1);
                }
              },
              (err) => {
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
              }
            );
        }
      });
  }
  configMultiple() {
    const modalRef = this.modalService.open(
      ConfigureDeviceModalInputComponent,
      {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      }
    );
  }
}
